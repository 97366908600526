import React, { useState, useEffect } from 'react';
import { Button, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ClassNames } from '@emotion/react';

const useStyles = makeStyles((theme) => ({    
    formRoot: {
        // width: '70%',
        margin: 'auto',
        textAlign: 'center',
    },
    error: {
        color: 'red'
    },
    success: {
        color: 'green'
    },
    fieldContainer: {
        margin: '20px'
    },
    submitButton: {
        margin: 'auto',
        marginTop: '20px',
        display: 'block'
    },
    '@media only screen and (max-width: 1000px)': {
        formImage: {
          display: 'none'
        }
    },
}));

const CustomForm = ({ status, message, onValidated, inlineFooter }) => {

    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    // const [lastName, setLastName] = useState('');
    const classes = useStyles();

    useEffect(() => {
        if(status === "success") clearFields();
    }, [status])
    
    const clearFields = () => {
        setFirstName('');
        // setLastName('');
        setEmail('');
    }
    
    const handleSubmit = (e) => {
        e.preventDefault();
        email &&
        firstName &&
        // lastName &&
        email.indexOf("@") > -1 &&
        onValidated({
            EMAIL: email,
            MERGE1: firstName,
            // MERGE2: lastName,
        });
    }

    return (
        <form className={classes.formRoot} onSubmit={(e) => handleSubmit(e)}>
            {
                inlineFooter ?
                    <>
                        <h3>Subscribe To Our Newsletter</h3>
                        <p>Stay informed about our latest updates by subscribing to our newsletter below!</p>
                    </>
                :
                    null
            }     
            {status === "sending" && (
                <div className="mc__alert mc__alert--sending">
                    sending...
                </div>
            )}
            {status === "error" && (
                <div 
                    className={classes.error}
                    dangerouslySetInnerHTML={{ __html: message }}
                />
            )}
            {status === "success" && (
                <div
                    className={classes.success}
                    dangerouslySetInnerHTML={{ __html: message }}
                />
            )}

            <div className={classes.fieldContainer}>
                <TextField
                    label="First Name"
                    // onChangeHandler={setEmail}
                    onChange={(e) => setFirstName(e.target.value)}
                    type="name"
                    value={firstName}
                    // placeholder=""
                    isRequired
                />
                {
                    inlineFooter ?
                        <>
                            &nbsp;
                        </>
                    :
                        <br />
                }
                <TextField
                    label="Email"
                    // onChangeHandler={setEmail}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    value={email}
                    placeholder="your@email.com"
                    isRequired
                />
            </div>

            {
                inlineFooter ?
                    null
                :
                    <Typography variant='caption'>
                        By clicking on 'Submit', you are signing up to recieve newsletter emails from NectarCares. <br />
                        You can unsubscribe at any time by clicking the link in the footer of our emails.
                    </Typography>
            }       
            
            <Button
                className={classes.submitButton}
                variant='outlined'
                label="subscribe"
                type="submit"
                onSubmit={(e) => handleSubmit(e)}
            >Submit
            </Button>
      </form>
    );
};

export default CustomForm;