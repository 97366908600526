import React, { useState } from 'react';
import { Card, CardMedia, CardContent, CardActions, IconButton, Select, Collapse, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
    root: {
      width: 250,
      margin: 25,
    },
    media: {
      height: 0,
      paddingTop: "56.25%" // 16:9
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest
      })
    },
    expandOpen: {
      transform: "rotate(180deg)"
    },
    cardContent: {
        display: 'flex',
        alignItems: 'center',
    }
  }));

const ItemCard = ({ item }) => {

    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
    const [itemName, setitemName] = useState('');


    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <div>
            <Card className={classes.root}>
            <CardMedia
                className={classes.media}
                image="/static/images/cards/paella.jpg"
                title="Paella dish"
                alt="plate"
            />
            <CardContent className={classes.cardContent}>
                <Typography variant="h6" color="textSecondary" component="p">
                {item}
                </Typography>
                <IconButton
                    className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded,
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon />
                </IconButton>
            </CardContent>
            {/* <CardActions disableSpacing>
                <IconButton aria-label="add to favorites">
                <FavoriteIcon />
                </IconButton>
                <IconButton
               
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
                >
                <ExpandMoreIcon />
                </IconButton>
            </CardActions> */}
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                <Select name="Qty" value="Select Qty" />
                </CardContent>
            </Collapse>
            </Card>
        </div>
    )
}

export default ItemCard

