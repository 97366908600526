import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@material-ui/core';

const LoginButton = () => {
    
    const { loginWithRedirect } = useAuth0();
    
    return (
        <Button
            className="btn btn-primary btn-block"
            style={
                {
                    // textTransform: "capitalize", 
                    // color: "inherit"
                    fontFamily: [
                        'DINRegular',
                        'sans-serif'
                    ].join(','),
                    // fontWeight: '500'
                }                
            }
            onClick={() => loginWithRedirect(
                // {
                // // appState: { target: '/organizations/trustedworld' }
                // redirectUri: "http://localhost:3000/organizations/trustedworld"
                // }
            )}
        >
         Log In
        </Button>
    )
}

export default LoginButton;
