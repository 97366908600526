import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { GoogleLogout } from 'react-google-login';
import {
  useHistory,
  useLocation
} from "react-router-dom";
import { Typography, Button, IconButton, Drawer } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import './navbar.css';
import Logo from '../../img/logo2.png';
import AuthenticationButton from '../Login/authentication-button';
import { useAuth0 } from '@auth0/auth0-react';
import PersonIcon from '@mui/icons-material/Person';
import HomeIcon from '@mui/icons-material/Home';
import ProfileButton from './ProfileButton';
import HistoryIcon from '@mui/icons-material/History';
import DropdownMenu from './DropdownMenu';
// import logo from '../../../public/logo-dark.png';
// import logo from '../../img/NectarCaresLogo.png';
// import logo from '../../img/nectar-cares-logo-blue.png';
import logo from '../../img/nectar-cares-logo-blue.svg';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: theme.spacing(2),
    maxWidth: '100vw',   
  },
  appBarRoot: {
    // backgroundColor: '#1AAE9F',
    backgroundColor: '#8DD7CF',    
  },
  menuButtons: {
    // padding: 2,
    // display: 'block'
    // backgroundColor: 'black'
    textAlign: 'center'
  },
  menuButtonMobile: {
    // marginRight: theme.spacing(2),
    // width: '55%',
    width: '100%',
    textAlign: 'center',
    // textTransform: "capitalize",    
    fontFamily: [
      'DINRegular',
      'sans-serif'
    ].join(','),
    // fontWeight: '500'
  },
  title: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: 10,
    // color: "white",
    // color: "inherit",
    // textTransform: "capitalize",    
    fontFamily: [
      'DINRegular',
      'sans-serif'
    ].join(','),
    // fontWeight: '500'
  },
  textUnderline: {
    textDecoration: "underline"
    // borderBottom: '1px solid white'
  },
  logoImg: {
    width: '16rem',
  },
  logoDiv: {
    flexGrow: 1,
  },
  hamburgerIconButton: {
    // marginLeft: '43vw',
    float: 'right',
  },
  adminLoginButton: {
    // color: "white",

  },
  '@media print': {
    noPrint: {
      visibility: 'hidden'
    }
  },
  DINFont: {
    fontFamily: [
      'DINRegular',
      'sans-serif'
    ].join(','),
    // fontWeight: '500'
  }
}));

export default function Navbar ({loggedIn, hideNavButtons}) {

  const classes = useStyles();
  let history = useHistory();

  const [state, setstate] = useState({ 
    mobileView: false,
    drawerOpen: false,
  })
  const { mobileView, drawerOpen } = state;
  const [isLoggedin, setisLoggedin] = useState(false);
  const location = useLocation();
  const { isAuthenticated, user } = useAuth0();

  
  // Return true if the user has been logged in AND a user object exists AND the user is an admin
  function checkIfUserIsAdmin() {
    console.log(`user is ${user}`);
    console.log(user);
    if (user) {
      console.log("In user condition");
      if (isAuthenticated && (user["https://nectarcares.org/roles"][0] == "admin")) {
      // if (isAuthenticated && (user["http://mynamespace/roles"][0] == "admin")) {
        return true;
      }
    }

    return false;
  }

  useEffect(() => {
    console.log("PRINTING USER");
    console.log(user);
    function handleResize() {
      if (window.sessionStorage.getItem('donorEmail') == null || window.sessionStorage.getItem('donorEmail') == '') {
        setisLoggedin(false);
      } else {
        setisLoggedin(true);
      }
      return window.innerWidth < 1250
        ? setstate((prevState) => ({...prevState, mobileView: true}))
        : setstate((prevState) => ({...prevState, mobileView: false}));
    };
    handleResize();
    window.addEventListener('resize', handleResize);
  }, []);

  const logout = () => {
    window.sessionStorage.setItem('donorEmail', '');
    window.sessionStorage.setItem('donorName', '');
    alert("Successfully Logged out");
    history.push("/");
    window.location.reload();
  }

  const displayDesktop = () => {
    if (!isAuthenticated) {
      return (
        <div className={`${classes.root}, ${classes.noPrint}`}>
        <AppBar position="static" className={classes.appBarRoot} >
          <Toolbar>           
            <Typography variant="h6" className={classes.title}>
              <a href="/" style={{textDecoration: "none", color: "inherit"}}>
                <img src={logo} width={'220px'}></img>
              </a>
            </Typography>
            <div className="menu-buttons">
              <DropdownMenu isPartnerships={true} />
              {/* <DropdownMenu isPartnerships={false} /> */}
              {/* <Button                              
                className={location.pathname == "/nft" ? `${classes.menuButton} ${classes.textUnderline}` : `${classes.menuButton} ${classes.DINFont}`}
                // color="secondary" 
                href="/nft">
                  Support NectarCares
              </Button> */}
              <Button href="/about" className={location.pathname == "/about" ? `${classes.menuButton} ${classes.textUnderline}` : `${classes.menuButton} ${classes.DINFont}`}>
                About Us
              </Button>
              <div style={{textTransform: "capitalize"}}>
                <AuthenticationButton />
              </div>
            </div>
          </Toolbar>
        </AppBar>
      </div>
      )
    }
    return (
      <div className={`${classes.root}, ${classes.noPrint}`}>
        <AppBar position="static" className={classes.appBarRoot}>
          <Toolbar>           
            <Typography variant="h6" className={classes.title}>
              <a href="/" style={{textDecoration: "none", color: "inherit"}}>
                <img src={logo} width={'220px'}></img>
              </a>
            </Typography>
            <div className="menu-buttons">
              <DropdownMenu isPartnerships={true} />
              {/* <Button                              
                className={location.pathname == "/nft" ? `${classes.menuButton} ${classes.textUnderline}` : `${classes.menuButton} ${classes.DINFont}`}
                href="/nft">
                  Support NectarCares
              </Button> */}
              <Button                              
                className={location.pathname == "/about" ? `${classes.menuButton} ${classes.textUnderline}` : `${classes.menuButton} ${classes.DINFont}`}
                href="/about">
                  About Us
              </Button>
              {
                isAuthenticated && user["https://nectarcares.org/roles"][0] == "admin" ?
                  <>
                    <Button 
                      className={location.pathname == "/item-request" ? `${classes.menuButton} ${classes.textUnderline}` : `${classes.menuButton} ${classes.DINFont}`}
                      // color="secondary"
                      href="/item-request">
                        Request Items
                    </Button>
                    <Button                              
                      className={location.pathname == "/item-history" ? `${classes.menuButton} ${classes.textUnderline}` : `${classes.menuButton} ${classes.DINFont}`}
                      // color="secondary" 
                      href="/item-history">
                        Manage Requests
                    </Button>
                  </>
                  :
                  <>
                    <Button 
                        className={location.pathname == "/donations" ? `${classes.menuButton} ${classes.textUnderline}` : `${classes.menuButton} ${classes.DINFont}`}
                        href="/donations">
                          Fulfill Requests
                      </Button>
                      {/* <Button
                        className={location.pathname == "/history" ? `${classes.menuButton} ${classes.textUnderline}` : `${classes.menuButton} ${classes.DINFont}`}
                        href="/history">
                        My Donations
                      </Button> */}
                      <DropdownMenu isPartnerships={false} />
                      <Button
                        className={location.pathname == "/refer" ? `${classes.menuButton} ${classes.textUnderline}` : `${classes.menuButton} ${classes.DINFont}`}
                        href="/refer">
                        Refer a Friend
                      </Button>
                  </>                     
              }
              {
                user ? 
                  <ProfileButton />
                :                    
                  <div>
                    <AuthenticationButton />
                  </div>
              }                  
            </div>
          </Toolbar>
        </AppBar>
      </div>
    );
  };

// Called for mobile displays
const displayMobile = ()  => {
  const handleDrawerOpen = () => {
    setstate((prevState) => ({...prevState, drawerOpen: true}));
  }
  const handleDrawerClose = () => {
    setstate((prevState) => ({...prevState, drawerOpen: false}));
  }
  
  if (!isAuthenticated) {
    return (
      <div className={`${classes.root}, ${classes.noPrint}`}>
      <AppBar position="static" className={classes.appBarRoot}>
        <Toolbar>           
          <Typography variant="h6" className={classes.title}>
            <a href="/" style={{textDecoration: "none", color: "inherit"}}>
              <img src={logo} width={'220px'}></img>
            </a>
          </Typography>
          {          
            <IconButton
              {...{
                edge: 'end',
                color: 'inherit',
                'aria-label': 'menu',
                'aria-haspopup': 'true',
                onClick: handleDrawerOpen,
              }}
              className={classes.hamburgerIconButton}
            >
              <MenuIcon />
            </IconButton>
        }
        <Drawer className={classes.navDrawer}
          {...{
              anchor: "right",
              open: drawerOpen,
              onClose: handleDrawerClose,
            }}
        >
          <div className={classes.menuButtons}>
            <DropdownMenu isPartnerships={true} />
            {/* <DropdownMenu isPartnerships={false} /> */}
            {/* <Button                              
                className={classes.menuButtonMobile}
                style={{width: '100%'}}
                // color="secondary" 
                href="/nft">
                  Support NectarCares
            </Button>             */}
            <Button href="/about" style={{width: "100%"}}>
                About Us
            </Button>
            {/* <div style={{color: "white", textTransform: "capitalize"}}> */}
              <AuthenticationButton />
            {/* </div> */}
          </div>
          </Drawer>
        </Toolbar>
      </AppBar>
    </div>
    )
  }
  
  return (
    <div className={`${classes.root}, ${classes.noPrint}`}>
      <AppBar position="static" className={classes.appBarRoot}>
      <Toolbar>
        <Typography variant="h6" className={classes.title}>
          <a href="/" style={{textDecoration: "none", color: "inherit"}}>
            <img src={logo} width={'220px'}></img>
          </a>
        </Typography>
        {          
            <IconButton
              {...{
                edge: 'end',
                // color: 'inherit',
                'aria-label': 'menu',
                'aria-haspopup': 'true',
                onClick: handleDrawerOpen,
              }}
              className={classes.hamburgerIconButton}
            >
              <MenuIcon />
            </IconButton>
        }
        <Drawer className={classes.navDrawer}
          {...{
              anchor: "right",
              open: drawerOpen,
              onClose: handleDrawerClose,
            }}
        >
        <div className={classes.menuButtons}>
          <DropdownMenu isPartnerships={true} />
          {/* <Button                              
            className={classes.menuButtonMobile}
            style={{width: '100%'}}
            // color="secondary" 
            href="/nft">
              Support NectarCares
          </Button> */}
          <Button href="/about" style={{width: '100%'}}>
            About Us
          </Button>
          {
            isAuthenticated && user["https://nectarcares.org/roles"][0] == "admin" ?
            <>
              <Button className={classes.menuButtonMobile} href="/item-history" startIcon={<HistoryIcon />}>Request History</Button>
              <Button className={classes.menuButtonMobile} href="/item-request">Request Items</Button>
              <br />
            </>
              :
              <>
                <Button 
                    className={location.pathname == "/donations" ? `${classes.menuButton} ${classes.textUnderline}` : `${classes.menuButton}`}
                    href="/donations">
                      Fulfill Requests
                  </Button><br />
                  {/* <Button
                    className={location.pathname == "/history" ? `${classes.menuButton} ${classes.textUnderline}` : `${classes.menuButton}`}
                    href="/history">
                    My Donations
                  </Button> */}
                  <DropdownMenu isPartnerships={false} />
                  <br />
                  <Button
                    className={location.pathname == "/refer" ? `${classes.menuButton} ${classes.textUnderline}` : `${classes.menuButton}`}
                    href="/refer">
                    Refer a Friend
                  </Button>
                <br />
              </>
          }              
          {
            user ? 
            <>
              <Button 
                className={classes.menuButton} 
                href={"/profile/" + user.sub}
                startIcon={<PersonIcon />}
                // style={{textTransform: "capitalize"}}
              >
                Profile
              </Button>
              <br />
            </>
            :
              null
          }
          <AuthenticationButton />                                         
        </div>
        </Drawer>
      </Toolbar>
      </AppBar>
      </div>
  );
};

  const AnnouncementBanner = () => {
    return (
      <div
        style={{
          width: '100%',
          height: '2rem',
          textAlign: 'center',
          backgroundColor: '#F2C400',
          marginTop: '-14px',
          marginBottom: '0px',
        }}
      >
        <p style={{height: '100%', padding: '0.25rem'}}>
          <a style={{color: 'inherit' }} href='https://nectarcares.org/nft' target='_blank'>
            Something's Coming!
          </a>
        </p>
      </div>
    )
    }

  return (
    <>
      {(mobileView) ? displayMobile() : displayDesktop()}
      {/* <AnnouncementBanner />  */}
    </>
  );  
}
