import * as React from 'react';
import { useEffect, useContext } from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AppContext from "../AppContext";
import Loading from '../loading';
import { useParams } from 'react-router-dom';

export default function CustomizedTimeline() {

  const myContext = useContext(AppContext);

  let { id } = useParams();

  // useEffect(() => {
   
  //   // alert("Setting donations in DonationList.jsx");
  //   let url = process.env.REACT_APP_SHEETY_GET_TIMELINE;
  //   fetch(url)
  //   .then((response) => response.json())
  //   .then(json => {
  //   // Do something with the data
  //       console.log("Setting timeline in RequestSummary.jsx(2)");
  //       myContext.setTimeline(json.timeline);
  //   });
  // }, [])  
  
  // const Timeline = () => {
  //   console.log(myContext.timeline);
  //   if (myContext.timeline) {
  //     console.log('printing timeline...');
  //     return (
  //       <>
  //       {
  //         myContext.timeline.map((timelineEvent, index) => (
  //           <Timeline key={index} position="alternate">
  //               <TimelineItem>
  //                 <TimelineOppositeContent
  //                   sx={{ m: 'auto 0' }}
  //                   align="right"
  //                   variant="body2"
  //                   color="text.secondary"
  //                 >
  //                   {timelineEvent['eventTimestamp']}
  //                   abcd
  //                 </TimelineOppositeContent>
    
  //                 <TimelineSeparator>
  //                   <TimelineConnector />
  //                   <TimelineDot>
  //                     <CheckIcon />
  //                   </TimelineDot>
  //                   <TimelineConnector />
  //                 </TimelineSeparator>
    
  //                 <TimelineContent sx={{ py: '12px', px: 2 }}>
  //                   <Typography variant="h6" component="span">
  //                   {timelineEvent['eventTitle']}
  //                   </Typography>
  //                   <Typography variant='caption' component="p" >
  //                     {timelineEvent['eventDescription']}
  //                   </Typography>
  //                 </TimelineContent>
  //               </TimelineItem>               
  //           </Timeline> 
  //         ))
  //       }
  //       </>
  //     )

  //   } else {
  //     console.log('timeline loading...');
  //     return (
  //       <Loading />
  //     )
  //   }
  // }
  
  if (myContext.timeline) {
    console.log(myContext.timeline[0]['eventTitle'])
    return (
      <>
      <Timeline position="alternate">
      {
        Object.keys(myContext.timeline).reverse().map((timelineEvent, index) => (
          <div key={index}>
            {
              id == myContext.timeline[timelineEvent]['requestId'] ?
                <TimelineItem>
                  <TimelineOppositeContent
                    sx={{ m: 'auto 0' }}
                    align="right"
                    variant="body2"
                    color="text.secondary"
                  >
                    {myContext.timeline[timelineEvent]['eventTimestamp']}
                  </TimelineOppositeContent>
    
                  <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot>
                      {
                        myContext.timeline[timelineEvent]['eventType'].includes('notify') ?
                          <NotificationsIcon />
                          :
                          <CheckIcon />
                      }
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
    
                  <TimelineContent sx={{ py: '12px', px: 2 }}>
                    <Typography variant="h6" component="span">
                      {myContext.timeline[timelineEvent]['eventTitle']}
                    </Typography>
                    <Typography variant='caption' component="p" >
                      {myContext.timeline[timelineEvent]['eventDescription']}
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
                :
                null                   
            }
            </div>
        ))
      }
      </Timeline>  
      </>
    )
  } else {
    return (
      <Loading />
    )
  }
}