import React from 'react';
// import NectarCaresLogo from '../../img/nectar-cares-logo-blue.png';
import NectarCaresLogo from '../../img/nectar-cares-logo-blue.svg';
import { TextField, Button, FormControl, InputLabel, Select, MenuItem, Grid, Divider, Box, IconButton, Typography, makeStyles } from '@material-ui/core';
import TrustedWorldLogo from '../../img/TrustedWorldLogo.png';
import EmailIcon from '@mui/icons-material/Email';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import CustomForm from '../MailChimp/CustomForm';
import MailchimpSubscribe from "react-mailchimp-subscribe";

const useStyles = makeStyles((theme) => ({
    newsletterSignUpFooter: {
        width: '100%'
    }
}));

const Footer = (props) => {
    
    const classes = useStyles();
    
    return (
        // <div className='no-print'>
            <footer
                className='no-print'
                style={
                    {
                        textAlign: "center", 
                        // paddingTop: '1rem',
                        // height: "40px", 
                        // padding: "15px",
                        position: "absolute",
                        bottom: "0",
                        width: "100%",
                        maxHeight: "25rem",
                        backgroundColor: "#8DD7CF",
                        // backgroundColor: "rgb(194, 207, 217)",
                        // backgroundColor: "rgb(102, 102, 102, 0.4)",
                        // backgroundColor: "#666666",
                        // marginBottom: "0px",
                        // marginTop: "18rem",
                        // paddingTop: "4rem",
                        // border: "1px solid gray"                  
                    }
                }
            >
                <Grid container alignItems='center' spacing={2} style={{maxWidth: '80vw', margin: 'auto'}}>
                    <Grid container alignItems='center' style={{marginTop: "1.5rem"}}>
                        {/* <Grid container spacing={4} style={{width: 'auto', borderRight: '1px solid black', margin: '1rem'}} > */}
                        {/* <Grid item xs={12} md={5} style={{ */}
                        <Grid container xs={12} md={5} style={{
                            // borderRight: '1px solid black', 
                            margin: '1rem'
                            }} >
                            <Grid item xs={12} md={8}>
                                <img src={NectarCaresLogo} 
                                style={{
                                    maxWidth: "250px",
                                    // marginRight: "85%"
                                }}
                                ></img>                            
                            </Grid>
                            {/* <Grid item xs={12}>
                                <img src={TrustedWorldLogo} 
                                style={{
                                    width: "180px",
                                    // marginRight: "85%"
                                }}
                                ></img>                            
                            </Grid> */}                            
                            <Grid item xs={12} md={3}>
                                <Button
                                    style={{display: 'block', margin: 'auto', textTransform: 'capitalize', marginTop: '10%' }}
                                    onClick={() => props.setnewsletterPopup(true)}
                                    variant='outlined'
                                    size='medium'
                                >Subscribe
                                </Button>                                                        
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={5} style={{margin: 'auto'}} >
                            <Grid container style={{textAlign: 'left', margin: 'auto'}}>
                                <Grid item xs={12}>
                                    <p style={{textAlign: 'center'}}>
                                        <strong>Contact Us</strong>
                                    </p>
                                </Grid>
                                <Grid item xs={3} md={6}>
                                    <a href='mailto:info@nectarcares.org' style={{color: 'inherit'}}>
                                        <IconButton>
                                            <EmailIcon />
                                        </IconButton>
                                        {
                                            window.innerWidth < 960 ?
                                                null
                                                :
                                                'Info@nectarcares.org'
                                        }
                                    </a>
                                </Grid>                        

                                <Grid item xs={3} md={6}>
                                    <a href='https://www.facebook.com/NectarCares-105351892034364' target='_blank' style={{color: 'inherit'}}>
                                        <IconButton>
                                            <FacebookIcon />
                                        </IconButton>
                                        {
                                            window.innerWidth < 960 ?
                                                null
                                                :
                                                '@NectarCares'
                                        }                                        
                                    </a>
                                </Grid>                        
                                <Grid item xs={3} md={6}>
                                    <a href='https://instagram.com/nectarcares' target='_blank' style={{color: 'inherit'}}>
                                        <IconButton>
                                            <InstagramIcon />
                                        </IconButton>
                                        {
                                            window.innerWidth < 960 ?
                                                null
                                                :
                                                '@nectarcares'                                                
                                        }   
                                    </a>
                                </Grid>
                                <Grid item xs={3} md={6}>
                                    <a href='https://twitter.com/NectarCares' target='_blank' style={{color: 'inherit'}}>
                                        <IconButton>
                                            <TwitterIcon />
                                        </IconButton>
                                        {
                                            window.innerWidth < 960 ?
                                                null
                                                :
                                                '@NectarCares'
                                        }   
                                    </a>
                                </Grid>                        
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{marginBottom: "10px"}} >
                        {/* <p 
                        style={{marginTop: "1%"}}
                        > */}
                            Copyright &copy; 2022 NectarCares. All rights reserved. &nbsp;                 
                        {/* </p> */}
                        <a href='/privacy-policy'>
                            (Privacy Policy)
                        </a>
                    </Grid>
                </Grid>
            </footer>    
        // </div>       
    )
}

export default Footer
