import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@material-ui/core';
import LogoutIcon from '@mui/icons-material/Logout';

const LogoutButton = () => {

    const { logout } = useAuth0();

    return (
        <Button
            className="btn btn-danger btn-block"
            style={
                {
                    // textTransform: "capitalize"
                    fontFamily: [
                        'DINRegular',
                        'sans-serif'
                    ].join(','),
                    // fontWeight: '500'
                }
            }
            onClick={() =>
                logout({
                    returnTo: window.location.origin,
                })
            }
            startIcon={<LogoutIcon />}
        >
            {/* <LogoutIcon /> */}
            Log Out
        </Button>
    )
}

export default LogoutButton
