import React, { useState, useContext } from 'react'
import { Grid, Card, CardContent, Typography, CardActions, Button, FormControl, Select, Checkbox, FormControlLabel, FormGroup, Stepper, Step, StepLabel, InputLabel, MenuItem, TextField, Divider, FormHelperText, Paper, Tabs, Tab, Badge, CardHeader, CardMedia } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AppContext from '../AppContext.js';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import CartSummary from '../Recipient Request/CartSummary';
import * as items from '../../items';
import itemsObjects from "../../items.json";
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
// import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles((theme) => ({
    root : {
        width: '85vw',
        margin: 'auto',
    },
    formRoot: {
        display: 'flex',
        // padding: '10px',
        width: '100%',
        // maxWidth: '1000px',
        flexWrap: 'wrap',
        margin: 'auto',
        // justifyContent: 'space-evenly',
    },    
    FormControl: {
        width: '11rem',
        margin: '12px 12px 12px 0px',
    },
    stepTitle: {
        // margin: '10px 20px 10px 26px',
        margin: '10px 20px 10px 0px',
    },
    TextField: {
        margin: '1%',
    },
    subCategory: {
        width: '100%',
        fontWeight: 'bold',
        // marginLeft: '5rem',
        marginTop: '3.5rem'
    },
    halfWidth: {
        width: '48%',
    },
    thirdWidth: {
        width: '31.3%',
    },
    button: {
    //   marginRight: theme.spacing(1),
        margin: theme.spacing(2),
    },
    greenButton: {
        backgroundColor: 'rgba(52, 211, 153, 1)',
    }
}));


const RequestUpdate = () => {
    const myContext = useContext(AppContext);

    const classes = useStyles();
    const [currentCategory, setcurrentCategory] = useState('Kitchen');
    const [tabValue, setTabValue] = useState(0);
    var itemsRequested = myContext.itemsRequested;
    // var itemsRequested={};
    let { id } = useParams();
    const [disableSubmitButton, setDisableSubmitButton] = useState(false);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        if (newValue == 0) setcurrentCategory("Kitchen")
        else if (newValue == 1) setcurrentCategory("Bedroom")
        else if (newValue == 2) setcurrentCategory("Bathroom")
    }

    const validate = (step) => {
        let isError = false;                    
        // if there is an error, form is NOT valid
        return !isError;
    }

    // function to convert item name from camelcase to normal word (Eg: pillowCases(standard) -> pillow cases(standard)) 
    function convertCase(item) {
        var gapi_key;

        if(/[A-Z]/.test(item)) {
            // split word at capital letter
            var str_array = item.split(/(?=[A-Z])/);
            gapi_key = str_array.join(' ');
            gapi_key = gapi_key.toLowerCase();
        } else {
            return item;
        }
        
        console.log(`converted ${item} to `);
        return gapi_key;
    }

    const handleFormValidation = (step) => {

        // Force shopping cart view before item request submission
        if (tabValue != 3 && step == 2) {
            setTabValue(3);
            return;
        } 
        console.log(`Step is ${step}`)
        var isValid = validate(step);
        if(isValid == true) {
            // handleNext();
        }
    }

    // This function takes in an item(string) and checks if the item cetegory filter is checked to true
    // If there is a match, we return true. Else, we return false
    function checkFilter(item) {
        console.log(item);

        if(items.items.indexOf(item) <= 30 && currentCategory=="Kitchen") {
            return true;
        } else if((items.items.indexOf(item) <= 38 && items.items.indexOf(item) > 30 )  && currentCategory=="Bedroom") {
            return true;
        } else if(items.items.indexOf(item) > 38  && currentCategory=="Bathroom") {
            return true;
        }

        return false;
    }

    // return the subcategory based on the index
    const displaySubcategory = (index) => {
        if (index == 0) {
            return (<Typography className={classes.subCategory}>Tableware</Typography>);
        }
        else if (index == 4) {
            return (
                <>
                <Divider variant="middle" />
                <Typography className={classes.subCategory}>Glassware & Drinkware</Typography>
                </>
            );
        }
        else if (index == 10) {
            return (<Typography className={classes.subCategory}>Cookware</Typography>);
        }
        else if (index == 19) {
            return (<Typography className={classes.subCategory}>Cooking Utensils</Typography>);
        }
        else if (index == 4) {
            return (<Typography className={classes.subCategory}>Glassware & Drinkware</Typography>);
        }
    }

    // Handler function to handle donation submissions
    const handleChangeStep2 = (event) => {
        // console.log("Handling item request...");
        const name = event.target.id;
        console.log(event);
        console.log(event.target.value);
        // setrequestedItems({
        //     ...requestedItems,
        //     [name]: event.target.value,
        // });
        // console.log(`Changing ${event}`);
        itemsRequested[event.target.name] = event.target.value;
        console.log(itemsRequested);
        console.log(Object.keys(itemsRequested));
        console.log(Object.keys(itemsRequested).length);
        console.log(itemsRequested[0]);
        console.log(itemsRequested[event]);
    };

    // Send Request Update PATCH request to the backend
    async function handleRequestUpdate () {
        setDisableSubmitButton(true);
        toast.promise(
            // Send reqID(short ID), items to add, qty to add
            axios.patch('https://donation-proxy-api.herokuapp.com/request-update', {
            // axios.patch('http://localhost:3080/request-update', {
                itemsAdded: itemsRequested,
                reqID: id,
            })
            .then(function (response) {
                // alert(response.data);                    
                response.data && toast.success(`${response.data}`, { autoClose: '6000' });
                window.setTimeout(function() {
                    window.location.replace('/item-history');
                }, response.data ? 5500 : 6500)
            })
            .catch(function (error) {
                alert(error);
            }),
            {
                pending: 'Processing Request Update',
                success: `Request Update Successful 👌 (Request ID: ${id})`,
                error: 'There was an error processing the request update.'
            }
        )

    }

    // return 'true' if no items have been selected
    function checkNoItemsSelected() {
        console.log('Checking if no items have been selected...');
        console.log(itemsRequested);

        var keys = Object.keys(itemsRequested);

        if (keys.length == 0) return 'true';
        else return 'false';
    }
    
    const Step2 = () => {
        return (
            <div className={classes.root}>
                <Typography variant="h4" className={classes.stepTitle}>Select Items To Add</Typography>
                <Typography className={classes.stepTitle}>
                    Add items to this request. Newly added items will be reflected in the family request.
                </Typography>
                {/* <Typography >Select items from the categories below: </Typography> */}
                {
                    tabValue == 3 ? 
                        <p>Please review your selected items now, and make any necessary changes before hitting Submit below.</p>
                        :
                        <>
                            <p><strong>Disclaimer: </strong>Images are examples of the items available to request, and are not necessarily the exact item that will be provided.</p>
                        </>
                }
                <Paper>
                    <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                        // variant="scrollable"
                        scrollButtons="on"
                    >
                        <Tab label="Kitchen" />
                        <Tab label="Bedroom" />
                        <Tab label="Bathroom" />
                        <Tab icon={
                             <Badge color="secondary" badgeContent={itemsRequested ? Object.keys(itemsRequested).length : 0}>
                                <ShoppingCartIcon />
                            </Badge>
                        } aria-label="shopping cart" />
                    </Tabs>
                </Paper>                
                {
                    tabValue == 3 ? 
                        <CartSummary itemsRequested={itemsRequested} summaryHeading="Item Request Summary" />
                    :
                        <form className={classes.formRoot}>
                        <Grid container spacing={4} className="itemGrid">
    
                        {
                            items.items.map((item, index) => (
                                (checkFilter(item) == false) ? null :
                                <>
                                    {displaySubcategory(index)}
                                    <Grid item xs={12} sm={6} lg={4}>
                                        <Card sx={{ maxWidth: 345 }} className="itemCard">
                                            <CardHeader              
                                                title={itemsObjects.items[0][item].name}
                                                subheader={convertCase(item)}
                                            />
                                            <CardMedia
                                                component="img"
                                                alt={item.slice(0,-1)}
                                                height="200"
                                                image={itemsObjects.items[0][item].img}
                                                className="itemCardProductImage"
                                            />
                                            <CardContent className="itemCardDescription" style={{overflow: "visible",}}>                                               
                                                <Typography variant="body2" color="text.secondary">
                                                    {itemsObjects.items[0][item].description}
                                                </Typography>
                                            </CardContent>
                                            <CardActions className="itemCardAction">
                                                <FormControl key={index} className={classes.FormControl}>
                                                    <InputLabel id={item}>Qty</InputLabel>
                                                    <Select
                                                        name={item}
                                                        value={itemsRequested ? itemsRequested[item] : 0}
                                                        onChange={handleChangeStep2}
                                                        data-test={"qty-"+index}
                                                        // autoWidth
                                                    >
                                                
                                                    <MenuItem value={0}>0</MenuItem>
                                                    <MenuItem value={1}>1</MenuItem>
                                                    <MenuItem value={2}>2</MenuItem>
                                                    <MenuItem value={3} data-test="qty-option-3">3</MenuItem>
                                                    <MenuItem value={4}>4</MenuItem>
                                                    <MenuItem value={5}>5</MenuItem>
                                                    <MenuItem value={6}>6</MenuItem>
                                                    </Select>
                                                </FormControl>                                           
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                </>           
                            ))
                        }
                        </Grid>                       
                        </form>
                }
                <Button variant="contained" onClick={() => window.history.back()} className={classes.button} color="primary">
                    Back
                </Button>
                {
                    tabValue == 3 ? 
                        <Button
                            variant="contained"
                            // color="primary"                        
                            onClick={() => handleRequestUpdate()}
                            // type="submit"
                            data-test="item-req-next"
                            className={`${classes.button} ${classes.greenButton}`}
                            // disabled={checkNoItemsSelected() ? true : false}
                            // disabled={Object.keys(itemsRequested).length == 0 ? true : false}
                            disabled={disableSubmitButton}
                        >
                            Submit
                        </Button>                     
                    : 
                        <Button
                            variant="contained"
                            // color="primary"                        
                            onClick={() => handleFormValidation(2)}
                            // type="submit"
                            data-test="item-req-next"
                            className={`${classes.button} ${classes.greenButton}`}
                        >
                            Next
                        </Button>
                }                  
            </div>
        )
    }

  return (
    <div>
        <Step2 />
    </div>
  )
}

export default RequestUpdate