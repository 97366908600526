// Note: 
// This page/component is not being used anymore as of December 13th 2021.
// Organizations has been merged with Partnerships page

import { ClassNames } from '@emotion/react';
import { Button, Divider, makeStyles, Typography, useMediaQuery, Grid, Card, CardContent, TableRow, Table, TableBody, TableContainer, Paper, withStyles, Chip, Link } from '@material-ui/core';
import React, { useEffect, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import logo from '../../img/TrustedWorldLogo.png';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import { DonationHome } from '..';
import MuiTableCell from "@material-ui/core/TableCell";
import Loading from '../loading';
import AppContext from '../AppContext';
import TrustedWorldLogo from '../../img/TrustedWorldLogo.png';
import NectarCaresLogo from '../../img/NectarCaresLogo.png'
import { Swiper, SwiperSlide } from 'swiper/react';
import useWindowDimensions from '../hooks/WindowDimensions';
import * as items_global from '../../items';


import {
    EmailShareButton,
    FacebookShareButton,
    FacebookIcon,
    // LinkedinShareButton,
    // RedditShareButton,
    // TelegramShareButton,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon,
    LinkedinShareButton,
    LinkedinIcon,
  } from "react-share";

const useStyles = makeStyles((theme) => ({
    logo: {
        // height: "auto",
        // justifyContent: "center",
        // maxWidth: "100%",
        maxWidth: "200px",
        height: "auto",
        width: "auto",
        // margin: "auto",
        // display: "block",
        // padding: "20px",
        // maxHeight: "150px"
    },
    bodyRoot: {
        margin: "3rem 12vw",
        marginTop: '3rem'
    },
    organizationOverviewRoot: {
        marginBottom: "4rem",
        marginTop: "4rem",
    },    
    TWlogo: {      
        width: "100%",
        maxWidth: "min(90%, 400px)",
        height: "auto",
        padding: "15px"
    },
    logoRoot: {
        // display: "flex",
        margin: "auto",
        marginTop: "3rem",
        textAlign: "center",
        alignItems: "center",
        maxWidth: "300px",
        // justifyContent: "center",
        // marginBottom: "1rem",
        // flexWrap: "wrap",
    },
    plusSign: {
        width: "115px",
        height: "110px",
        textAlign: "center",
    },
    heroTitle: {
        // fontFamily: 'roboto',
        fontSize: 'min(9vw, 4.5rem)',
        // fontSize: '6vw',
        fontWeight: 'bold',
        margin: '15px 15px 7px 15px',
        textAlign: "center",
    },
    heroSubtitle: {
        fontSize: 'min(5vw, 2rem)',
        // fontSize: '2vw',
        margin: '7px 15px 15px 15px',
        textAlign: "center",
    },
    heroButtons: {
        display: "flex",
        justifyContent: "space-around",
        marginBottom: "2rem",
        maxWidth: "30%",
        margin: "auto",
        flexWrap: "wrap"
    },
    InstructionsTitle: {
        fontSize: '1.5rem',
        margin: '2rem 0rem 2rem 0rem',
        fontWeight: 'bold'
    },
    InstructionsRoot: {
        // marginLeft: '5rem'
        display: 'flex',
        flexFlow: 'column',
        maxWidth: '80vw',
        margin: 'auto'
    }, 
    swiper: {
        width: "95vw",
        margin: 'auto',
        height: "188px",
        marginTop: "15px",
        marginBottom: "15px",
    },
    swiperSlide: {
        // maxWidth: "80vw",
        // marginRight: "unset",
        // margin: "auto",
        // justifyContent: "center",
    },
    cardRoot: {
        height: "95%",
        marginLeft: "20px !important",
        marginRight: "20px !important"
    },
    donationButton: {
        marginTop: '2rem',
        marginBottom: '3rem',
    },
    bodyText: {
        fontSize: '1.25rem',
        lineHeight: '180%',
    }
}));

const TableCell = withStyles({
    root: {
      borderBottom: "none"
    }
  })(MuiTableCell);


// Org page to display the following:
//  -> posted requests
//  -> Contact info
//  -> Location info (with map?)
//  -> Org summary

var api_call_limiter = 0;

const Organization = () => {
    
    const classes = useStyles();
    let { id } = useParams();
    const myContext = useContext(AppContext);
    const [currentOrganization, setcurrentOrganization] = useState();
    const { height, width } = useWindowDimensions();
    var categoryList = [];
    var openRequests = [];

    useEffect(() => {
        if(api_call_limiter<=1) {
            console.log("Fetching from api");
            let url = process.env.REACT_APP_SHEETY_API_GET;
            fetch(url)
            .then((response) => response.json())
            .then(json => {                
                // Update the prop with the requests
                if (myContext.setrequests) {
                    console.log("Going to set requests in homepage useEffect");
                    console.log(myContext);
                    myContext.setrequests(json.requests);
                }
            });
        }
        api_call_limiter++;
        // if (!myContext.nonProfitOrgs) {
        if (!currentOrganization && api_call_limiter<=2) {     
            // search the backend for the non profit org "id" & retrieve it's info
            let url = 'https://api.sheety.co/591c67f7e588d2a49e1cf55d260ce8a5/recipient/nonProfitOrgs';
            fetch(url)
            .then((response) => response.json())
            .then(json => {
                // Do something with the data
                console.log(json.nonProfitOrgs);
                myContext.setnonProfitOrgs(json.nonProfitOrgs);

                // convert id to name
                var idArray = id.split('-');
                var nameLowercase = '';
                for (var i=0; i<idArray.length; i++) {
                    // if last iteration, do not add a space
                    if (i == idArray.length -1) {
                        nameLowercase = nameLowercase + idArray[i];
                    } else {
                        nameLowercase = nameLowercase + idArray[i] + ' ';
                    }
                }

                console.log(nameLowercase);
                
                // loop through the org list to find the org with matching slug
                for (var i=0; i<json.nonProfitOrgs.length; i++) {
                    if (json.nonProfitOrgs[i]['name'].toLowerCase() == nameLowercase) {
                        console.log(json.nonProfitOrgs[i]);
                        // alert('setting current org...');
                        setcurrentOrganization(json.nonProfitOrgs[i]);
                        // write current organization to session storage
                        console.log(`setting current org in session storage to: ${json.nonProfitOrgs[i]['name']}`);
                        window.sessionStorage.setItem('visitedOrganization', json.nonProfitOrgs[i]['name']);
                    }
                }

            });
        }       
    })

    // Set the global variable categoryList to a list of categories based on the items that are present in object obj
    function setCategories(obj) {
        
        // Reset the global categoryList array
        categoryList = [];

        Object.keys(obj).forEach(function(key) {
            // console.log('Key : ' + key + ', Value : ' + obj[key]);
            if (items_global.items.includes(key) && (obj[key] != '') && (obj[key] != 0)) {
                console.log(`key is ${key}`);
                // if the current key is an item, check it's index in items_global[]
                var index = items_global.items.indexOf(key);
                console.log(`Index of ${key} is ${index}`);

                // Set the category based on the index range of the current key in array items_global[]
                if (index <= 30) {
                    // Kitchen
                   if (categoryList.indexOf('Kitchen ') == -1) {
                       categoryList.push('Kitchen ');
                   } 
                } else if (index > 30 && index <= 38) {
                    // Bedroom
                    if (categoryList.indexOf('Bedroom ') == -1) {
                        categoryList.push('Bedroom ');
                    } 
                } else if (index > 38) {
                    // Bathroom
                    if (categoryList.indexOf('Bathroom ') == -1) {
                        categoryList.push('Bathroom ');
                    } 
                }
            }
        });        
    }

    // Get & set list of items given a request object
    function getItems(obj, value) {

        console.log("CHEKKK");
        console.log(obj);
        console.log(currentOrganization['name']);

        console.log("Getting list");
        var list = '';

        Object.keys(obj).forEach(function(key) {
            // console.log('Key : ' + key + ', Value : ' + obj[key]);
            if (items_global.items.includes(key) && (obj[key] != '') && (obj[key] != 0)) {
                list = list + ' ' + obj[key] + ' ' + key + ',';
            }
        });

        // Set the categories to show on each family card
        setCategories(obj);

        // Remove the last comma
        list = list.slice(0,-1);

        return list;
    }

     // Return the first 6 requests
     function getRequestsOverview () {
        
        if (openRequests.length == 0 && currentOrganization) {
            // loop through the requests and make a list of requests made by the current organization
            console.log("Printing requests...");
            console.log(myContext.requests);
            for (var i=0; i< myContext.requests.length; i++) {
                if (myContext.requests[i]['nonProfitName'] == currentOrganization['name']) {
                    console.log(`pushing req object`);
                    console.log(myContext.requests[i]);
                    openRequests.push(myContext.requests[i]);
                }
            }
        }

        if (openRequests.length == 0) {
            return (
                <div style={{
                    textAlign: "center",
                    height: '6rem'
                }}>
                    <p>This organization does not have any open requests at this moment.</p>
                </div>
            )
        }
        
        // alert(openRequests.length);
        var slidesPerView = 3;
        // if (width > 1080) {
        if (width > 1300) {
            if (openRequests.length >= 3) {
                slidesPerView = 3;
            } else {
                slidesPerView = openRequests.length;
            }
        } else if (width > 600 && width <= 1300) {
            slidesPerView = 2;
        } else {
            slidesPerView = 1;
        }

        if (myContext.requests[0].bio == "Loading...") {
            return(
                <Loading />
            )
        }

        // if there are <= 3 active requests, just display them without the carousel
        if (openRequests.length <= 3 && false) {
            return (
                <div className={classes.swiper}>
                    <Grid container spacing={2} justify='center'>
                    { openRequests.map((value, index) => (
                        (index > 6 || getItems(value, 1) == '') ? null :
                            <Grid item xs={12} md={4} key={value.id}>
                                <Card className={classes.cardRoot}>
                                    <CardContent className="card-content">
                                        <Typography className="card-bio" color="textPrimary" style={{fontWeight:"bold"}}>
                                            {value.bio}
                                        </Typography>
                                        <Typography color="textSecondary" className="card-items">
                                            <strong>Categories needed: </strong>
                                            {categoryList}
                                        </Typography>
                                    </CardContent>                           
                                </Card>
                            </Grid>
                    ))}
                    </Grid>
                </div>
            )
        } else {
            return(
                <>
                <Swiper
                    // spaceBetween={50}
                    slidesPerView={slidesPerView}
                    navigation={true}
                    className={classes.swiper}
                    // onSlideChange={() => console.log('slide change')}
                    // onSwiper={(swiper) => console.log(swiper)}
                    
                    >
                    { myContext.requests==null?null: myContext.requests.map((value, index) => (
                        (getItems(value, 1) == '' || currentOrganization['name'] != value.nonProfitName) ? null :
                        <SwiperSlide className={classes.swiperSlide} key={value.id}>
                            <Card className={classes.cardRoot}
                                style={{
                                    // margin: "auto",
                                    // backgroundColor: "green",
                                    // maxWidth: "80%",
                                    // marginLeft: "40px",
                                    // marginLeft: "40px",
                                    // marginRight: "10px",
                                }}
                            >
                                <CardContent className="card-content">
                                    <Typography className="card-bio" color="textPrimary" style={{fontWeight:"bold"}}>
                                        {value.bio}
                                    </Typography>
                                    <br />                                                                                     
                                    <Chip 
                                        label={'Requestor: ' + value['nonProfitName']}
                                        style={{marginBottom: "0px"}} 
                                        size='small'                                        
                                        // variant='outlined'                                                
                                    />    
                                </CardContent>                           
                            </Card>
                        </SwiperSlide>
                    ))}
                </Swiper>
                </>
            )
        }
    }

    if (!currentOrganization) {
        return <Loading />
    } else {
        return (
            <>
                <Grid container className={classes.logoRoot} justify='center'>
                    {
                        currentOrganization['name'] != "Trusted World" ?
                            <>
                                {/* <Grid item xs={12} md={3}> */}
                                    <img src={currentOrganization['logo']} className={classes.logo}></img>                     
                                {/* </Grid> */}
                                {/* <Grid item xs={12} md={1} className={classes.plusSign}>
                                    <img src="../../img/plusSign.png"></img>
                                </Grid> */}
                            </>
                            :
                            null
                    }
                    {/* <Grid item xs={12} md={3}>
                        <img src={TrustedWorldLogo} className={classes.TWlogo}></img>
                    </Grid> */}
                </Grid>
                <Grid container justify='center'>
                    <Grid className={classes.leftContent}>
                        <div className={classes.heroText}>
                            <Typography className={classes.heroTitle}>
                                Serve a family in need
                            </Typography>
                            <Typography className={classes.heroSubtitle}>
                                Donate in-kind items and services to families in need
                            </Typography>
                        </div>
                    </Grid>
                </Grid>               
                <div className={classes.heroButtons}>
                    <Button
                        href="/donations"
                        variant="contained"
                        color="primary"
                        className={classes.donationButton}
                    >Fulfill a Request</Button>         
                </div>
                <h3 style={{width: "100%", textAlign: "center", marginTop: "7vh"}}>Open Requests</h3>
                {getRequestsOverview()}
                <div className={classes.bodyRoot}>
                    <Grid container>
                        <Grid item xs={12} md={4} 
                            style={
                                {
                                    textAlign: "center",
                                    height: "100%",
                                    // marginTop: "auto"
                                    // margin: "auto"
                                }
                            }
                        >
                            <Typography variant='h4'>
                                <strong>
                                    Powered by <br /> 
                                    <img src={TrustedWorldLogo} className={classes.TWlogo}></img>
                                    <br /> and <br /> 
                                    <img src={NectarCaresLogo} className={classes.TWlogo}></img>                                    
                                </strong>
                            </Typography>
                        </Grid>
                        <Grid item md={1}></Grid>
                        <Grid item xs={12} md={7} 
                            style={
                                {
                                    // maxHeight: "100%",
                                    fontSize: '1.25rem',
                                    lineHeight: '180%',
                                    // marginTop: "4%",
                                    alignContent: "center",
                                    margin: "auto",
                                }
                            }
                        >
                            <p>
                                The NectarCares app was developed with input from TrustedWorld leadership based on their experience with Dallas
                                area non-profits for more than the last decade.
                            </p>
                            <p>
                                Trusted World is responsible for helping thousands of families, either directly through the services that they 
                                offer, or through their partnerships with other organizations. Their input in identifying processes, 
                                recommending new paths forward, and dedication to helping families wherever they’re able has been invaluable.
                            </p>
                        </Grid>
                        {/* <Grid item md={1}></Grid> */}
                    </Grid>
                </div>

                <Divider />

                <div className={classes.InstructionsRoot}>
                    <Typography className={classes.InstructionsTitle}>How Does It Work?</Typography>
                    <Typography className={classes.bodyText}>
                        1. Click on &nbsp;
                        <Link href='/donations' >
                            'Fulfill a Request'                        
                        </Link>
                        .
                    </Typography>
                    <Typography className={classes.bodyText}>
                        2. Create a NectarCares account (it's free!)
                    </Typography>
                    <Typography className={classes.bodyText}>
                        3. Login to your NectarCares account
                    </Typography>
                    <Typography className={classes.bodyText}>
                        4. Choose a family that you’d like to donate to.
                    </Typography>
                    <Typography className={classes.bodyText}>
                        5. Click “donate” on that family’s card.
                    </Typography>
                    <Typography className={classes.bodyText}>
                        6. Choose the items that you’d like to donate from the requested items. If you can’t donate all of them, that’s okay! Just select the items that you have available to donate.
                    </Typography>
                    <Typography className={classes.bodyText}>
                        7. Submit your donation and print the donation summary. If you aren’t able to print when you submit your donation, you can always save it or access it later from the “Donation History” page.
                    </Typography>
                </div>

                {/* <div className={classes.bodyRoot}>                         
                    <TableContainer className={classes.organizationOverviewRoot}>
                        <Table aria-label="simple table">                       
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <img src={currentOrganization['logo']} className={classes.logo}></img>                     
                                    </TableCell>                              
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                    <strong>Mission</strong>
                                    <p>{currentOrganization['summary']}</p>
                                    </TableCell>                              
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Button
                                            variant='contained'
                                            onClick={() => window.history.back()}
                                        >
                                            Back to Organization List
                                        </Button>                                  
                                    </TableCell>                              
                                </TableRow>
                                <TableRow>
                                    <div style={{width: "95%",}}>
                                        <h4 style={{display: "flex", alignContent: "center", flexDirection: "column"}}>
                                            Share a link to this page!
                                        </h4>
                                        <FacebookShareButton quote={`Make a donation to ${currentOrganization['name']}!`} url={window.location.href} style={{marginRight: "10px"}}>
                                            <FacebookIcon round="true" size={40} />
                                        </FacebookShareButton> 
                                        <TwitterShareButton title={`Make a donation to ${currentOrganization['name']}!`} url={window.location.href} style={{marginRight: "10px"}}>
                                            <TwitterIcon round="true" size={40} />
                                        </TwitterShareButton> 
                                        <LinkedinShareButton title={`Make a donation to ${currentOrganization['name']}!`} url={window.location.href} style={{marginRight: "10px"}}>
                                            <LinkedinIcon round="true" size={40} />
                                        </LinkedinShareButton>                                        
                                    </div>
                                </TableRow>
                            </TableBody> 
                        </Table>
                    </TableContainer>
                    <Typography
                        variant='h6'
                        style={{fontWeight: 'bold'}}
                    >
                        Open Requests
                    </Typography>
                    <DonationHome organizationName={currentOrganization['name']} />
                </div>            */}
            </>
        )
    }
    
}

export default Organization
