import { Typography, Grid, TextField, FormControl, makeStyles, InputLabel, Button, FormControlLabel, Checkbox } from '@material-ui/core';
import React, { useState } from 'react';
import axios from 'axios';
import {useDropzone} from 'react-dropzone';
import FileUpload from './FileUpload';
import Loading from '../loading';

const useStyles = makeStyles((theme) => ({
    formContainer: {
        width: '70vw',
        margin: 'auto',
        marginTop: '3rem',
        marginBottom: '3rem'
    },
    submitButton: {
        backgroundColor: 'rgba(52, 211, 153, 1)',
    },
    subText: {
        fontSize: "1rem"
    },
    pageText: {
        // padding: "15px",
        margin: "2rem 0 2rem 10rem"
    }
}))

// var states = [
//     "Alaska",
//     "Arizona",
//     "Arkansas",
//     "California",
//     "Colorado",
//     "Connecticut",
//     "Delaware",
//     "Florida",
//     "Georgia",
//     "Hawaii",
//     "Idaho",
//     "Illinois",
//     "Indiana",
//     "Iowa",
//     "Kansas",
//     "Kentucky",
//     "Louisiana",
//     "Maine",
//     "Maryland",
//     "Massachusetts",
//     "Michigan",
//     "Minnesota",
//     "Mississip",
//     "Missouri",
//     "Montana",
//     "Nebraska",
//     "Nevada",
//     "New Hampshire",
//     "New Jersey",
//     "New Mexico",
//     "New York",
//     "North Carolina",
//     "North Dakota",
//     "Ohio",
//     "Oklahoma",
//     "Oregon",
//     "Pennsylvania",
//     "Rhode Island",
//     "South Carolina",
//     "South Dakota",
//     "Tennessee",
//     "Texas",
//     "Utah",
//     "Vermont",
//     "Virginia",
//     "Washington",
//     "West Virginia",
//     "Wisconsin",
//     "Wyoming",
// ]

export const PartnershipApplication = () => {
    
    const classes = useStyles();
    const [organizationName, setorganizationName] = useState();
    const [organizationWebsite, setorganizationWebsite] = useState();
    const [organizationTaxId, setorganizationTaxId] = useState();
    const [organizationAddressLine1, setorganizationAddressLine1] = useState();
    const [organizationAddressLine2, setorganizationAddressLine2] = useState();
    const [organizationCity, setorganizationCity] = useState();
    const [organizationState, setorganizationState] = useState();
    const [organizationZipCode, setorganizationZipCode] = useState();
    const [organizationSummary, setorganizationSummary] = useState();
    const [organizationGoals, setorganizationGoals] = useState();
    const [organizationAcceptsDelivery, setorganizationAcceptsDelivery] = useState(false);
    const [trustedWorldAffiliated, settrustedWorldAffiliated] = useState(false);
    const [File, setFile] = useState();

    const [adminFirstName, setadminFirstName] = useState();
    const [adminLastName, setadminLastName] = useState();
    const [adminEmail, setadminEmail] = useState();
    const [adminPhone, setadminPhone] = useState()

    const [isLoading, setisLoading] = useState(false);

    async function handleSubmit() {
        
        // validate form
        if (!validateForm()) {
            alert("Please fill in the required fields");
            return;
        }

        setisLoading(true);

        // Call upload endpoint to upload the image and get the URL
        var url;

        let data = new FormData();
        data.append('file[]', File, File.name);
        data.append('adminUpload', "false");

        const cloudServerResponse = await axios.post('https://donation-proxy-api.herokuapp.com/upload', data, {
        // const cloudServerResponse = await axios.post('http://localhost:3080/upload', data, {
            headers: {
              'accept': 'application/json',
              'Accept-Language': 'en-US,en;q=0.8',
              'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
            }
            })
            .then((response) => {
                //handle success
                url = response.data.data;
                console.log(`URL is: ${url}`);     
                // Call API endpoint with all the information               
                axios.patch('https://donation-proxy-api.herokuapp.com/partnership-submission', {
                // axios.patch('http://localhost:3080/partnership-submission', {
                    organizationName: organizationName,
                    organizationWebsite: organizationWebsite,
                    organizationTaxId: organizationTaxId,
                    organizationAddressLine1: organizationAddressLine1,
                    organizationAddressLine2: organizationAddressLine2,
                    organizationCity: organizationCity,
                    organizationState: organizationState,
                    organizationZipCode: organizationZipCode,
                    organizationSummary: organizationSummary,
                    organizationGoals: organizationGoals,
                    // organizationAcceptsDelivery: organizationAcceptsDelivery,
                    trustedWorldAffiliated : trustedWorldAffiliated,
                    url: url,
                    adminFirstName: adminFirstName,
                    adminLastName: adminLastName,
                    adminEmail: adminEmail,
                    adminPhone: adminPhone,
                    adminUpload: "false",
                })
                .then(function (response) {
                    alert("Application Submitted!");
                    // var URL = response.data;
                    // window.location.reload();
                    // console.log(URL);
                    // return donationID
                    // return new Promise((resolve, reject) => {
                    //     resolve(donationID);
                    // })
                    setisLoading(false);
                    return response;
                })
                .catch(function (error) {
                    console.log(error);
                });          
            
            }).catch((error) => {
                //handle error
                console.log(error);
                alert("Logo upload failed");                        
            });
    }

    // Make sure required fields have been filled
    function validateForm () {

        console.log(organizationName);
        console.log(organizationWebsite);
        console.log(organizationAddressLine1);
        console.log(organizationCity);
        console.log(organizationState);
        console.log(organizationZipCode);

        if (
            !File || 
            !organizationName || !organizationAddressLine1 || !organizationCity || !organizationState 
            || !organizationZipCode || !adminFirstName || !adminEmail
            || !adminLastName
            || !adminPhone
            ) {
            return false;
        }

        // Form is valid
        return true;
    }
    
    if (isLoading) {
        return (
            <Loading />
        )
    }
    
    return (
        <div>
            <div className={classes.pageText}>
                <Typography variant='h2'>
                    Become a Partner With Us
                </Typography>
                <Typography variant='caption' className={classes.subText}>
                    Use the form below to request the NectarCares team to contact you. <br />
                    We will review your request and get back to you within 24 hours.                 
                </Typography>          
                {/* <Typography variant='caption' className={classes.subText}>
                    Already a Partner? 
                </Typography> */}
            </div>

            <form className={classes.formContainer}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Typography variant='h5'>General Information</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {/* <InputLabel id="demo-simple-select-label">Organization Name</InputLabel> */}
                            <Typography variant='body1'>Organization Name (Required)</Typography>
                                <TextField 
                                    onChange={(e) => setorganizationName(e.target.value)}
                                    id="organization-name"
                                    // label="Organization Name"
                                    name="organization-name"
                                    variant="outlined"
                                    required
                                    fullWidth                                                                                                    
                                />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant='body1'>Organization Website</Typography>
                                <TextField 
                                    onChange={(e) => setorganizationWebsite(e.target.value)}
                                    id="organization-website"
                                    // label="Organization Website"
                                    // type="email"
                                    name="organization-website"
                                    required
                                    variant="outlined"
                                    fullWidth                                                                                                      
                                />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='body1'>Organization Tax ID/EIN (Required)</Typography>
                                <TextField 
                                    onChange={(e) => setorganizationTaxId(e.target.value)}
                                    id="organization-tax-id"
                                    // label="Organization Website"
                                    // type="email"
                                    name="organization-tax-id"
                                    required
                                    variant="outlined"
                                    // fullWidth                                                                                                      
                                />
                        </Grid>
                        {/* <Grid item xs={12}>
                            <Typography variant='h5'>Contact Information</Typography>
                            <Typography variant='subtitle1'>
                                If approved, we will use this information to set up an admin account for your organization.
                            </Typography>
                        </Grid> */}                      
                            <Grid item xs={12} md={4}>
                                {/* <InputLabel id="demo-simple-select-label">Organization Name</InputLabel> */}
                                <Typography variant='body1'>First Name (Required)</Typography>
                                    <TextField 
                                        onChange={(e) => setadminFirstName(e.target.value)}
                                        id="admin-name"
                                        // label="Organization Name"
                                        name="admin-name"
                                        variant="outlined"
                                        required
                                        fullWidth                                                                                                  
                                    />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                {/* <InputLabel id="demo-simple-select-label">Organization Name</InputLabel> */}
                                <Typography variant='body1'>Last Name (Required)</Typography>
                                    <TextField 
                                        onChange={(e) => setadminLastName(e.target.value)}
                                        id="admin-last-name"
                                        // label="Organization Name"
                                        name="admin-last-name"
                                        variant="outlined"
                                        required
                                        fullWidth                                                                                                  
                                    />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography variant='body1'>Email (Required)</Typography>
                                    <TextField 
                                        onChange={(e) => setadminEmail(e.target.value)}
                                        id="admin-email"
                                        // label="Organization Website"
                                        type="email"
                                        name="admin-email"
                                        required
                                        variant="outlined"
                                        fullWidth 
                                    />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography variant='body1'>Phone (Required)</Typography>
                                    <TextField 
                                        onChange={(e) => setadminPhone(e.target.value)}
                                        id="admin-phone"
                                        // label="Organization Website"
                                        type="tel"
                                        name="admin-phone"
                                        required
                                        variant="outlined"
                                        fullWidth 
                                    />
                            </Grid>
                        <Grid item xs={12}>
                            <Typography variant='body1'>Organization Address (Required)</Typography>
                                <TextField 
                                    onChange={(e) => setorganizationAddressLine1(e.target.value)}
                                    id="organization-address2"
                                    placeholder="Address Line 1"
                                    // type="email"
                                    name="organization-address"
                                    required
                                    variant="outlined"
                                    fullWidth                                                                                                     
                                />
                        </Grid>
                        <Grid item xs={12}>
                                <TextField 
                                    onChange={(e) => setorganizationAddressLine2(e.target.value)}
                                    id="organization-address2"
                                    placeholder="Address Line 2"
                                    // type="email"
                                    name="organization-address"
                                    required
                                    variant="outlined"
                                    fullWidth                                                                                                      
                                />
                        </Grid>
                        <Grid item xs={12} md={4}>
                                <TextField 
                                    onChange={(e) => setorganizationCity(e.target.value)}
                                    id="organization-city"
                                    label="City"
                                    // type="email"
                                    name="organization-address"
                                    required
                                    variant="outlined"
                                    fullWidth                                                                                                       
                                />
                        </Grid>
                        <Grid item xs={12} md={4}>
                                <TextField 
                                    onChange={(e) => setorganizationState(e.target.value)}
                                    id="organization-state"
                                    label="State"
                                    // type="email"
                                    name="organization-state"
                                    required
                                    variant="outlined"
                                    fullWidth                                                                                                    
                                />
                        </Grid>
                        <Grid item xs={12} md={4}>
                                <TextField 
                                    onChange={(e) => setorganizationZipCode(e.target.value)}
                                    id="organization-zip"
                                    label="Zip"
                                    // type="email"
                                    name="organization-zip"
                                    required
                                    variant="outlined"
                                    fullWidth                                                                                                      
                                />
                        </Grid>
                        <Grid item xs={12} style={{marginTop: "-25px"}}>
                            <FormControlLabel
                                control={
                                <Checkbox
                                    checked={trustedWorldAffiliated}
                                    onChange={(e) => settrustedWorldAffiliated(e.target.checked)}
                                    name="trustedWorldAffiliatedCheckbox"
                                    color="primary"
                                />
                                }
                                label="Is your organization affiliated with Trusted World?"
                            />        
                        </Grid> 
                        {/* <Grid item xs={12} style={{marginTop: "-25px"}}>
                            <FormControlLabel
                                control={
                                <Checkbox
                                    checked={organizationAcceptsDelivery}
                                    onChange={(e) => setorganizationAcceptsDelivery(e.target.checked)}
                                    name="deliveryAcceptedCheckbox"
                                    color="primary"
                                />
                                }
                                label="Donors can deliver items to this location"
                            />        
                        </Grid> */}
                        <Grid item xs={12}>
                            <Typography variant='body1'>Upload your logo (Required)</Typography>
                            <FileUpload setFile={setFile} />
                        </Grid>
                        {/* <Grid item xs={12}>
                            <Typography variant='body1'>Upload your logo(2)</Typography>
                            <input id="my-file-selector" type="file" name="file" onChange={(e) => onFileChange(e)} />
                        </Grid> */}
                        <Grid item xs={12}>
                            <Typography variant='body1'>Organization Description</Typography>
                            <InputLabel id="demo-simple-select-label">
                                Please describe your organization
                            </InputLabel>
                                <TextField 
                                    onChange={(e) => setorganizationSummary(e.target.value)}
                                    id="organization-website"
                                    placeholder="Helping Hands provides furniture and household items for families who are in need. 
                                    We've helped build families up for the last 2 years!"
                                    // type="email"
                                    name="organization-address"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={4}
                                />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='body1'>Goals</Typography>
                            <InputLabel id="demo-simple-select-label">
                                What are your goals with NectarCares and what would you want to get from the platform?
                            </InputLabel>
                                <TextField 
                                    onChange={(e) => setorganizationGoals(e.target.value)}
                                    id="organization-website"
                                    // placeholder="All items will need to be dropped off at our home office, 1234 Main Street,
                                    // between the hours of 9 am and 7 pm, Tuesday through Saturday"
                                    // type="email"
                                    name="organization-address"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={4}
                                />
                        </Grid>                                             
                        <Grid item xs={12} cen>
                            <Button 
                                variant="contained" 
                                onClick={() => handleSubmit()} 
                                className={classes.submitButton}
                                // disabled={() => validateForm()}
                            >
                                Submit Request
                            </Button>
                        </Grid>
                    </Grid>                 
                </form>
        </div>
    )
}
