import { ClassNames } from '@emotion/react';
import { Button, Divider, makeStyles, Typography, useMediaQuery, Grid, Card, CardContent, TableRow, Table, TableBody, TableContainer, Paper, withStyles, TableHead } from '@material-ui/core';
import React, { useEffect, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import logo from '../../img/TrustedWorldLogo.png';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import { DonationHome } from '..';
import MuiTableCell from "@material-ui/core/TableCell";
import Loading from '../loading';
import { Link, useHistory } from 'react-router-dom';
import { WindowRounded } from '@mui/icons-material';

/*

    This component returns a list of all the approved non profit organizations that have partnered with NectarCares

*/

const useStyles = makeStyles((theme) => ({
    logo: {
        // maxWidth: "100%",
        // height: "auto",
        height: "15vh",
        width: "auto",
        margin: "auto",
        display: "block",
        // justifyContent: "center",
        padding: "20px",
        maxHeight: "150px"
    },
    bodyRoot: {
        margin: "auto 12vw",
        marginTop: "2rem",
    },
    organizationOverviewRoot: {
        marginBottom: "4rem",
        marginTop: "4rem",
    },
    requestsRoot: {

    },
}));

const TableCell = withStyles({
    root: {
      borderBottom: "none"
    }
  })(MuiTableCell);

var api_call_limiter = 0;

  const OrganizationList = () => {
    
    const classes = useStyles();
    // let { id } = useParams();
    // var organizationList;
    const [organizationList, setorganizationList] = useState();
    const history = useHistory();

    useEffect(() => {
        if (api_call_limiter <= 1 || !organizationList) {
            // search the backend for the non profit org "id" & retrieve it's info
            let url = 'https://api.sheety.co/591c67f7e588d2a49e1cf55d260ce8a5/recipient/nonProfitOrgs';
            fetch(url)
            .then((response) => response.json())
            .then(json => {
                // Do something with the data
                console.log(json.nonProfitOrgs);
                // organizationList = json.nonProfitOrgs;
                setorganizationList(json.nonProfitOrgs);
            });
            api_call_limiter++;
        }
    })
    
    function handleClick(organization) {
        var orgSlug = '';
        var nameArray = organization['name'].split(' ');

        console.log(nameArray);

        for (var i=0; i<nameArray.length; i++) {
            if (i == nameArray.length-1) {
                orgSlug = orgSlug + organization['name'].split(' ')[i];
            } else {    
                orgSlug = orgSlug + organization['name'].split(' ')[i] + '-';
            }
        }
        // set Redirect link for "Make another donation" in the session storage
        sessionStorage.setItem('makeAnotherDonationURL', "/organizations/"+orgSlug.toLowerCase());

        if (orgSlug == "Trusted-World") {        
            history.push("/partnerships/trustedworld");
        } else {
            history.push("/organizations/"+orgSlug.toLowerCase());
        }
        
    }

    if (!organizationList) {
        return <Loading />
    } else {
        return (
            <>
                <div className={classes.bodyRoot}>   
                    <Typography variant='h5'>
                        Organization List
                    </Typography>
                    <TableContainer component={Paper} className="table" style={{marginTop: "1rem"}}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell><strong>Name</strong></TableCell>
                                    <TableCell><strong>Mission</strong></TableCell>
                                    {/* <TableCell align="right"><strong>Request Summary</strong></TableCell> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                    {                                                                                     
                                        organizationList.map((key, index) => (
                                            <TableRow key={index}>
                                            {
                                                (
                                                key['status'] == "Approved"
                                                )
                                                ?
                                                <>
                                                    <TableCell>{key['name']}</TableCell>
                                                    <TableCell>{key['summary']}</TableCell>
                                                    <TableCell component="th" scope="row" align="right">
                                                        {/* <Link to={"/item-history/"+myContext.requests[key]['reqId']}> */}
                                                            <Button variant="contained"
                                                                onClick={() => handleClick(key)}
                                                            >
                                                                Homepage
                                                            </Button>
                                                        {/* </Link> */}
                                                    </TableCell>
                                                </>
                                                :
                                                null
                                            }
                                            </TableRow>                                
                                        ))
                                    }
                            </TableBody>
                        </Table>
                    </TableContainer>        
                </div>           
            </>
        )
    }
    
}

export default OrganizationList
