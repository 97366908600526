import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionActions from "@material-ui/core/AccordionActions";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { 
    FormControl, FormLabel, FormControlLabel, RadioGroup, Radio, 
    Table, TableHead, TableRow, TableCell, TableBody, TableContainer, 
    Paper, IconButton, Collapse, Box, InputLabel, Select, MenuItem, TextField, Chip
} from "@material-ui/core";
import AppContext from "../AppContext";
import { useHistory, useParams } from 'react-router-dom';
import axios from "axios";
import EditIcon from '@material-ui/icons/Edit';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import FileUpload from "../Partnerships/FileUpload";
import Loading from '../loading';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "85%",
    margin: 'auto',
    marginBottom: 40
  },
  heading: {
    fontSize: theme.typography.pxToRem(15)
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20
  },
  details: {
    alignItems: "center",
  },
  column: {
    // flexBasis: "33.33%",
    display: 'tableCell',
    padding: '16px',
    fontSize: '0.875rem',
    textAlign: 'left',
    // fontFamily: "Roboto", "Helvetica", "Arial", sans-serif,
    // borderBottom: 1px solid rgba(224, 224, 224, 1),
    letterSpacing: '0.01071em',
    verticalAlign: 'inherit',
  },
  helper: {
    // borderRight: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(10, 2)
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  partiallyAcceptedDropdownRoot: {
      padding: 15,
      margin: 'auto',
    //   width: 'fit-content',
      width: '80%',
  },
  partiallyAcceptedDropdown: {
    //   padding: 15
    marginRight: 10
  },
  statusUpdateButton: {
      margin: 15
  },
  partiallyAcceptedNotesBox: {
      marginTop: 20,
  }
}));

// function to convert item name from camelcase to normal word (Eg: pillowCases(standard) -> pillow cases(standard)) 
function convertCase(item) {
    var gapi_key;

    if(/[A-Z]/.test(item)) {
        // split word at capital letter
        var str_array = item.split(/(?=[A-Z])/);
        gapi_key = str_array.join(' ');
        gapi_key = gapi_key.toLowerCase();
    } else {
        return item;
    }
    
    console.log(`converted ${item} to `);
    return gapi_key;
}

// export default function DonationList ({id}) {
export default function DonationList ({id, adopterEmail}) {

    const myContext = useContext(AppContext);
    const classes = useStyles();
    // const [itemsRejected, setitemsRejected] = useState("");
    // var itemsRejected = "";
    var itemsRejected = [];
    var notes = "";
    var notesObject = {};
    // const { id } = useParams();
    // const [File, setFile] = useState();
    var Files = [];
    const [isLoading, setisLoading] = useState(false);

    // Setter function passed to FileUpload component that sets the file object once it has been uploaded
    function setFile(file) {
        // File = file;
        Files.push(file);
    }

    useEffect(() => {
        // alert(`ID: ${id}`);
        console.log("In DonationList useEffect...");
        // console.log(id);
        // console.log(myContext.donations[3]['recipientId']);
        // if (myContext.donations[3]['recipientId'] == id) {
            //     console.log("ID match!")
            // }
        console.log(myContext.donations);
        if (!myContext.donations) {
            // alert("Setting donations in DonationList.jsx");
            let url = process.env.REACT_APP_SHEETY_GET_DONATIONS;
            fetch(url)
            .then((response) => response.json())
            .then(json => {
            // Do something with the data
                console.log("Setting donations in RequestSummary.jsx(2)");
                myContext.setdonations(json.donors);
            });
        }
    })  
    
    // Take the new status and relevant row id and make a PATCH API call to the backend
    async function handleStatusUpdate(value, row) {
        // alert("Handling status update...");
        console.log(value);        
        // alert(value);    
        var rowId = parseInt(value.split('-')[1]);
        var status = value.split('-')[0];
        // alert(`New status is ${status}`);
        
        console.log(notesObject);
        // itemsRejected = itemsRejected.slice(0, -1);

        setisLoading(true);
        

        // if (!value.includes("Rejected") && !value.includes("Partially Accepted")) {
        if (status == "Pending" || status == "Received" || status == "Processing" || status == "Accepted") {
            // alert("no image upload");
            // Call API endpoint with all the information image url etc.)
            axios.patch('https://donation-proxy-api.herokuapp.com/status-update', {
            // axios.patch('http://localhost:3080/status-update', {
                newStatus: status,
                rowId: rowId,
                itemsRejected: itemsRejected,
                notes: notesObject,
                donorEmail: row['email'],
                nonProfitContact: row['contact'],
                itemsDonated: row['itemsDonated'],
                url: '',
                nonprofitName: myContext.nonprofitName
            })
            .then(function (response) {
                alert(response.data);
                // alert(`Status has been updated to ${status}`);
                window.location.reload();
                // return response;
            })
            .catch(function (error) {
                alert(error);
            })
            // window.location.reload();
            // return;
        } else {
            // Enters here if an image upload needs to be made i.e, Rejected, Partially Accepted
            // alert("Setting up image upload")
            // If status is being changed to "Rejected", we need to set items rejected to all items donated
            if (status == "Rejected") {
                // alert("Rejecting all items.");
    
                var itemsDonatedArray = row['itemsDonated'].split(",");
                for (var i=0; i<itemsDonatedArray.length; i++) {
                    // If item has already been added, update the value                   
                    var newObject = {
                        "item": i == 0 ? itemsDonatedArray[i].split(" ")[1] : itemsDonatedArray[i].split(" ")[2],
                        "quantity": i == 0 ? itemsDonatedArray[i].split(" ")[0] : itemsDonatedArray[i].split(" ")[1]
                    }
    
                    console.log(newObject);
                    
                    itemsRejected.push(newObject);
                }
                // itemsRejected = row['itemsDonated'].split(",").toString()
                console.log(itemsRejected);
            }
            
            // Send image of damaged item to donor server API end point
            // setisLoading(true);
            // Call upload endpoint to upload the image and get the URL
            var urlList;
            var statusUpdateResponse;
            let data = new FormData();
    
            // loop through and add all the files to the formdata object
            console.log(Files);
            for (var i=0; i<Files.length; i++) {
                data.append('file[]', Files[i], Files[i].name);
                console.log(Files[i]);
            }
    
            data.append('adminUpload', "true");
    
            console.log(data.getAll('adminUpload'));
            console.log(data.getAll('file'));
            console.log(data.getAll('file[]'));
            // alert("sending post request to update endpoint");

            const cloudServerResponse = await axios.post('https://donation-proxy-api.herokuapp.com/upload', data, {
            // const cloudServerResponse = await axios.post('http://localhost:3080/upload', data, {
                headers: {
                    'accept': 'application/json',
                    'Accept-Language': 'en-US,en;q=0.8',
                    'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                }
            })
            .then((response) => {
                //handle success
                console.log(response);
                urlList = response.data.data;
                console.log(`URL list is:`);
                console.log(urlList);
                // alert("url list has been returned");
    
                // Call API endpoint with all the information image url etc.)
                axios.patch('https://donation-proxy-api.herokuapp.com/status-update', {
                // axios.patch('http://localhost:3080/status-update', {
                    newStatus: value.split('-')[0],
                    rowId: rowId,
                    itemsRejected: itemsRejected,
                    notes: notesObject,
                    donorEmail: row['email'],
                    nonProfitContact: row['contact'],
                    itemsDonated: row['itemsDonated'],
                    url: urlList,
                })
                .then(function (response) {
                    // alert("then...");
                    alert(response.data);
                    // setisLoading(false);
                    window.location.reload();
                })
                .catch(function (error) {
                    console.log(error);
                })                              
            }).catch((error) => {
                //handle error
                console.log(error);
                alert("Image upload failed");                        
            });

        }   // end of else for Rejected and Partially Accepted items

            // window.location.reload();
            // const res = await statusUpdateResponse;
    }

    function handleSelectChange(event) {
        console.log("handlingSelectChange");
        console.log(event);
        // setitemsRejected(
        //     itemsRejected + event.target.value + " " + event.target.name + ", "
        // )   

        // if this item has been added before, update the quantity instead of appending to the list
        // if (itemsRejected.includes(event.target.name)) {
        //     console.log("Item has been added. Updating value");
        //     var startIndex = itemsRejected.indexOf(event.target.name);
        //     let newItemsRejected = itemsRejected.replace(itemsRejected.charAt(startIndex) + " " + event.target.name)
        //     itemsRejected.split(",").forEach((key, index) => {
        //         // var itemName = 
        //         console.log(key);
        //     })

        // } else {
        //     itemsRejected = itemsRejected + event.target.value + " " + event.target.name + ", ";
        // }

        // If item has already been added, update the value
        for (let index = 0; index < itemsRejected.length; index++) {
            if(itemsRejected[index]["item"] == event.target.name) {
                itemsRejected[index]["quantity"] = event.target.value;
                return;
            }
        }
                
        var newObject = {
            "item": event.target.name,
            "quantity": event.target.value
        }
        
        itemsRejected.push(newObject);
        
        console.log(itemsRejected);
    }

    function RadioButtons(props) {
        const { row } = props;
        const [value, setValue] = React.useState(row['completed']);                // Status dropdown value
        const [isPartiallyAccepted, setisPartiallyAccepted] = useState();
        const [rejected, setrejected] = useState();

        const handleChangeRadioGroup = (event) => {
            setValue(event.target.value);
            if (event.target.value.includes("Partially Accepted")) {
                setisPartiallyAccepted(true);
            }
            if (event.target.value.includes("Rejected")) {
                setrejected(true);
            }
        };       

        // Get the number associated with the argument "item"
        function getNumber(item) {
            var number = item.split(" ")[0];
            if (number == "") {
                number = item.split(" ")[1];                
            }

            return number;
        }

        if(isPartiallyAccepted == true) {
            return (
                <>
                    <Typography>Select the items that have been rejected</Typography>
                    <div className={classes.partiallyAcceptedDropdownRoot}>
                        {
                            row['itemsDonated'].split(",").map((item, index) => (
                                <>
                                    <FormControl key={index} className={classes.partiallyAcceptedDropdown}>
                                        <InputLabel id={item}>{item.split(" ")[0] == "" ? convertCase(item.split(" ")[2]) : convertCase(item.split(" ")[1])}</InputLabel>
                                        <Select
                                            name={item.split(" ")[item.split(" ").length-1]}
                                            // value={(!myContext.donatedItems) ? '' : myContext.donatedItems[item.slice(0,-1)]}
                                            onChange={handleSelectChange}
                                            // className="dropdown-item"
                                            style={{width: "13em"}}
                                        >                          
                                        {console.log(item.split(" "))}                  
                                            <MenuItem aria-label="None" value={0}></MenuItem>                                                                                                           
                                            {Array(parseInt(Array(getNumber(item)))+1).fill(0).map((value, i) => (
                                                <MenuItem key={i} value={i}>{i}</MenuItem>
                                            ))}                                                                                 
                                        </Select>
                                    </FormControl>
                                    <TextField 
                                        name="notes" 
                                        type="text" 
                                        label="Notes" 
                                        placeholder="Enter your notes" 
                                        variant="outlined"
                                        // onChange={(event) => {notes = event.target.value}}
                                        onChange={(event) => {notesObject[item.split(" ")[0] == "" ? item.split(" ")[2] : item.split(" ")[1]] = event.target.value}}
                                        className={classes.partiallyAcceptedNotesBox}
                                        fullWidth 
                                        multiline 
                                        rows={4}
                                        required
                                    />
                                    <div style={{marginTop: "2rem", marginBottom: "3rem"}}>
                                        <Typography>Upload image of the damaged item</Typography>
                                        <FileUpload setFile={setFile} />                                        
                                    </div>
                                </>
                            ))
                        }                                   
                    </div>
                    <Button
                        variant="outlined"
                        onClick={() => setisPartiallyAccepted(false)}
                        className={classes.statusUpdateButton}
                        // color="primary"
                    >
                        Back
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={() => handleStatusUpdate(value, row)}
                        className={classes.statusUpdateButton}
                        color="primary"
                    >
                        Update
                    </Button>
                </>
            )
        } else if (rejected == true) {
            return (
                <>
                    <Typography>Enter notes (if needed) and add an image of each rejected item</Typography>
                    <div className={classes.partiallyAcceptedDropdownRoot}>
                        {
                            row['itemsDonated'].split(",").map((item, index) => (
                                <>                                    
                                    {/* <Typography>{parseInt(Array(getNumber(item)))+1 + item.split(" ")[0] == "" ? convertCase(item.split(" ")[2]) : convertCase(item.split(" ")[1])}</Typography> */}
                                    <Typography>{item.split(" ")[0] == "" ? convertCase(item.split(" ")[2]) : convertCase(item.split(" ")[1])}</Typography>
                                    <TextField 
                                        name="notes" 
                                        type="text" 
                                        label="Notes" 
                                        placeholder="Enter your notes" 
                                        variant="outlined"
                                        // onChange={(event) => {notes = event.target.value}}
                                        onChange={(event) => {notesObject[item.split(" ")[0] == "" ? item.split(" ")[2] : item.split(" ")[1]] = event.target.value}}
                                        className={classes.partiallyAcceptedNotesBox}
                                        fullWidth 
                                        multiline 
                                        rows={4}
                                        required
                                    />
                                    <div style={{marginTop: "2rem", marginBottom: "3rem"}}>
                                        <Typography>Upload image of the damaged item</Typography>
                                        <FileUpload setFile={setFile} />                                        
                                    </div>
                                </>
                            ))
                        }                                   
                    </div>
                    <Button
                        variant="outlined"
                        onClick={() => setrejected(false)}
                        className={classes.statusUpdateButton}
                        // color="primary"
                    >
                        Back
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={() => handleStatusUpdate(value, row)}
                        className={classes.statusUpdateButton}
                        color="primary"
                    >
                        Update
                    </Button>
                </>
            )
        } else {
            return(
                <>
                    <FormControl component="fieldset">
                        {/* <FormLabel component="legend">Gender 1</FormLabel> */}
                        <RadioGroup aria-label="status" name="status" value={value} onChange={handleChangeRadioGroup}>
                            <FormControlLabel value={"Pending"+"-"+row['id']} control={<Radio />} label="Pending" />
                            <FormControlLabel value={"Received"+"-"+row['id']} control={<Radio />} label="Received" />
                            <FormControlLabel value={"Processing"+"-"+row['id']} control={<Radio />} label="Processing" />
                            <FormControlLabel value={"Partially Accepted"+"-"+row['id']} control={<Radio />} label="Partially Accepted" />
                            <FormControlLabel value={"Accepted"+"-"+row['id']} control={<Radio />} label="Accepted" />
                            <FormControlLabel value={"Rejected"+"-"+row['id']} control={<Radio />} label="Rejected" />
                        </RadioGroup>
                    </FormControl><br /> 
                    <Button
                        variant="outlined"
                        onClick={() => handleStatusUpdate(value, row)}
                        className={classes.statusUpdateButton}
                        color="primary"
                        disabled={value.includes("Partially Accepted")}
                    >
                        Update
                    </Button>
                </>  
            )
        }
    }

    var open_var = false;

    function Row(props) {
        const { row } = props;
        const [open, setOpen] = React.useState(open_var);

        useEffect(() => {
            console.log(`in useEffect, open: ${open}`)                           
        })
      
        function setOpenOverride(value) {
            console.log(`Setting open to ${value}`);
            console.log(`open before: ${open}`);
            open_var = value;
            setOpen(value)
            console.log(`open after: ${open}`);
        }
        
        if (row['recipientId'] != id) {
            // console.log("ID does not match");
            return (<></>);
        } else {
            return (
              <React.Fragment>
                <TableRow>
                    <TableCell>
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpenOverride(!open)}>
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>                          
                    <TableCell>{row['name']}</TableCell>
                    <TableCell>
                        {
                            adopterEmail == row['email'] ?
                                <div>                                    
                                    {row['email']} &nbsp;
                                    <Chip 
                                        size='small'
                                        icon={<VolunteerActivismIcon style={{
                                            fontSize: '1rem'
                                        }} />} 
                                        label="Adopter"
                                        color='primary'
                                        variant='default'
                                        style={{
                                            marginBottom: "0px", 
                                            // marginLeft: '0.4rem', 
                                            marginTop:'0.5rem', 
                                            // display: 'block',
                                            width: 'fit-content',
                                        }} 
                                    />
                                </div>
                            :
                                row['email']
                        }
                    </TableCell>
                    <TableCell>{convertCase(row['itemsDonated'])}</TableCell>
                    <TableCell align="right">{row['completed']}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto">
                        <RadioButtons row={row} />                  
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            );
        }
        
    }

    if (isLoading) {
        return (
            <Loading />
        )
    }
    
    return (
    <div className={classes.root}>
        <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
            <TableHead>
            <TableRow>
                <TableCell />
                <TableCell>Donor Name</TableCell>
                <TableCell>Donor Email</TableCell>
                <TableCell>Items Donated</TableCell>
                <TableCell align="right">Status</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {
                myContext.donations ? 
                Object.keys(myContext.donations).map((key, index) => (
                    <Row key={index} row={myContext.donations[key]} />
                ))
                :
                null
            }
            </TableBody>
        </Table>
        </TableContainer>
        {/* <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell><strong>Donor Name</strong></TableCell>
                        <TableCell><strong>Donor Email</strong></TableCell>
                        <TableCell><strong>Items</strong></TableCell>
                        <TableCell align="right"><strong>Status</strong></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {
                    Object.keys(myContext.donations).map((key, index) => (
                        <div key={index}>
                        {
                            myContext.donations[key]['recipientId'] == id ?
                            <TableRow>
                                <TableCell>{myContext.donations[key]['name']}</TableCell>
                                <TableCell>{myContext.donations[key]['email']}</TableCell>
                                <TableCell>{myContext.donations[key]['itemsDonated']}</TableCell>
                                <TableCell align="right">{myContext.donations[key]['completed']}</TableCell>
                                <TableCell align="right">
                                    <EditIcon />
                                </TableCell>
                            </TableRow>
                            :
                            null
                        }
                        </div>
                    ))
                }
                </TableBody>
            </Table>
        </TableContainer>
            </div>    
            ))
        } */}
    </div>
    );
}
