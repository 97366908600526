import './App.css';
import { Donation, DonationHome, Navbar, Login, History, BasicSummary, RecipientRequest, Home , DonationModal, Failure, Profile, RequestHistory, RequestSummary, ItemsMatch, Organization, Partnerships, 
  Footer, OrganizationList, Referrals, WhyNectarCares, NFTLanding, MailchimpFormContainer, AboutUs, Adopt, AdoptLandingPage, RequestUpdate, AdoptionHistory } from './Components';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import React, { useState, useContext, useEffect } from 'react';
import { StylesProvider } from '@material-ui/core/styles';
import './Components/DonationHome/DonationHome.css';
import Auth0ProviderWithHistory from './auth/auth0-provider-with-history';
import { useAuth0 } from '@auth0/auth0-react';
// import { Loading } from './Components/loading';
import ProtectedRoute from './auth/protected-route';
import Loading from './Components/loading';
import AppContext from './Components/AppContext';
import { Hidden } from '@material-ui/core';
import Policy from './Components/Policy/Policy';
import { ToastContainer } from 'react-toastify';
import ReactGA from 'react-ga';
const TRACKING_ID = "UA-241961652-1";
ReactGA.initialize(TRACKING_ID);


var email;
var loggedIn;
var pop_up_counter = 0;

// var requests='Loading...';

function App() {

  // const [donorLoggedIn, setdonorLoggedIn] = useState(false);
  const [requests, setrequests] = useState(require('./requests.json').requests);
  const [donations, setdonations] = useState();
  
  // const [donatedItems, setdonatedItems] = React.useState({});                 // items donate by donor. Set on dropdown change
  const [deliveryInstructions, setdeliveryInstructions] = useState('');
  const [familyID, setfamilyID] = useState('');
  const [deliveryAddress, setdeliveryAddress] = useState([]);
  const [npEmail, setnpEmail] = useState('tim@trustedworld.org');
  const [nonprofitName, setnonprofitName] = useState('');
  // var itemsRequested = {} || '';
  
  const [requestedItems, setrequestedItems] = React.useState({});                 // items requested by recipient. Set on dropdown change
  
  const { isLoading, user, isAuthenticated } = useAuth0();
  const [itemsNeeded, setItemsNeeded] = useState([]);                                     // items of current request. Set on modal open
  var itemsRequested = {} || '';
  var donatedItems = {} || '';
  
  const [nonProfitOrgs, setnonProfitOrgs] = useState();
  const [newsletterPopup, setnewsletterPopup] = useState(false);  
  
  const [triggerUseEffect, settriggerUseEffect] = useState(0);
  
  const [timeline, setTimeline] = useState();
  
  const [donorHasAdopted, setDonorHasAdopted] = React.useState(false);
  const [otherDonorHasAdopted, setOtherDonorHasAdopted] = React.useState(false);
  // const [adoptionFlag, setAdoptionFlag] = React.useState(-1);
  const [adoptions, setAdoptions] = useState([
    {status: 'loading'}
  ]);
  

  useEffect(() => {
    console.log("IN APP.JS");
    console.log(isAuthenticated);
    console.log(user);
    if (isAuthenticated) {
      console.log(user);
      console.log(user["https://nectarcares.org/roles"][0]);
      // console.log(user["http://mynamespace/roles"][0]);
    } else {
      // user["https://nectarcares.org/roles"] = ["",""];
      // user["https://nectarcares.org/roles"][0] = "";
    }
    
    if (pop_up_counter <= 1 && !window.sessionStorage.getItem('popupActivated')) {
      settriggerUseEffect(1)
    }
  }, [])

  // This second useEffect hook handles the newsletter popup
  useEffect(() => {
     // if pop up has not been activated yet
     if (pop_up_counter <= 1 && !window.sessionStorage.getItem('popupActivated')) {
      setTimeout(() => {
        window.sessionStorage.setItem('popupActivated', true);
        setnewsletterPopup(true);
        pop_up_counter++;
      }, 10000);
    }
  }, [triggerUseEffect])
  // // This second useEffect hook handles the newsletter popup
  // useEffect(() => {
  //    // if pop up has not been activated yet
  //    if (pop_up_counter <= 1 && !window.sessionStorage.getItem('popupActivated')) {
  //     // if timer has not been started, start timer
  //     if (!window.sessionStorage.getItem('newsletterTimerStart')) {
  //       // start timer for newsletter pop up
  //       window.sessionStorage.setItem('newsletterTimerStart', Date.now());
  //     } else {
  //       // check if 5 seconds have passed
  //       var start = window.sessionStorage.getItem('newsletterTimerStart');
  //       var millis = Date.now() - start;
  //       var secondsElapsed = Math.floor(millis / 1000);
        
  //       if (secondsElapsed >= 5) {
  //         // pop up sign up window/modal
  //         window.sessionStorage.setItem('popupActivated', true);
  //         setnewsletterPopup(true);
  //         pop_up_counter++;
  //       }
  //     }
  //   }
  // }, [newsletterPopup, triggerUseEffect])
  
  
  if (isLoading) {
    <Loading />;
  }

  // Create Context object
  const donationContext = {
    requests,
    setrequests,
    donatedItems,
    // setdonatedItems,
    deliveryInstructions,
    setdeliveryInstructions,
    familyID,
    setfamilyID,
    deliveryAddress,
    setdeliveryAddress,
    npEmail,
    setnpEmail,
    itemsNeeded,
    setItemsNeeded,
    nonprofitName,
    setnonprofitName,

    // Donation sheet
    donations,
    setdonations,

    //item request flow
    itemsRequested,

    nonProfitOrgs,
    setnonProfitOrgs,
    
    // item pickup flow
    timeline,
    setTimeline,

    // Adoption flow
    donorHasAdopted,
    setDonorHasAdopted,

    otherDonorHasAdopted,
    setOtherDonorHasAdopted,

    adoptions,
    setAdoptions
  }

  const checkStatus = () => {
    console.log("Checking status");
    email = window.sessionStorage.getItem('donorEmail');
    console.log(`session storage: ${email}`);
    if (email == '' || email == null) {
      console.log(`Returning false: ${email}`);
      loggedIn = false;
      return false;
    }
    else {
      console.log(`Returning true: ${email}`);
      loggedIn = true;
      return true;
    }
  }

  return (
    <StylesProvider injectFirst>
    <AppContext.Provider value={donationContext}>
      <div style={{
        // backgroundColor: "blue",
        position: "relative",
        minHeight: "100vh",
        overflow: "hidden"
        // overflowX: "hidden",
      }}>
      {/* <Router> */}
        {/* <Auth0ProviderWithHistory> */}
        <div style={{
          // paddingBottom: "12rem",
          marginBottom: '23rem'
        }}>
          <Navbar loggedIn={loggedIn}/>
          {
            newsletterPopup ? <MailchimpFormContainer setnewsletterPopup={setnewsletterPopup} /> : null
          }
          <Switch>
                {/* <Navbar loggedIn={loggedIn}/> */}
            <ProtectedRoute exact path="/history"
                req={requests}
                component={History}
            />
            <ProtectedRoute exact path="/summary"
              familyID={familyID} deliveryInstructions={deliveryInstructions} deliveryAddress={deliveryAddress} item_list={donatedItems} npEmail={npEmail}
              componenet={BasicSummary}
            />
            <ProtectedRoute exact path="/item-request"                
                // component={checkAdmin() ? RecipientRequest : Failure}
                component={RecipientRequest}
            />
            <ProtectedRoute exact path="/donations"
                // requests={setrequests} 
                // donatedItems={donatedItems} 
                // setdonatedItems={setdonatedItems}
                // setdeliveryInstructions = {setdeliveryInstructions}
                // setdeliveryAddress = {setdeliveryAddress}
                // setfamilyID = {setfamilyID}
                // setnpEmail = {setnpEmail}
                // familyID={familyID} deliveryInstructions={deliveryInstructions} deliveryAddress={deliveryAddress} item_list={donatedItems} 
                npEmailGlobal={npEmail}
                component={DonationHome}                
            />            
            <ProtectedRoute path="/donations/:id"
                component={DonationModal}
            />            
            <ProtectedRoute path="/profile/:id"
                component={Profile}
            />            
            <ProtectedRoute exact path="/item-history/"
                component={RequestHistory}
            />            
            <ProtectedRoute exact path="/adoption-history/"
                component={AdoptionHistory}
            />            
            <ProtectedRoute exact path="/item-history/:id"
                component={RequestSummary}
            />      
            <ProtectedRoute exact path="/update-request/:id"
                component={RequestUpdate}
            />        
            <Route exact path="/organizations/:id"
                component={Organization}
            />            
            <Route exact path="/organizations"
                component={OrganizationList}
            />            
            <ProtectedRoute exact path="/items-match"
                component={ItemsMatch}
            />            
            <ProtectedRoute exact path="/refer"
                component={Referrals}
            />            
            {/* <ProtectedRoute path="/item-request/:id"
                component={RequestSummary}
            />             */}
            <ProtectedRoute exact path="/adopt/:id"
                component={Adopt}
            />   
            <Route exact path="/adopt-a-family"
                component={AdoptLandingPage}
            />   
            <Route path="/partnerships/:organization"
                component={Partnerships}
            />            
            <Route exact path="/why-nectarcares">
                <WhyNectarCares />
            </Route>
            <Route exact path="/nft">
                <NFTLanding />
            </Route>
            <Route exact path="/about">
                <AboutUs />
            </Route>
            <Route exact path="/privacy-policy">
              <Policy />
            </Route>
            <Route exact path="/">
                <Home />
            </Route>
          </Switch>
        </div>
        {/* </Auth0ProviderWithHistory> */}
      {/* </Router> */}
        <Footer setnewsletterPopup={setnewsletterPopup} />
        <ToastContainer />
      </div>
    </AppContext.Provider>
    </StylesProvider>
  );
}

export default App;
