import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
    BrowserRouter as Router,
    Link,
    useHistory,
    useParams
} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { PowerOffRounded } from '@material-ui/icons';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    profileRoot: {
        margin: 'auto',
        padding: 20,
        textAlign: 'center',
    },
    formRoot: {
        // textAlign: 'left',
    },
    profileBody: {
        // maxWidth: '70vw'
        maxWidth: 'fit-content',
        margin: 'auto',
        textAlign: 'left',
    },
    editProfileButton: {
        margin: 25,
    },
    fieldLabel: {
        // width: '50%',
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "center",
    }
}));

const Profile = () => {
    
    const { user, isAuthenticated } = useAuth0();
    let { id } = useParams();
    const classes = useStyles();

    const [editMode, seteditMode] = useState(false);

    const [firstName, setfirstName] = useState(user['https://example.com/first_name']);
    const [lastName, setlastName] = useState(user['https://example.com/last_name']);
    const [phoneNumber, setphoneNumber] = useState(user['https://example.com/phone_number'])
    const [preferredCommunicationMethod, setpreferredCommunicationMethod] = useState(user['https://example.com/preferred_communication_method'])

    let history = useHistory();


    useEffect(() => {
        console.log('in Profile.jsx useEffect');
        console.log(user['http://mynamespace/roles'][0]);
        console.log(user);
    })

    function NonEditDisplay() {
        return(
            <>
                {
                    (isAuthenticated && id==user.sub) ?
                    <div className={classes.profileRoot}>
                        <h1>User Profile</h1>
                        <img src={user.picture} />
                        <div className={classes.profileBody}>

                        <p><strong>Email: </strong>{user.email}</p>
                        <p><strong>First Name: </strong>{user['https://example.com/first_name']}</p>
                        <p><strong>Last Name: </strong>{user['https://example.com/last_name']}</p>
                        <p><strong>Phone Number: </strong>{user['https://example.com/phone_number']}</p>
                        <p><strong>Preferred Communication Method: </strong>{user['https://example.com/preferred_communication_method']}</p>
                        </div>
                    </div>
                    :
                    null
                }
            </>
        )
    }

    function handleProfileUpdate() {

        alert("Updating profile");
        console.log(firstName);
        console.log(lastName);
        console.log(phoneNumber);
        console.log(preferredCommunicationMethod);
        
       // Call donor api endpoint
        axios.patch('https://donation-proxy-api.herokuapp.com/profile-update', {
        // axios.patch('http://localhost:3080/profile-update', {
            id: user.sub,
            first_name: firstName,
            last_name: lastName,
            phone_number: phoneNumber,
            preferred_communication_method: preferredCommunicationMethod
        })
        .then(function (response) {
            // response.set('Access-Control-Allow-Origin', '*');
            alert(response.data+' You will see the changes the next time you log in!.');
            // seteditMode(false);
            window.sessionStorage.setItem('profileUpdateFlag', true);
            var profileCompleteRedirectURL = window.localStorage.getItem('profileCompleteRedirectURL');
            alert(profileCompleteRedirectURL);
            history.push(profileCompleteRedirectURL);
            // history.push('/adopt/R-24');
            // window.location.replace(profileCompleteRedirectURL);
            // if (profileCompleteRedirectURL == 'adopt') {
            //     history.push('/adopt/' + obj.shortId);
            // } else if (profileCompleteRedirect == 'donation') {

            // } else {
            //     window.location.reload();
            // }
        })
        .catch(function (error) {
            alert(error);
        });
    }   
    
    const EditDisplay = () => {
        return(
            <>
                {
                    (isAuthenticated && id==user.sub) ?
                    <div className={classes.profileRoot}>
                        <h1>User Profile</h1>
                        <img src={user.picture} />
                        <div className={classes.profileBody}>
                            <p><strong className={classes.fieldLabel}>Email: </strong>{user.email}</p>
                            <form 
                                // onSubmit={handleProfileUpdate}
                                className={classes.formRoot}
                            >
                                <p>
                                    <strong className={classes.fieldLabel}>First Name: </strong>
                                    <FormControl>
                                        <TextField 
                                            variant="outlined"
                                            onChange={(e) => setfirstName(e.target.value)}
                                            value={firstName}                                        
                                        />
                                    </FormControl>
                                </p>
                                <p>
                                    <strong className={classes.fieldLabel}>Last Name: </strong>
                                    <FormControl>
                                        <TextField 
                                            variant="outlined"
                                            onChange={(e) => setlastName(e.target.value)}
                                        />
                                    </FormControl>
                                </p>
                                <p>
                                    <strong className={classes.fieldLabel}>Phone Number: </strong>
                                    <FormControl>
                                        <TextField 
                                            variant="outlined"
                                            onChange={(e) => setphoneNumber(e.target.value)}
                                        />
                                    </FormControl>
                                </p>
                                <p>
                                    <strong className={classes.fieldLabel}>Preferred Communication Method:</strong>&nbsp;
                                    <FormControl className={classes.FormControl}>
                                        {/* <InputLabel id="demo-simple-select-label">Select Non-Profit</InputLabel> */}
                                        <Select
                                            name="npName"
                                            labelId="demo-simple-select-label"
                                            // id="demo-simple-select"
                                            value={preferredCommunicationMethod}
                                            onChange={(e) => setpreferredCommunicationMethod(e.target.value)}
                                            autoWidth
                                        >
                                            <MenuItem value={'Phone'}>Phone</MenuItem>
                                            <MenuItem value={'Email'}>Email</MenuItem>
                                        </Select>
                                    </FormControl>
                                    {/* <FormControl>
                                        <TextField 
                                            variant="outlined"
                                            onChange={(e) => setpreferredCommunicationMethod(e.target.value)}
                                        />
                                    </FormControl> */}
                                </p>
                            </form>
                        </div>
                    </div>
                    :
                    null
                }
                <Button
                variant="contained"
                color="secondary"
                type="submit"
                onClick={() => handleProfileUpdate()}
                className={classes.editProfileButton}
            >
                Update Profile
            </Button>
            </>
        )
    }
    
    return (
        <div>
           
            {(editMode && user['http://mynamespace/roles'][0] != "admin") ? EditDisplay() : <NonEditDisplay />}
            {
                user['http://mynamespace/roles'][0] != "admin" ?
                    <div className={classes.buttonContainer}>
                        <Button
                            variant="contained"
                            onClick={() => seteditMode(!editMode)}
                            // disabled={user['http://mynamespace/roles'][0] == "admin"}
                            className={classes.editProfileButton}
                        >
                            {editMode ? 'Cancel' : 'Edit Profile'}
                        </Button>
                    </div>
                    :
                    null
            }
        </div>
    )
}

export default Profile
