import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { makeStyles } from "@material-ui/core/styles";
// import { Link } from 'react-router-dom';
import {
  BrowserRouter as Router,
  Link,
  useHistory,
  useParams
} from "react-router-dom";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },      
    },
  },
}));

const useStyles = makeStyles((theme) => ({
    dropdownRoot: {
        color: "inherit",
    },
    '&:hover': {
        background: "black",
     },     
}));

 /** 
  * isPartnerships 
  * if true, DropdownMenu component should display the "Partnerships" dropdown linking to the NectarCares + Trusted World partnerships page
  * if false, DropdownMenu component should display "Organizations" dropdown with a list of supported organizations each linking to the relevant landing page
 **/

export default function DropdownMenu({isPartnerships}) {

  const theme = createMuiTheme({
    typography: {
      fontFamily: [
        'Barlow',
        'sans-serif'
      ].join(','),
    }
  })

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const classes = useStyles();
  const history = useHistory();

  return (
    <div
        // className={classes.dropdownRoot}
    >
      <Button
        id="demo-customized-button"
        aria-controls="demo-customized-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        // variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}    
        style={{
            color: "black",
            // textTransform: "capitalize",
            '&:hover': {
                background: "black",
            },
            fontFamily: [
              'DINRegular',
              'sans-serif'
            ].join(','),
            // fontWeight: '500'
        }}    
      >
       {isPartnerships ? "Partners" : "Donor Portal"}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
      {
        isPartnerships ?
          <>
            <Link to="/partnerships/trustedworld" style={{textDecoration: "none", color: "inherit"}}>
                <MenuItem onClick={handleClose} disableRipple>
                  Trusted World
                </MenuItem>
            </Link>
            <Link to="/organizations/purpose-shelter" style={{textDecoration: "none", color: "inherit"}}>
                <MenuItem 
                  // onClick={handleClose} 
                  onClick={() => {
                      history.replace("/organizations/purpose-shelter");
                      window.location.reload();
                    }
                  }
                  disableRipple
                >
                  Purpose Shelter
                </MenuItem>
            </Link>
            <Divider />
            <Link to="/partnerships/application" style={{textDecoration: "none", color: "inherit"}}>
                <MenuItem onClick={handleClose} disableRipple>
                  Become a Partner
                </MenuItem>            
            </Link>
          </>
          :
          <>
            <Link to="/history" style={{textDecoration: "none", color: "inherit"}}>
                <MenuItem 
                  // onClick={handleClose} 
                  onClick={() => {
                      history.replace("/history");
                      window.location.reload();
                    }
                  }
                  disableRipple
                >
                  My Donations
                </MenuItem>
            </Link>
            <Link to="/adoption-history" style={{textDecoration: "none", color: "inherit"}}>
                <MenuItem 
                  // onClick={handleClose} 
                  onClick={() => {
                      history.replace("/adoption-history");
                      window.location.reload();
                    }
                  }
                  disableRipple
                >
                  My Adoptions
                </MenuItem>
            </Link>           
          </>

      }
      </StyledMenu>
    </div>
  );
}
