import React from 'react';
// import './mcFormStyles.scss';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import CustomForm from './CustomForm';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';
import NectarCaresLogo from '../../img/nectar-cares-logo-blue.png'

const useStyles = makeStyles((theme) => ({
    popupRoot: {
        position: 'fixed',
        zIndex: '10',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        overflow: 'auto',
        backgroundColor: 'rgba(0,0,0,0.4)'
    },
    popupContent: {
        backgroundColor: '#fefefe',
        margin: '15% auto', /* 15% from the top and centered */
        padding: '20px',
        border: '1px solid #888',
        width: '50%',
    },
    close: {
        color: '#aaa',
        float: 'right',
        fontSize: '28px',
        fontWeight: 'bold',
    },      
    // 'close:hover,
    // close:focus': {
    //     color: 'black',
    //     textDecoration: 'none',
    //     cursor: 'pointer',
    // }
    newsletterForm: {
        width: '70%',
        margin: 'auto',
        textAlign: 'center'
    },   
    formRoot: {
        width: '70%',
        margin: 'auto',
        textAlign: 'center',
    },
    '@media only screen and (max-width: 1000px)': {
        formImage: {
          display: 'none'
        }
    },
}));

const MailchimpFormContainer = props => {

    const classes = useStyles();

    const postUrl = process.env.REACT_APP_MAILCHIMP_POST_URL;

    return (
        <div className={classes.popupRoot}>
            <div className={classes.popupContent}>
                <Button className={classes.close}
                    onClick={() => props.setnewsletterPopup(false)}
                >&times;</Button>
                <div className={classes.formRoot}>
                    <img width='400px' src={NectarCaresLogo} className={classes.formImage} />
                    <h2>Subscribe To Our Newsletter</h2>
                    <p>Stay informed about our latest updates by subscribing to our newsletter below!</p>
                </div>
                <MailchimpSubscribe 
                    url={postUrl}
                    render={({subscribe, status, message }) => (
                        <CustomForm
                            status={status}
                            message={message}
                            onValidated={formData => subscribe(formData)}
                        />
                    )}
                    className={classes.newsletterForm}
                />
            </div>
        </div>
    );
};

export default MailchimpFormContainer;