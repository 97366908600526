/*
    Root component for donation summary pdf
*/

import React, { useEffect, useState, useContext } from 'react';
import { Button, Modal, FormControl, InputLabel, Select  } from '@material-ui/core';
import { Link } from "react-router-dom";
import './BasicSummary.css';
import { useAuth0 } from '@auth0/auth0-react';
import AppContext from '../AppContext';
// var React = require('react');
// var QRCode = require('qrcode.react');
import QRCode from 'qrcode.react';
import {
    EmailShareButton,
    FacebookShareButton,
    FacebookIcon,
    FacebookShareCount,
    // LinkedinShareButton,
    // RedditShareButton,
    // TelegramShareButton,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon,
    LinkedinShareButton,
    LinkedinIcon
  } from "react-share";

var render_count =0;

export default function BasicSummary ( { familyID, deliveryInstructions, deliveryAddress, item_list, setpageState, resetItems, npEmail, donationID, imageURL}) {
    // var item_list_global = {};
    const [item_list_global, setitem_list_global] = useState({});
    const { user } = useAuth0();
    const { name, email } = user;
    const myContext = useContext(AppContext);
    // var itemsRejected = "";
    // var notes = "No notes given";
    const [notes, setnotes] = useState("");
    const [itemsRejected, setitemsRejected] = useState([]);


    useEffect(() => {

        // var src = 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v12.0';
        // const ref = window.document.getElementsByTagName("script")[0] 
        // const script = window.document.createElement("script") 
        // script.src = src 
        // script.async = true 
        // ref.parentNode.insertBefore(script, ref) 

        if (window.FB) window.FB.XFBML.parse();

        // alert(`Delivery to: ${deliveryAddress}`);
        // alert(`BasicSummary: Setting item_list_global to ${item_list}`);
        setitem_list_global(item_list);
        console.log("Item_list_global is: ");
        console.log(item_list_global);
        // resetItems ? resetItems() : null;
        if (resetItems) {
            resetItems();
        }
        
        if(myContext.donations && render_count <=1) {
            // get and set items rejected and notes if any
            console.log(`donationID is ${donationID}`);
            // 1. Find the row associated with the ID donationID
            const index = myContext.donations.map(e => e.donationId).indexOf(donationID);
            console.log(`Index is ${index}`);
            // itemsRejected = myContext.donations[index]['itemsRejected'];
            // notes = myContext.donations[index]['notes'];
            setnotes(myContext.donations[index]['notes']);


            var newArray = [];

            console.log(`index is: ${index}`);
            console.log(myContext.donations[index]['itemsRejected']);

            if (myContext.donations[index]['itemsRejected']) {
                myContext.donations[index]['itemsRejected'].split(",").forEach((element, index) => {
                    console.log(element);
                    if(element.charAt(0) == ' ') {
                        console.log(`changing ${element} to `);
                        element = element.substring(1);
                        console.log(element);
                    }
                    if (element != "") {
                        console.log(`item: ${element.split(" ")[1]}`);
                        console.log(`quantity: ${element.split(" ")[0]}`);
    
                        var newObj = {
                            "item": element.split(" ")[1],
                            "quantity": element.split(" ")[0],
                        }
    
                        console.log("inserting new object");
                        console.log(newObj);
    
                        newArray = itemsRejected;
                        newArray.push(newObj);
    
                        // setitemsRejected(
                        //     {
                        //     ...itemsRejected,                            
                        //         "item": element.split(" ")[1],
                        //         "quantity": element.split(" ")[0],                            
                        //     }
                        // );
    
                        console.log(itemsRejected);
                    }
                    // myContext.setdonatedItems({
                    //     ...myContext.donatedItems,
                    //     [name]: event.target.value,
                    // });
                });
            }
            
            console.log(itemsRejected);
            console.log(itemsRejected.length);

            // setitemsRejected(myContext.donations[index]['itemsRejected']);
            console.log(newArray);
            setitemsRejected(newArray);

            console.log(itemsRejected);
            console.log(notes);
            // alert("notes set");
            render_count++;
        }

    })
    
    function handleClose() {
        if (setpageState != null) {
            setpageState(0);
        } else {
            // if setpageState is not passed, this component is being called from App.js on submission of a donation
            // In this case, we want the back button to take the user back to the homepage at
            
            console.log(document.referrer);

            // window.history.back();

            // If the user has made the donation from an organization page, send them back there

            // Else, if the user has made the donation from the main donations page, send them there
            window.location.replace(window.sessionStorage.getItem('makeAnotherDonationURL'));
        }
    }

    // function to convert item name from camelcase to normal word (Eg: pillowCases(standard) -> pillow cases(standard)) 
    function convertCase(item) {
        var gapi_key;

        if(/[A-Z]/.test(item)) {
            // split word at capital letter
            var str_array = item.split(/(?=[A-Z])/);
            gapi_key = str_array.join(' ');
            gapi_key = gapi_key.toLowerCase();
        } else {
            return item;
        }
        
        console.log(`converted ${item} to `);
        return gapi_key;
    }

    if (donationID == "") {
        return(
            <>
                <p>Loading...(BasicSummary)</p>
            </>
        )
    } else {
        return (
            <>
                <div className="summary-root">
                <div className="delivery-and-donor">
                        {/* Packing & Delivery Instructions */}
                        <div className="delivery-instructions">
                            <h3>Packing & Delivery Instructions</h3>
                            <p>Please deliver to the following location:</p>
                            <p>{deliveryAddress ? deliveryAddress : 'Trusted World, \n613b Easy St, Garland, TX 75042'}</p>
                            <span>{deliveryInstructions}</span>
                            <ol>
                                <li> Print two copies of this page.</li>
                                <li> Pack all items carefully in a box (or as many boxes as are required to fit all items for donation).</li>
                                <li> Once fully packed, place one copy inside the box.</li>
                                <li> Seal the box or boxes, and attach one copy of this page to the outside of the box (or boxes)</li>
                            </ol>
                        </div>
    
                        {/* Donor Details */}
                        <div className="donor-details">
                            {/* <strong>Donor email:</strong> {window.sessionStorage.getItem('donorEmail')} */}
                            <strong>Donor email:</strong> {email}
                        </div>
                        {/* <div className="donation-summary-qr-code"> */}
                            <QRCode value={window.location.origin + "/item-history/" + familyID} />
                        {/* </div> */}
                    </div>
    
                    {/* Item List Table */}
                    <div className="items-table">
    
                        <table className="items-table">
                            <tr>
                                <th>Item(s) Donated</th>
                                <th>Quantity</th>
                            </tr>
                        {console.log(item_list_global)}
                            {
                                Object.keys(item_list_global).map((key, i) => (
                                    <tr key={i}>
                                        <td>{convertCase(key)} </td>
                                        <td>{item_list_global[key]} </td>
                                    </tr>
                                ))
                            }
                        </table>
                    </div><br />
    
                    {/*  Center Family ID, Drop off Location */}
                    <div >
                        <span>
                            <strong>Family ID: </strong>
                            {familyID}
                        </span><br /><br />
                        <span>
                            <strong>Donation ID: </strong>
                            {donationID}
                        </span><br /><br />
    
                        <span><strong>Drop-off Location: </strong></span>
                        <span>
                            {/* Trusted World<br />
                            613b Easy St<br />
                            Garland, TX 75202<br />
                            214-555-0123<br /> */}
                            {/* {(deliveryAddress=='' || deliveryAddress.includes('Trusted World')) ? 'Trusted World, 613b Easy St, Garland, TX 75042': deliveryAddress} */}
                            {deliveryAddress}
                        </span><br /><br />
                        <strong>Non-Profit Contact: </strong>{npEmail}
                    </div>
    
                    {/* Dotted Line */}
                    {/* <div className="dotted-line"></div> */}
                </div>
    
                <div className="summary-root">
                    <h2>Notes</h2>
                    {
                        notes ?
                            <>
                                {/* <p>{notes}</p> */}
                                <table className="items-table">
                                        <tr>
                                            <th>Item(s) Rejected</th>
                                            <th>Quantity</th>
                                            <th>Notes</th>
                                            <th>Image</th>
                                        </tr>
                                    {console.log(item_list_global)}
                                        {
                                            Object.keys(itemsRejected).map((key, i) => (
                                                <tr key={i}>
                                                    <td>{convertCase(itemsRejected[key].item)} </td>
                                                    <td>{itemsRejected[key].quantity}</td>
                                                    <td>{notes.split("\n")[i]}</td>
                                                    {/* <td>{imageURL.split("\n")[i]}</td> */}
                                                    <td>
                                                        <a href={imageURL && imageURL.split("\n")[i]} target="_blank">
                                                            <img 
                                                                // width="220" 
                                                                height="150" 
                                                                // border="0" 
                                                                align="center"  
                                                                src={imageURL && imageURL.split("\n")[i]}                                                                     
                                                            />
                                                        </a>
                                                        {/* <img src={imageURL.split("\n")[i]}
                                                            height="150px"
                                                        ></img> */}
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </table>
                            </>
                            :
                            <>
                                <p>(No notes have not been added by the organization yet)</p>
                            </>                        
                    }           
                </div>
                <div className='summary-root no-print'>
                    {/* <h2 style={{display: "flex", alignContent: "center", flexDirection: "column"}}> */}
                    <h2>
                        Share your donation!
                    </h2>
                    <p>
                        NectarCares appreciates your contribution. Share your donation to show support!
                    </p>
                    <FacebookShareButton quote='I just made a donation with NectarCares!' url='http://www.nectarcares.org' style={{marginRight: "10px"}}>
                    {/* <FacebookShareButton quote='I just made a donation with NectarCares!' url='http://www.github.com/' style={{marginRight: "10px"}}> */}
                        <FacebookIcon round="true" size={40} />
                        {/* <FacebookShareCount url="https://nectarcares.org/" /> */}
                    </FacebookShareButton>
                    <TwitterShareButton title='I just made a donation with NectarCares!' url='http://www.nectarcares.org' style={{marginRight: "10px"}}>
                        <TwitterIcon round="true" size={40} />
                    </TwitterShareButton> 
                    <LinkedinShareButton title='I just made a donation with NectarCares!' url='http://www.nectarcares.org' style={{marginRight: "10px"}}>
                        <LinkedinIcon round="true" size={40} />
                    </LinkedinShareButton><br /><br />
                    {/* <div class="fb-page" data-href="https://www.facebook.com/NectarCares-105351892034364/" data-tabs="" data-width="" data-height="" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/NectarCares-105351892034364/" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/NectarCares-105351892034364/">NectarCares</a></blockquote></div> */}
                    {/* <div class="fb-page" data-href="https://www.facebook.com/NectarCares-105351892034364/" data-tabs="" data-hide-cta="true" data-width="" data-height="" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/NectarCares-105351892034364/" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/NectarCares-105351892034364/">NectarCares</a></blockquote></div> */}
                    {/* <a href="https://www.facebook.com/sharer/sharer.php?u=nectarcares.org" target="_blank">
                        Share on Facebook
                    </a> */}
                </div>
    
                <div className="modal-buttons-container no-print">
                    <Button
                        // fullWidth
                        variant="contained"
                        color="primary"
                        // onClick={setpageState==null ? null : () => handleClose()}
                        onClick={() => handleClose()}
                        // href={setpageState==null ? '/donations' : null}
                        className="basicsummary-button"
                    >
                        {/* <Link to="/history"> */}
                        {setpageState==null ? 'Make Another Donation' : 'Back To Donation History'}
                        {/* </Link> */}
                    </Button>
                    {
                        setpageState == null ?
                            <Button
                                variant="contained"
                                className="basicsummary-button"
                                href="/history"
                            >
                                View Donation History
                            </Button>
                            :
                            null
                    }
                    <Button  variant="contained" onClick={window.print} className="greenButton basicsummary-button" > Print page </Button>
                </div>
            </>
        );
    }
    
};

// item_list.map((item, index) => (
//     <tr key={index}>
//         <td>{item}</td>
//         {/* <td>{item.split(' ')[0]}</td> */}
//     </tr>
// ))