import React, { useState, useEffect, useContext } from 'react'
import { Grid, Card, CardContent, Typography, CardActions, Button, FormControl, Select, Checkbox, FormControlLabel, FormGroup, Stepper, Step, StepLabel, CircularProgress, TextField, InputLabel, MenuItem  } from '@material-ui/core';
import {
    BrowserRouter as Router,
    Link,
    useHistory
} from "react-router-dom";
import { handleItemRequest } from '../../handleItemRequest.js';
import RecipientForm from './RecipientForm.jsx';
import { makeStyles } from '@material-ui/core/styles';
// import RecipientHeroImg from '../../img/DallasSkyline3.png';
import RecipientHeroImg from '../../img/banner-image.png';
import { width } from '@material-ui/system';
import AppContext from '../AppContext.js';
import { useAuth0 } from '@auth0/auth0-react';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    button: {
    //   marginRight: theme.spacing(1),
        margin: theme.spacing(2),
    },
    requestSummary: {
    //   marginTop: theme.spacing(1),
    //   marginBottom: theme.spacing(1),
        margin: theme.spacing(2),
    },
    instructions: {
        marginBottom: 10,
    },
    stepperButtons: {
        margin: 12,
        textAlign: 'center',
    },
    formRoot: {
        display: 'flex',
        padding: '10px',
        width: '60vw',
        flexWrap: 'wrap',
        margin: 'auto',
        justifyContent: 'space-evenly'
    },
    FormControl: {
        width: '11rem',
        margin: 12
    },
    stepTitle: {
        margin: '10px 20px 10px 26px',
    },
    heroImage: {
        // maxWidth: '100vw',
        // height: '200px',
        width: '100vw'
    },
    greenButton: {
        backgroundColor: 'rgba(52, 211, 153, 1)',
    }
}));

function getSteps() {
    return ['Enter Non-Profit Details', 'Enter Recipient Details', 'Enter Alternate Contact', 'Select Items'];
}

let itemsRequestedLocal = "";

const RecipientRequest = () => {

    const myContext = useContext(AppContext);

    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const steps = getSteps();
    const [requestSubmitted, setrequestSubmitted] = useState(false);        // to check if request has been submitted

    const { user } = useAuth0();
    const [nonProfit, setnonProfit] = useState(user['https://nectarcares.org/organization']);            // name of the non-profit
    const [npEmail, setnpEmail] = useState('');
    
    const [recipientFirstName, setrecipientFirstName] = useState('');                 // First name of the recipient
    const [recipientLastName, setrecipientLastName] = useState('');                 // Last name of the recipient
    const [recipientEmail, setrecipientEmail] = useState('');
    const [recipientPhone, setrecipientPhone] = useState();
    const [recipientAddress1, setrecipientAddress1] = useState('');
    const [recipientAddress2, setrecipientAddress2] = useState('');
    const [recipientCity, setrecipientCity] = useState('');
    const [recipientState, setrecipientState] = useState('');
    const [recipientZip, setrecipientZip] = useState('');
    const [recipientBio, setrecipientBio] = useState('');
    const [phoneType, setphoneType] = useState('Mobile');
    const [preferredCommunication, setpreferredCommunication] = useState('');
    
    const [alternateContactFirstName, setalternateContactFirstName] = useState('');                 // First name of the alternate contact
    const [alternateContactLastName, setalternateContactLastName] = useState('');                 // Last name of the alternate contact
    const [alternateContactEmail, setalternateContactEmail] = useState('');
    const [alternateContactPhone, setalternateContactPhone] = useState('');
    const [alternateContactAddress1, setalternateContactAddress1] = useState('');
    const [alternateContactAddress2, setalternateContactAddress2] = useState('');
    const [alternateContactCity, setalternateContactCity] = useState('');
    const [alternateContactState, setalternateContactState] = useState('');
    const [alternateContactZip, setalternateContactZip] = useState('');
    // const [alternateContactBio, setalternateContactBio] = useState('');
    const [alternateContactPhoneType, setalternateContactPhoneType] = useState('Mobile');
    const [alternateContactpreferredCommunication, setalternateContactpreferredCommunication] = useState('');

    const [alternateContactCheckbox, setalternateContactCheckbox] = useState(false);    

    var itemsRequested = myContext.itemsRequested;


    useEffect(() => {
        console.warn('Re rendering root component RecipientRequest');
        console.log(itemsRequested);
    })
    
    const isStepOptional = (step) => {
        return step === 2;
    };
    
    const isStepSkipped = (step) => {
        return skipped.has(step);
    };        
    
    
    async function handleNext(e) {

        console.log(e);
        
        // e.preventDefault();

        console.log(`activeStep: ${activeStep}`);

        // validate form before proceeding 
        // var isValid = validate()
        // console.log(`isValid: ${isValid}`);

        if (true) {
            let newSkipped = skipped;
            if (isStepSkipped(activeStep)) {
                newSkipped = new Set(newSkipped.values());
                newSkipped.delete(activeStep);
            }
    
            if (activeStep == 1 && alternateContactCheckbox == false) {
                setActiveStep((prevActiveStep) => prevActiveStep + 2);
            } else {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
            
            setSkipped(newSkipped);
    
            // If final step, make item request to backend and populate necessary variables to display in the request summary
            if (activeStep === steps.length - 1) {
                await handleRequest();
                // setrequestSubmitted(true);
            }
        }
    };
    
    // function to convert item name from camelcase to normal word (Eg: pillowCases(standard) -> pillow cases(standard)) 
    function convertCase(item) {
        var gapi_key;

        if(/[A-Z]/.test(item)) {
            // split word at capital letter
            var str_array = item.split(/(?=[A-Z])/);
            gapi_key = str_array.join(' ');
            gapi_key = gapi_key.toLowerCase();
        } else {
            return item;
        }
        
        console.log(`converted ${item} to `);
        return gapi_key;
    }
    async function handleRequest() {
        itemsRequestedLocal = "";
        // if finished, iterate through itemsRequested object and push into printable array/list
        Object.keys(itemsRequested).forEach(function(key) {
            console.log(itemsRequested);
            itemsRequestedLocal += itemsRequested[key] + " " + convertCase(key) + ", ";
        })

        // Remove last comma
        itemsRequestedLocal = itemsRequestedLocal.slice(0,-1);
        console.log(`This is the final step. itemsRequestedLocal:\n ${itemsRequestedLocal.slice(0,-1)}`);

        console.log(preferredCommunication);
        console.log(alternateContactpreferredCommunication);

        // Send data to handleDonation to send PATCH request to google sheets api to add a row to the requests google sheet
        await handleItemRequest(
            nonProfit, 
            npEmail, 
            recipientFirstName, 
            recipientLastName, 
            recipientEmail,
            recipientPhone,
            phoneType,
            recipientAddress1,
            recipientAddress2,
            recipientCity,
            recipientState,
            recipientZip,
            recipientBio,
            preferredCommunication,
           
            alternateContactFirstName, 
            alternateContactLastName, 
            alternateContactEmail,
            alternateContactPhone,
            alternateContactPhoneType,
            alternateContactAddress1,
            alternateContactAddress2,
            alternateContactCity,
            alternateContactState,
            alternateContactZip,
            alternateContactpreferredCommunication,

            itemsRequested
        );
    }
    
      const handleBack = () => {
        if (activeStep == 3 && alternateContactCheckbox == false) {
            setActiveStep((prevActiveStep) => prevActiveStep - 2);
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
      };
    
      const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
          // You probably want to guard against something like this,
          // it should never occur unless someone's actively trying to break something.
          throw new Error("You can't skip a step that isn't optional.");
        }
    
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
          const newSkipped = new Set(prevSkipped.values());
          newSkipped.add(activeStep);
          return newSkipped;
        });
      };
    
      // Reset all the fields
      const handleReset = () => {
        itemsRequestedLocal = "";
        setnpEmail("");
        setnonProfit("");
        setrecipientFirstName("");
        setrecipientLastName("");
        setrecipientEmail("");
        setrecipientPhone("");
        setrecipientAddress1("");
        setrecipientAddress2("");
        setrecipientCity("");
        setrecipientState("");
        setrecipientZip("");
        setrecipientBio("");
        setpreferredCommunication("");
        
        setalternateContactFirstName("");
        setalternateContactLastName("");
        setalternateContactEmail("");
        setalternateContactPhone("");
        setalternateContactPhoneType("")
        setalternateContactAddress1("");
        setalternateContactAddress2("");
        setalternateContactCity("");
        setalternateContactState("");
        setalternateContactZip("");
        setalternateContactpreferredCommunication("")
        
        setActiveStep(0);
        window.location.reload();
      };
    
      return (
        <div className={classes.root}>
            {/* <img src={RecipientHeroImg} className={classes.heroImage} /> */}
            <img src={RecipientHeroImg} className={classes.heroImage} />
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                if (isStepOptional(index)) {
                    labelProps.optional = <Typography variant="caption">Optional</Typography>;
                }
                if (isStepSkipped(index)) {
                    stepProps.completed = false;
                }
                return (
                    <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                );
                })}
            </Stepper>
            <div>
                {activeStep === steps.length ? (
                    (itemsRequestedLocal != '') ?
                        <div className={classes.requestSummary}>
                            <Typography variant="h4" className={classes.instructions}>
                            {/* All steps completed - you&apos;re finished */}
                                Item Request Completed!
                            </Typography>
                            <Typography>
                                <strong>Items Requested:</strong> {itemsRequestedLocal.slice(0,-1)}
                            </Typography>                
                            <Button onClick={handleReset} className={classes.button} variant="outlined">
                                Place another request
                            </Button>
                        </div>
                        :
                        <CircularProgress />
                ) : (
                <form className={`${classes.formRoot} ${classes.step1Root}`}>
                    {/* <RecipientForm step={activeStep} requestedItems={requestedItems} setrequestedItems={setrequestedItems} /> */}
                    <RecipientForm step={activeStep} itemsRequested={itemsRequested} 
                        nonProfit={nonProfit} setnonProfit={setnonProfit}
                        setnpEmail={setnpEmail} npEmail={npEmail}

                        setrecipientFirstName={setrecipientFirstName}
                        setrecipientLastName={setrecipientLastName}
                        setrecipientEmail={setrecipientEmail}
                        setrecipientPhone={setrecipientPhone}
                        setrecipientAddress1={setrecipientAddress1}
                        setrecipientAddress2={setrecipientAddress2}
                        setrecipientCity={setrecipientCity}
                        setrecipientState={setrecipientState}
                        setrecipientZip={setrecipientZip}
                        setrecipientBio={setrecipientBio}
                        recipientPhone={recipientPhone}
                        setphoneType={setphoneType}
                        setpreferredCommunication={setpreferredCommunication}

                        setalternateContactCheckbox={setalternateContactCheckbox}
                        alternateContactCheckbox={alternateContactCheckbox}

                        setalternateContactFirstName={setalternateContactFirstName}
                        setalternateContactLastName={setalternateContactLastName}
                        setalternateContactEmail={setalternateContactEmail}
                        setalternateContactPhone={setalternateContactPhone}
                        setalternateContactPhoneType={setalternateContactPhoneType}
                        setalternateContactAddress1={setalternateContactAddress1}
                        setalternateContactAddress2={setalternateContactAddress2}
                        setalternateContactCity={setalternateContactCity}
                        setalternateContactState={setalternateContactState}
                        setalternateContactZip={setalternateContactZip}
                        setalternateContactpreferredCommunication={setalternateContactpreferredCommunication}
                        
                        alternateContactFirstName={alternateContactFirstName}
                        alternateContactLastName={alternateContactLastName}
                        alternateContactEmail={alternateContactEmail}
                        alternateContactPhone={alternateContactPhone}
                        alternateContactPhoneType={alternateContactPhoneType}
                        alternateContactAddress1={alternateContactAddress1}
                        alternateContactAddress2={alternateContactAddress2}
                        alternateContactCity={alternateContactCity}
                        alternateContactState={alternateContactState}
                        alternateContactZip={alternateContactZip}

                        handleNext={handleNext}
                        recipientFirstName={recipientFirstName}
                        recipientLastName={recipientLastName}
                        recipientEmail={recipientEmail}
                        recipientBio={recipientBio}
                        // npEmail={npEmail}

                        handleBack={handleBack}
                    />
                    
                    <div className={classes.stepperButtons}>
                        {/* <Button variant="contained" disabled={activeStep === 0} onClick={handleBack} className={classes.button} color="primary">
                            Back
                        </Button>                        

                        <Button
                            variant="contained"
                            // color="primary"
                            onClick={handleNext}
                            // type="submit"
                            className={`${classes.button} ${classes.greenButton}`}
                        >
                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                        </Button> */}
                    </div>
                </form>
                )}
            </div>
        </div>
  );
}

export default RecipientRequest
