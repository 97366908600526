import React from 'react';
import { useHistory } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { useAuth0 } from '@auth0/auth0-react';

const Auth0ProviderWithHistory = ({ children }) => {

    const domain = process.env.REACT_APP_AUTH0_DOMAIN;
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

    const { isLoading, user, isAuthenticated } = useAuth0();

    const history = useHistory();

    const onRedirectCallback = (appState) => {
        // alert(`${appState?.returnTo || window.location.pathname}`);
        // history.push("/donations");
        console.log("printing app state");
        console.log(appState);
        console.log(user);

        history.push(
            appState?.returnTo || window.location.pathname
        );
    }

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            redirectUri={window.location.origin}
            onRedirectCallback={onRedirectCallback}
            useRefreshTokens
            cacheLocation="localstorage"  // needed to support page refresh w/ blocked 3rd party cookies
        >
            {children}
        </Auth0Provider>
    )
}

export default Auth0ProviderWithHistory
