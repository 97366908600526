import React, { useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import AppContext from '../AppContext';

const useStyles = makeStyles((theme) => ({
    items: {
        margin: 10,
        marginLeft: 20,
    }
}))

const CartSummary = ({ itemsRequested, summaryHeading }) => {
    
    const classes = useStyles();
    const myContext = useContext(AppContext);
    
    useEffect(() => {
        console.warn(itemsRequested);
    })

    // function to convert item name from camelcase to normal word (Eg: pillowCases(standard) -> pillow cases(standard)) 
    function convertCase(item) {
        var gapi_key;

        if(/[A-Z]/.test(item)) {
            // split word at capital letter
            var str_array = item.split(/(?=[A-Z])/);
            gapi_key = str_array.join(' ');
            gapi_key = gapi_key.toLowerCase();
        } else {
            return item;
        }
        
        return gapi_key;
    }

    const getItems = () => {
        Object.keys(itemsRequested).map(function(key){
            // return(<p>{key}</p>)
            console.log(itemsRequested[key] + " " + key);
        })

    }
    
    return (
        <div>
            <h1>{summaryHeading}</h1>
            {/* <p>Items Requested:</p> */}
            {/* <span>{(JSON.stringify(itemsRequested).split(','))}</span> */}
            {
                itemsRequested ? 
                    Object.keys(itemsRequested).map((key, i) => (
                        <div key={i} className={classes.items} >
                            { itemsRequested[key] + " " + convertCase(key) }
                        </div>
                    ))
                :
                    null
            }
           
        </div>
    )
}

export default CartSummary
