import React, { useEffect } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { Kitchen } from '@material-ui/icons';
import ItemCard from './ItemCard';
import { items } from '../../items';

// This component returns the relevant gallery of items based on the 'category' prop passed    

const useStyles = makeStyles((theme) => ({
   itemGallery: {
       display: 'flex',
       flexWrap: 'wrap',
       justifyContent: 'center',
   },
   root: {
       margin: 20,
   }
}));
const ItemGallery = ({category}) => {
    const classes = useStyles();

    useEffect(() => {
        console.log(items);
    })

    const kitchen = () => {
        return (
            <div className={classes.itemGallery}>
                {/* Loop through items and call ItemCard component on each item */}
                {
                    items.map((item, index) => (
                        (index <= 30) ? 
                            <div key={index}>
                                <ItemCard item={item}/>
                            </div>
                            :
                            null
                    ))
                }
            </div>
        )
    }
    const bedroom = () => {
        return (
            <>
                <h1>Bedroom Items</h1>
            </>
        )
    }
    const bathroom = () => {
        return (
            <>
                <h1>Bathroom Items</h1>
            </>
        )
    }
    
    const getGallery = () => {
        if (category == "Kitchen") return kitchen();
        else if (category == "Bedroom") return bedroom();
        if (category == "Bathroom") return bathroom();
    }

    return (
        <div className={classes.root}>
            <Typography variant="h6">{category} items</Typography>
            {getGallery()}
        </div>
    )
}

export default ItemGallery
