import axios from 'axios';

// Function to get current UTC time and return a string timestamp in the following format
// m/d/yyy h:m:s
function getTimeStamp() {
    var dateObject = new Date();
    var m = dateObject.getUTCMonth()+1;
    var d = dateObject.getUTCDate();
    var yyyy = dateObject.getUTCFullYear();
    
    var hours = dateObject.getUTCHours();
    var minutes = dateObject.getUTCMinutes();
    var seconds = dateObject.getUTCSeconds();

    var timestamp = "" + m + "/" + d + "/" + yyyy + " " + hours + ":" + minutes + ":" + seconds;

    return timestamp;
}

export async function handleItemRequest(
    npName, 
    npEmail, 
    recipientFirstName, 
    recipientLastName, 
    recipientEmail,
    recipientPhone,
    phoneType,
    recipientAddress1,
    recipientAddress2,
    recipientCity,
    recipientState,
    recipientZip,
    recipientBio,
    preferredCommunication,

    alternateContactFirstName, 
    alternateContactLastName, 
    alternateContactEmail,
    alternateContactPhone,
    alternateContactphoneType,
    alternateContactAddress1,
    alternateContactAddress2,
    alternateContactCity,
    alternateContactState,
    alternateContactZip,
    alternateContactpreferredCommunication,

    itemsRequested) {

    console.log('In handleItemRequest...');
    console.log(npEmail);

    console.log(preferredCommunication);
    console.log(alternateContactpreferredCommunication);
    
    // send patch request to api
    axios.patch('https://donation-proxy-api.herokuapp.com/item-request', {
    // axios.patch('http://localhost:3080/item-request', {
        timestamp: getTimeStamp(),
        npName: npName,
        npEmail: npEmail,
        recipientName: recipientFirstName + ' ' + recipientLastName,
        recipientEmail: recipientEmail,
        recipientPhone: recipientPhone,
        recipientPhoneType: phoneType,
        recipientAddress1: recipientAddress1,
        recipientAddress2: recipientAddress2,
        recipientCity: recipientCity,
        recipientState: recipientState,
        recipientZip: recipientZip,
        recipientBio: recipientBio,
        preferredCommunication: preferredCommunication,

        alternateContactName: alternateContactFirstName + ' ' + alternateContactLastName, 
        alternateContactEmail: alternateContactEmail,
        alternateContactPhoneNumber: alternateContactPhone,
        alternateContactType: alternateContactphoneType,
        alternateContactAddressLine1: alternateContactAddress1,
        alternateContactAddressLine2: alternateContactAddress2,
        alternateContactCity: alternateContactCity,
        alternateContactState: alternateContactState,
        alternateContactZipCode: alternateContactZip,
        alternateContactpreferredCommunication: alternateContactpreferredCommunication,

        itemsRequested: itemsRequested,
    })
    .then(function (response) {
        alert(response.data);
        // window.location.reload();
    })
    .catch(function (error) {
        alert(error);
    });
}
