import React, { useEffect, useRef, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
    BrowserRouter as Router,
    Link,
    useHistory,
    useParams
  } from "react-router-dom";
import { Grid, Card, CardContent, Typography, CardActions, Button, Box, Tabs, Tab } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import axios from 'axios';
import { toast } from 'react-toastify';
import Loading from '../loading';
import AppContext from '../AppContext';

var api_call_limiter = 0;
// var familyAdoptedByOtherDonor = false;

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '0px 4vw 0px 4vw',

    }
}));

var isLoading = false;
const Adopt = () => {
    const myContext = useContext(AppContext);
    let { id } = useParams();
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const { user, isAuthenticated } = useAuth0();
    const [showThankYouPage, setShowThankYouPage] = React.useState();
    // const [familyAdoptedByOtherDonor, setFamilyAdoptedByOtherDonor] = React.useState();
    
    // const [donorHasAdopted, setDonorHasAdopted] = React.useState(false);
    // const [loadingState, setLoadingState] = React.useState(true);
    // var donorHasAdopted = false;
    // const adoptionStateRef = useRef();
    const [renderVariable, setRenderVariable] = React.useState(0);

    // adoptionStateRef.current = donorHasAdopted;
    
    // Get the request object using the id
    useEffect(() => {
        // alert(`${myContext.donorHasAdopted}`);
        // setDonorHasAdopted(false);        
        // if (api_call_limiter <= 1) { 
            // Read from the adoption sheet to check if the current donor has already adopted family request with id 'id'
            let url = 'https://api.sheety.co/591c67f7e588d2a49e1cf55d260ce8a5/recipient/adoptAFamily';
            fetch(url)
            .then((response) => response.json())
            .then(json => {
                // Do something with the data
                console.log(json.adoptAFamily);
                api_call_limiter++;
                var adoptionFlag = false;

                // alert('iterating through adoption sheet...');
                // iterate through json.adoptAFamily and search for 'Request ID' matching id
                for (var i=0; i<json.adoptAFamily.length; i++) {
                    if (json.adoptAFamily[i]['requestId'] == id) {
                        // alert('request id matches');
                        // check if the donor matches
                        if (user.email == json.adoptAFamily[i]['donorEmail'] && json.adoptAFamily[i]['status'] == 'Active' ) {
                            adoptionFlag = true;
                            myContext.setOtherDonorHasAdopted(false);
                            // donorHasAdopted = true;
                        } else if (json.adoptAFamily[i]['status'] == 'Active') {
                            // flag if family already has an active adopter
                            // familyAdoptedByOtherDonor = true;
                            myContext.setOtherDonorHasAdopted(true);
                            adoptionFlag = false;
                            break;
                        }
                    }
                }
                myContext.setDonorHasAdopted(adoptionFlag);
                // setLoadingState(false);
            });
        // }

    })

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // Add the current donor to the adoption list of the current family/request
    function handleAdoptAFamily(status) {        
        // send an axios POST request to the /adopt end API point
        // - Donor Name, Donor Email, Donor Phone, Request ID

        if (status == 'opt-in') {
            var toast_id = toast.loading('Adopting family...');
        } else if (status == 'opt-out') {
            var toast_id = toast.loading('Opting out of adoption...');
        }

        // send patch request to api
    axios.post('https://donation-proxy-api.herokuapp.com/adopt', {
        // axios.post('http://localhost:3080/adopt', {
            donorName: user['https://example.com/first_name'] || 'Donor',
            donorEmail: user.email,
            donorPhone: '',
            requestID: id,
            status: status,
        })
        .then(function (response) {
            // alert(response.data);
            if (status == 'opt-in') {
                toast.update(toast_id, { 
                    // render: "Adoption Successful!",
                    render: response.data,
                    type: "success", 
                    isLoading: false, 
                    autoClose: 5000, 
                    closeOnClick: true,
                    hideProgressBar: false,
                });
            } else if (status == 'opt-out') {
                toast.update(toast_id, { 
                    // render: "You have successfully opted out of this adoption!",
                    render: response.data,
                    type: "success", 
                    isLoading: false, 
                    autoClose: 5000, 
                    closeOnClick: true,
                    hideProgressBar: false,
                });
            }
            window.setTimeout(function() {
                if (status == 'opt-in') {
                    setShowThankYouPage(true);
                } else {
                    // window.location.reload();
                    window.location.replace("/donations");
                }

            }, 5500)
        })
        .catch(function (error) {
            toast.update(toast_id, { render: "Donation Failed!", type: "error", isLoading: false });
            alert(error);
        });

    }     

    const ThankYouComponent = () => {
        return (
            // <div>
            <div className={classes.root}>
                <h1>
                    Thank you for adopting a family!
                </h1>
                {/* <h2>Family Details</h2>
                <ul>
                    <li>
                       <strong>Family Bio</strong>:
                    </li>
                    <li>
                       <strong>Non-Profit Name</strong>: 
                    </li>
                    <li>
                       <strong>Non-Profit Contact</strong>:
                    </li>
                </ul>             */}
                <Button variant='contained' onClick={() => window.history.back() } >
                    Back
                </Button> 
            </div>
        )
    }

    if (myContext.otherDonorHasAdopted == true) {
        // alert('family adopted by other donor.');
        return (
            <div className={classes.root}>
                <h1>
                    Adopt a Family
                </h1>
                <h2>This family has already been adopted by another donor!</h2>
                <Button variant='contained' onClick={() => window.history.back() } >
                    Back
                </Button>                                  
            </div>
        )
    }

    if (showThankYouPage == true) {
        return (
            <ThankYouComponent />
        )
    } else {
        if (isLoading == true) {
            return (
                <Loading />
            )
        } else if (myContext.donorHasAdopted == false && isLoading == false) {
            // alert('Family not adopted');
            return (
            <div className={classes.root}>
                <h1>
                    Adopt a Family
                </h1>
                <p>Support a family with necessary items for a fixed period of time.</p>
                <h2>What does it mean to 'adopt' a family?</h2>
                <ul>
                    <li>
                        By 'adopting' a family, you will added to an exclusive list of donors who have opted to 
                        support this specific family
                    </li>
                    <li>
                        This means you will be notified by email when the family is in need of new items
                    </li>
                    <li>
                        This way, you will be directed straight to the family's donation page where you can make your donations
                    </li>
                </ul>
        
                <p><strong>Note:</strong> You can opt out of this at any time by coming back to this adoption page.</p>
                    
                <Button variant='contained' onClick={() => window.history.back() } >
                    Back
                </Button>           
                <Button variant='contained' color='primary' style={{
                    marginLeft: '20px',
                    }} onClick={() => handleAdoptAFamily('opt-in')} >Adopt Family</Button>                    
            </div>
          )
        } else if(myContext.donorHasAdopted == true) {
            // alert('user has adopted family.');
            return (
            <div className={classes.root}>
                <h1>
                    Adopt a Family
                </h1>
                {/* <p>Support a family with necessary items for a fixed period of time.</p> */}
                <h2>You have already adopted this family!</h2>

                <p><strong>Note:</strong> You can opt out of this at any time by coming back to this adoption page.</p>

                <Button variant='contained' onClick={() => window.history.back() } >
                    Back
                </Button>
                <Button variant='contained' color='primary' style={{
                    marginLeft: '20px',
                }} onClick={() => handleAdoptAFamily('opt-out')} >Opt Out of Adoption</Button>                  
            </div>
            )
        }
    }

}

export default Adopt