import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../Components/loading";

// const printMessage = () => (
//   console.log(`In ProtectedRoute: \ncomponent is ${component}\nargs:\n${args}`);
// );

function PrintMessage ( component, ...args ) {
  console.log(`In ProtectedRoute: \ncomponent is ${component}\nargs:\n${args}`);
  console.log(component);
  console.log(...args);
  console.log(JSON.stringify(...args))
};

const ProtectedRoute = ({ component, ...args }) => (
  
  // useEffect(() => {
  //   console.log(`In ProtectedRoute: \ncomponent is ${component}\nargs:\n${args}`);
  // })

  <Route
    component={withAuthenticationRequired(component, {
      onRedirecting: () => <Loading />,
    })}
    {...args}
  />
);

export default ProtectedRoute;