// Page to display the donor history of the currently logged in user based on 
import React, { useState, useEffect, useContext } from 'react'
import { 
    Button,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableContainer,
    Paper,
    TableHead,
    TableSortLabel,
    Typography, FormGroup, FormControlLabel, Checkbox, Grid, IconButton, Tooltip
} from '@material-ui/core';
import './history.css';
import { BasicSummary, NameFilter } from '../index';
import { useAuth0 } from '@auth0/auth0-react';
import AppContext from '../AppContext';
import { Link, useHistory } from 'react-router-dom';
import Loading from '../loading';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import { toast, ToastContainer } from 'react-toastify';

var api_call_limiter = 0;

var item_list={};
var recipientId='';
var status = ''
var deliveryInst_global = '';
var deliveryAddress_global = '';
var npEmail_global = '';

export default function RequestHistory({req}) {

    const myContext = useContext(AppContext);
    const history = useHistory();

    // const [donations, setdonations] = useState([]);   // state that stores all donations made by currently logged in user
    const [id, setid] = useState([]);
    const [recipientID, setrecipientID] = useState([]);
    const [emailArray, setemailArray] = useState([]);
    const [itemsDonated, setitemsDonated] = useState([]);
    const [nameArray, setnameArray] = useState([]);
    const [completed, setcompleted] = useState([]);
    const [render, setrender] = useState(0);
    const [deliveryInsructions, setdeliveryInsructions] = useState([]);
    const [deliveryAddress, setdeliveryAddress] = useState([]);
    const [npEmailarray, setnpEmailarray] = useState([]);
    
    const [pageState, setpageState] = useState(0);

    const [nameFilter, setnameFilter] = useState();
    const [dateOrder, setdateOrder] = useState('desc');
    const [filter, setFilter] = useState({
        Pending: true,
        Incomplete: true,
        Complete: true,
    });

    const { user } = useAuth0();
    const { name, email } = user;
    const [requestUpdateStatus, setRequestUpdateStatus] = useState('');

    // const [item_list, setitem_list] = useState({});

    useEffect(() => {
        // Check if requests object has been loaded. If not, make API call and populate it       
        if (api_call_limiter <= 1) {
            if (myContext.requests[0].bio == "Loading...") {
                console.log("Requests not loaded yet... Loading requests...");
                let url = process.env.REACT_APP_SHEETY_API_GET;
                fetch(url)
                .then((response) => response.json())
                .then(json => {
                // Do something with the data
                    // Update the prop with the requests
                    if (myContext.setrequests) {
                        console.log("Going to set requests in RequestHistory useEffect");
                        console.log(myContext);
                        myContext.setrequests(json.requests);
                    }
                });
                api_call_limiter++;
            }         
        }

        // If donations data has not been queried yet, query it and store it in the context
        console.log("Printing myContext.donations");
        console.log(myContext.donations);
        if (!myContext.donations) {
            console.log("Setting donations in RequestHistory.jsx");
            let url = process.env.REACT_APP_SHEETY_GET_DONATIONS;
            fetch(url)
            .then((response) => response.json())
            .then(json => {
            // Do something with the data
                console.log("Setting donations in RequestHistory.jsx(2)");
                myContext.setdonations(json.donors);
            });          
        }
    })

    // useEffect(() => {
    //     // check request update status message and display toast if needed
    //     // window.localStorage.setItem('request-update-status-message', `Updated Request ${id}!`);
        
    //     alert(`Message is: ${requestUpdateStatus}`);
    //     if (requestUpdateStatus) {
    //         toast.success(requestUpdateStatus);
    //         // window.localStorage.setItem('request-update-status-message', '');
    //     }

    // }, [requestUpdateStatus])

    function handleClick(item, rid, currentStatus, deliveryInst, deliveryAddr, contact) {
        console.log("Handling click in History.jsx");

        recipientId = rid;
        status = currentStatus;

        // Put item string into item_list object to pass to BasicSummary component
        var item_split = item.split(',');
        for(var i=0; i<item_split.length; i++) {
            if (i==0) {
                item_list = {
                    ...item_list,
                    [item_split[i].split(' ')[1]]: item_split[i].split(' ')[0],
                }
            } else {
                item_list = {
                    ...item_list,
                    [item_split[i].split(' ')[2]]: item_split[i].split(' ')[1],
                }
            }
        }


        // if (deliveryInst == '' || deliveryInst == null) {
        //     deliveryInst_global = 'Deliver to Trusted World, \n613b Easy St, Garland, TX 75042';
        // } else {
            deliveryInst_global = deliveryInst;
        // }

        // alert(`History.js: delivery to ${deliveryAddr}`);
        if (deliveryAddr == '' || deliveryAddr == null) {
            deliveryAddress_global = 'Trusted World, 613b Easy St, Garland, TX 75042';
        } else {
            deliveryAddress_global = deliveryAddr;
        }

        // set np email for the currently clicked donation
        npEmail_global = contact;

        // alert(`Handling click, delivery addr: ${deliveryAddr}`);
        // alert(`Handling click, delivery instructions: ${deliveryInst}`);
        console.log(`handleClick: converting ${item} \n to`);
        console.log(item_list);

        setpageState(1);
        setrender(5);
    }

    // function to convert item name from camelcase to normal word (Eg: pillowCases(standard) -> pillow cases(standard)) 
    function convertCase(item) {
        var gapi_key;

        if(/[A-Z]/.test(item)) {
            // split word at capital letter
            var str_array = item.split(/(?=[A-Z])/);
            gapi_key = str_array.join(' ');
            gapi_key = gapi_key.toLowerCase();
        } else {
            return item;
        }
        
        console.log(`converted ${item} to `);
        return gapi_key;
    }

    // Sends an API request to the email-blast endpoint that sends out an email blast to the current orgs' mailchimp mailing list
    function sendEmailBlast(npName) {
        alert("Sending Email Blast...");
        // send a POST request to the /email-blast endpoint
        axios.post('https://donation-proxy-api.herokuapp.com/email-blast', {
            // axios.post('http://localhost:3080/email-blast', {
                npName: npName,
                scheduler: 'no-schedule',
            })
            .then(function (response) {
                // window.location.reload();
                alert(response.data);
                return response;
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const requestHistoryTable = (
        <div>
            <div className="summary-header">
                <h2>Item Request History ({user['https://nectarcares.org/organization']})</h2>
            </div>

            <Grid container className="request-history-filters">
                <Grid item xs={12} sm={4}>
                    <NameFilter setnameFilter={setnameFilter}/>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Typography className="bold-text">Filter by Status: </Typography>
                    <FormGroup row>
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={filter.Pending}
                                // onChange={(e) => setFilter({ ...filter, [e.target.name]: e.target.checked })}
                                onChange={(e) => {
                                        console.log(`${e.target.name} is ${e.target.checked}`)
                                        setFilter({ ...filter, [e.target.name]: e.target.checked})
                                    }
                                }
                                name="Pending"
                                color="primary"
                            />
                            }
                            label="Pending"
                        />
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={filter.Incomplete}
                                // onChange={(e) => setFilter({ ...filter, [e.target.name]: e.target.checked })}
                                onChange={(e) => {
                                        console.log(`${e.target.name} is ${e.target.checked}`)
                                        setFilter({ ...filter, [e.target.name]: e.target.checked})
                                        console.log(filter["Complete"])
                                    }
                                }
                                name="Incomplete"
                                color="primary"
                            />
                            }
                            label="Incomplete"
                        />
                        <FormControlLabel
                            control={
                            <Checkbox
                                checked={filter.Complete}
                                // onChange={(e) => setFilter({ ...filter, [e.target.name]: e.target.checked })}
                                onChange={(e) => {
                                        console.log(`${e.target.name} is ${e.target.checked}`)
                                        setFilter({ ...filter, [e.target.name]: e.target.checked})
                                    }
                                }
                                name="Complete"
                                color="primary"
                            />
                            }
                            label="Complete"
                        />
                    </FormGroup>
                </Grid>
                {/* This button sends out an email blast to the mailchimp mailing list of the current organization informing them of new requests that need support */}
                <Grid container xs={12} sm={3} >
                    <Typography className="bold-text">Announce new requests to your donors:</Typography>                    
                    <Button variant='outlined' color='primary' onClick={() => sendEmailBlast((user['https://nectarcares.org/organization']))}
                    style={{marginTop: 'auto'}} 
                    >Send Email Blast</Button>
                </Grid>
            </Grid>

            <TableContainer component={Paper} className="table">
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {/* <TableCell><strong>Update Request</strong></TableCell> */}
                            <TableCell><strong>Family Name</strong></TableCell>
                            <TableCell><strong>Request ID</strong></TableCell>
                            <TableCell>
                                <TableSortLabel
                                    // active={orderBy === headCell.id}
                                    active={true}
                                    // direction={orderBy === headCell.id ? order : 'asc'}
                                    direction={dateOrder}
                                    onClick={() => (
                                        dateOrder == 'asc' ? setdateOrder('desc') : setdateOrder('asc')
                                    )}                                    
                                >
                                    <strong>Request Date</strong>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell><strong>Request Status</strong></TableCell>
                            <TableCell align="center"><strong>Actions</strong></TableCell>
                            {/* <TableCell align="right"><strong>Request Summary</strong></TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                            {
                                dateOrder == 'desc' ? 
                                Object.keys(myContext.requests).reverse().map((key, index) => (
                                    <TableRow key={key} >
                                    {                                        
                                        (
                                            filter[myContext.requests[key]['status']]
                                            && 
                                            myContext.requests[key]['nonProfitName'] == user['https://nectarcares.org/organization']
                                            &&
                                            !nameFilter || myContext.requests[key]['recipientName'] == nameFilter
                                        )
                                        ?
                                        <>                                        
                                            <TableCell>
                                                {myContext.requests[key]['recipientName']}
                                            </TableCell>
                                            <TableCell>{myContext.requests[key]['shortId']}</TableCell>
                                            <TableCell>{myContext.requests[key]['timestamp'].split(' ')[0]}</TableCell>
                                            <TableCell>{myContext.requests[key]['status']}</TableCell>
                                            <TableCell component="th" scope="row" align="center">
                                                {/* <Link to={"/item-history/"+myContext.requests[key]['reqId']}> */}                                                  
                                                    {/* <Button variant="contained"
                                                        onClick={() => history.push("/item-history/"+myContext.requests[key]['shortId'])}
                                                    >
                                                        View Summary
                                                    </Button> */}
                                                {/* </Link> */}
                                                <Tooltip title="Update Request">
                                                    <IconButton onClick={() => history.push("/update-request/"+myContext.requests[key]['shortId'])}>
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="View Request Summary">
                                                    <IconButton onClick={() => history.push("/item-history/"+myContext.requests[key]['shortId'])}>
                                                        <ImportContactsIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </>
                                        :
                                        null
                                    }
                                    </TableRow>                                
                                ))
                                :                       
                                Object.keys(myContext.requests).map((key, index) => (
                                    <TableRow key={key}>
                                    {
                                        (
                                            filter[myContext.requests[key]['status']]
                                            &&
                                            myContext.requests[key]['nonProfitName'] == user['https://nectarcares.org/organization']
                                            &&
                                            (!nameFilter || myContext.requests[key]['recipientName'] == nameFilter)
                                        )
                                        ?
                                        <>
                                            <TableCell>{myContext.requests[key]['recipientName']}</TableCell>
                                            <TableCell>{myContext.requests[key]['shortId']}</TableCell>
                                            <TableCell>{myContext.requests[key]['timestamp'].split(' ')[0]}</TableCell>
                                            <TableCell>{myContext.requests[key]['status']}</TableCell>
                                            <TableCell component="th" scope="row" align="center">
                                                {/* <Link to={"/item-history/"+myContext.requests[key]['reqId']}> */}
                                                <Tooltip title="Update Request">
                                                    <IconButton onClick={() => history.push("/update-request/"+myContext.requests[key]['shortId'])}>
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="View Request Summary">
                                                    <IconButton onClick={() => history.push("/item-history/"+myContext.requests[key]['shortId'])}>
                                                        <ImportContactsIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                {/* </Link> */}
                                            </TableCell>
                                        </>
                                        :
                                        null
                                    }
                                    </TableRow>                                
                                ))
                            }
                    </TableBody>
                </Table>
            </TableContainer>          
        </div>
    );

    // Function passed to BasicSummary prop to reset the items object
    function resetItems() { 
        item_list = {};
    }
    
    
    if (myContext.requests[0].bio == "Loading...") {
        return (
            <Loading />
        )
    } else {
        return (
            <>
                {requestHistoryTable}
            </>
        )
    }
}
