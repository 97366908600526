import React from 'react';
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
    root: {
        margin: '0px 4vw 0px 4vw'
    }
}));

const AdoptLandingPage = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <h1>Adopt A Family Program</h1>
            <p>
                The adopt a family program allows donors to support a family for a given time period, helping them during their times of need.
                <br /><br />"Adopting" a family/individual means that the donor would support the family by donating items they currently need (kitchen, bathroom items etc.),
                and help with recurring needs such as gas, electricity, phone bill, etc.

                <br /><br />As a donor, you will be notified with any new requests from this family. Donors also have the option to opt out
                at any time if they are unable to continue supporting the recipient family.
            </p>
        </div>
    )
}

export default AdoptLandingPage