// Page to display the donor history of the currently logged in user based on 
import React, { useState, useEffect, useContext } from 'react'
import { 
    Button,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableContainer,
    Paper,
    TableHead,
    Typography,
    TextField,
    FormControl, RadioGroup, FormControlLabel, Radio, FormGroup, Checkbox,
    Select, OutlinedInput, MenuItem, ListItemText,
    Grid
} from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import './history.css';
import { BasicSummary } from '../index';
import { useAuth0 } from '@auth0/auth0-react';
import AppContext from '../AppContext';

var api_call_limiter = 0;
// var pageState = 0;

var item_list={};
var recipientId='';
var donationId='';
var status = ''
var deliveryInst_global = '';
var deliveryAddress_global = '';
var npEmail_global = '';
var imageURL = ''

export default function History({req}) {
    
    const myContext = useContext(AppContext);

    // const [donations, setdonations] = useState([]);   // state that stores all donations made by currently logged in user
    const [id, setid] = useState([]);
    const [recipientID, setrecipientID] = useState([]);
    const [emailArray, setemailArray] = useState([]);
    const [itemsDonated, setitemsDonated] = useState([]);
    const [nameArray, setnameArray] = useState([]);
    const [completed, setcompleted] = useState([]);
    const [render, setrender] = useState(0);
    const [deliveryInsructions, setdeliveryInsructions] = useState([]);
    const [deliveryAddress, setdeliveryAddress] = useState([]);
    const [npEmailarray, setnpEmailarray] = useState([]);
    
    const [pageState, setpageState] = useState(0);
    const [organizationFilter, setorganizationFilter] = useState();
    const [statusFilter, setstatusFilter] = React.useState({
        "Pending": true,
        "Received": true,
        "Processing": true,
        "Partially Accepted": true,
        "Rejected": true,
        "Accepted": true,
    });
    const [dateFilter, setdateFilter] = useState("asc");

    const { user } = useAuth0();
    const { name, email } = user;

    // const [item_list, setitem_list] = useState({});

    useEffect(() => {
        // alert('In history useEffect...');
        console.log(user["https://nectarcares.org/roles"][0]);
        console.log(user);
        if(api_call_limiter<1) {
            console.log("Fetching from api");
            let url = process.env.REACT_APP_SHEETY_GET_DONATIONS;
            fetch(url)
            .then((response) => response.json())
            .then(json => {
            // Do something with the data
                console.log(json.donors);
                
                // if (myContext.donations.length == 0) {
                if (!myContext.donations) {
                    myContext.setdonations(json.donors);
                }
                Object.keys(json.donors).forEach(function(key) {
                    console.log('Key : ' + key + ', Value : ' + json.donors[key].email);
                    // if email matches email in session storage, add row to object
                    if (email == json.donors[key].email) {

                        console.log(`items: ${itemsDonated}`);
                        
                        console.log(`adding ${json.donors[key].itemsDonated} to ${itemsDonated}`);
                        var items_array = itemsDonated;
                        items_array.push(json.donors[key].itemsDonated);
                        setitemsDonated(items_array);
                        // setid({ id: [...id, json.donors[key].id] });
                        
                        // console.log(`adding ${json.donors[key].itemsDonated} to ${itemsDonated}`);
                        var id_array = id;
                        id_array.push(json.donors[key].id);
                        setid(id_array);
                        
                        var rid_array = recipientID;
                        // rid_array = recipientID;
                        rid_array.push(json.donors[key].recipientId);
                        setrecipientID(rid_array);
                        
                        var delivery_instructions_array = deliveryInsructions;
                        delivery_instructions_array.push(json.donors[key].deliveryInstructions);
                        setdeliveryInsructions(delivery_instructions_array);

                        var email_array = emailArray;
                        email_array.push(json.donors[key].email);
                        setemailArray(email_array);

                        var name_array = nameArray;
                        name_array.push(json.donors[key].name);
                        setnameArray(name_array);

                        // setrender(100);
                        var completed_array = completed;
                        completed_array.push(json.donors[key].completed);
                        setcompleted(completed_array);

                        // set delivery address of current donation into the deliveryAddress array
                        var delivery_address_array = deliveryAddress;
                        delivery_address_array.push(json.donors[key].deliveryAddress);
                        setdeliveryAddress(delivery_address_array);
                        // alert(`History.jsx, setting delivery addr: ${json.donors[key].deliveryAddress}`);
                        
                        // set np email array
                        // alert(`History: Adding ${json.donors[key].contact} to np email arrya`);
                        var np_email_array = npEmailarray;
                        np_email_array.push(json.donors[key].contact);
                        setnpEmailarray(np_email_array);
                    }
                });
                console.log(`id: ${id}`);
                // id.map((key,i) => (
                //     console.log(`Key: ${key}`)
                // ))
                // alert('Done in useEffect...');
            })
            .then( () =>  {
                console.log('Setting render...');
                // console.log(recipientID);
                setrender(2);
                // window.location.reload();
    });
        }
        api_call_limiter++;
    });

    function handleClick(item, rid, currentStatus, deliveryInst, deliveryAddr, contact, donationID, url) {
        console.log("Handling click in History.jsx");

        recipientId = rid;
        donationId = donationID;
        status = currentStatus;
        imageURL = url;

        // Put item string into item_list object to pass to BasicSummary component
        var item_split = item.split(',');
        for(var i=0; i<item_split.length; i++) {
            if (i==0) {
                item_list = {
                    ...item_list,
                    [item_split[i].split(' ')[1]]: item_split[i].split(' ')[0],
                }
            } else {
                item_list = {
                    ...item_list,
                    [item_split[i].split(' ')[2]]: item_split[i].split(' ')[1],
                }
            }
        }


        // if (deliveryInst == '' || deliveryInst == null) {
        //     deliveryInst_global = 'Deliver to Trusted World, \n613b Easy St, Garland, TX 75042';
        // } else {
            deliveryInst_global = deliveryInst;
        // }

        // alert(`History.js: delivery to ${deliveryAddr}`);
        if (deliveryAddr == '' || deliveryAddr == null) {
            deliveryAddress_global = 'Trusted World, 613b Easy St, Garland, TX 75042';
        } else {
            deliveryAddress_global = deliveryAddr;
        }

        // set np email for the currently clicked donation
        npEmail_global = contact;

        // alert(`Handling click, delivery addr: ${deliveryAddr}`);
        // alert(`Handling click, delivery instructions: ${deliveryInst}`);
        console.log(`handleClick: converting ${item} \n to`);
        console.log(item_list);

        setpageState(1);
        setrender(5);
    }

    // function to convert item name from camelcase to normal word (Eg: pillowCases(standard) -> pillow cases(standard)) 
    function convertCase(item) {
        var gapi_key;

        if(/[A-Z]/.test(item)) {
            // split word at capital letter
            var str_array = item.split(/(?=[A-Z])/);
            gapi_key = str_array.join(' ');
            gapi_key = gapi_key.toLowerCase();
        } else {
            return item;
        }
        
        console.log(`converted ${item} to `);
        return gapi_key;
    }

    const donationHistoryTable = (
        <div>
            <div className="summary-header">
                <h2>Donation History for ({user.email})</h2>
            </div>

            <Grid container spacing={4}>
                <Grid item xs={12} sm={4} className="request-history-filters">
                    <Typography className="bold-text">Filter by Organization: </Typography>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={["Trusted World", "Other"]}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Organization Name" />}
                        onClose={(e) => setorganizationFilter(e.target.innerText)}
                    />
                </Grid>
                <Grid item xs={12} sm={4} className="request-history-filters">
                    <Typography className="bold-text">Filter by Date: </Typography>
                    <FormControl component="fieldset">
                    {/* <FormLabel component="legend">Gender</FormLabel> */}
                    <RadioGroup row aria-label="gender" name="row-radio-buttons-group" onChange={(e) => setdateFilter(e.target.value)}>
                        <FormControlLabel value="asc" control={<Radio />} label="Ascending" />
                        <FormControlLabel value="desc" control={<Radio />} label="Descending" />                       
                    </RadioGroup>
                    </FormControl>                   
                </Grid>
                <Grid item xs={12} className="request-history-filters">
                    <Typography className="bold-text">Filter by Status: </Typography>
                    <FormGroup row>
                            <FormControlLabel
                                control={
                                <Checkbox
                                    checked={statusFilter.Pending}
                                    onChange={(event) => setstatusFilter({ ...statusFilter, [event.target.name]: event.target.checked })}
                                    name="Pending"
                                    color="primary"
                                />
                                }
                                label="Pending"
                            />                           
                            <FormControlLabel
                                control={
                                <Checkbox
                                    checked={statusFilter.Received}
                                    onChange={(event) => setstatusFilter({ ...statusFilter, [event.target.name]: event.target.checked })}
                                    name="Received"
                                    color="primary"
                                />
                                }
                                label="Received"
                            />
                             <FormControlLabel
                                control={
                                <Checkbox
                                    checked={statusFilter.Processing}
                                    onChange={(event) => setstatusFilter({ ...statusFilter, [event.target.name]: event.target.checked })}
                                    name="Processing"
                                    color="primary"
                                />
                                }
                                label="Processing"
                            />
                            <FormControlLabel
                                control={
                                <Checkbox
                                    checked={statusFilter['Partially Accepted']}
                                    onChange={(event) => setstatusFilter({ ...statusFilter, [event.target.name]: event.target.checked })}
                                    name="Partially Accepted"
                                    color="primary"
                                />
                                }
                                label="Partially Accepted"
                            />
                            <FormControlLabel
                                control={
                                <Checkbox
                                    checked={statusFilter.Accepted}
                                    onChange={(event) => setstatusFilter({ ...statusFilter, [event.target.name]: event.target.checked })}
                                    name="Accepted"
                                    color="primary"
                                />
                                }
                                label="Accepted"
                            />
                            <FormControlLabel
                                control={
                                <Checkbox
                                    checked={statusFilter.Rejected}
                                    onChange={(event) => setstatusFilter({ ...statusFilter, [event.target.name]: event.target.checked })}
                                    name="Rejected"
                                    color="primary"
                                />
                                }
                                label="Rejected"
                            />
                            {/*
                              <FormControl sx={{ m: 1, width: 300 }}>
                                <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={['Pending', 'Received']}
                                    // onChange={handleChange}
                                    input={<OutlinedInput label="Tag" />}
                                    renderValue={(selected) => selected.join(', ')}
                                >                                
                                    <MenuItem value="Pending">
                                        <Checkbox 
                                        // checked={personName.indexOf(name) > -1} 
                                        />
                                        <ListItemText primary="Pending" />
                                    </MenuItem>
                                    <MenuItem value="Received">
                                        <Checkbox 
                                        // checked={personName.indexOf(name) > -1} 
                                        />
                                        <ListItemText primary="Received" />
                                    </MenuItem>
                                    <MenuItem value={name}>
                                        <Checkbox checked={personName.indexOf(name) > -1} />
                                        <ListItemText primary={name} />
                                    </MenuItem>
                                    <MenuItem value={name}>
                                        <Checkbox checked={personName.indexOf(name) > -1} />
                                        <ListItemText primary={name} />
                                    </MenuItem>
                                    <MenuItem value={name}>
                                        <Checkbox checked={personName.indexOf(name) > -1} />
                                        <ListItemText primary={name} />
                                    </MenuItem>
                                    <MenuItem value={name}>
                                        <Checkbox checked={personName.indexOf(name) > -1} />
                                        <ListItemText primary={name} />
                                    </MenuItem>
                                </Select>
                            </FormControl>*/}
                        </FormGroup>  
                </Grid>
            </Grid>
            

            <TableContainer component={Paper} className="table">
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>Donation Summary</strong></TableCell>
                            <TableCell><strong>Items</strong></TableCell>
                            <TableCell><strong>Organization</strong></TableCell>
                            <TableCell align="right"><strong>Family ID</strong></TableCell>
                            <TableCell align="right"><strong>Status</strong></TableCell>
                            <TableCell align="right"><strong>Non-Profit Contact</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>                
                        {
                            myContext.donations ?
                            (
                            (
                                dateFilter == "desc"
                            ) ?
                                (Object.keys(myContext.donations).reverse().map((key, index) => (
                                    <TableRow key={key}>
                                    {
                                        (
                                            (myContext.donations[key]['email'] == user.email)
                                            &&
                                            (!organizationFilter || myContext.donations[key]['organization'] == organizationFilter)
                                            &&
                                            (statusFilter[myContext.donations[key]['completed']] == true)
                                        )
                                        ?
                                        <>
                                            <TableCell component="th" scope="row">
                                                <Button variant="contained" 
                                                    onClick={() => handleClick(
                                                        myContext.donations[key]['itemsDonated'],
                                                        myContext.donations[key]['recipientId'],
                                                        myContext.donations[key]['completed'],
                                                        myContext.donations[key]['deliveryInstructions'],
                                                        myContext.donations[key]['deliveryAddress'],
                                                        myContext.donations[key]['contact'],                                     
                                                        myContext.donations[key]['donationId'],                                     
                                                        myContext.donations[key]['image'],
                                                    )}>
                                                    View Summary
                                                </Button>
                                            </TableCell>
                                            <TableCell>{convertCase(myContext.donations[key]['itemsDonated'])}</TableCell>
                                            <TableCell>{myContext.donations[key]['organization']}</TableCell>
                                            <TableCell align="right">{myContext.donations[key]['recipientId']}</TableCell>
                                            <TableCell align="right">{myContext.donations[key]['completed']}</TableCell>
                                            <TableCell align="right">{myContext.donations[key]['contact']}</TableCell>
                                        </>
                                        :
                                        null                        
                                    }
                                    </TableRow>
                                )))                                
                            :
                            Object.keys(myContext.donations).map((key, index) => (
                                <TableRow key={key}>
                                {
                                    (
                                        (myContext.donations[key]['email'] == user.email)
                                        &&
                                        (!organizationFilter || myContext.donations[key]['organization'] == organizationFilter)
                                        &&
                                        (statusFilter[myContext.donations[key]['completed']] == true)
                                    )
                                    ?
                                    <>
                                        <TableCell component="th" scope="row">
                                            <Button variant="contained" 
                                                onClick={() => handleClick(
                                                    myContext.donations[key]['itemsDonated'],
                                                    myContext.donations[key]['recipientId'],
                                                    myContext.donations[key]['completed'],
                                                    myContext.donations[key]['deliveryInstructions'],
                                                    myContext.donations[key]['deliveryAddress'],
                                                    myContext.donations[key]['contact'],                                     
                                                    myContext.donations[key]['donationId'],                                  
                                                    myContext.donations[key]['image'],
                                                )}>
                                                View Summary
                                            </Button>
                                        </TableCell>
                                        <TableCell>{convertCase(myContext.donations[key]['itemsDonated'])}</TableCell>
                                        <TableCell>{myContext.donations[key]['organization']}</TableCell>
                                        <TableCell align="right">{myContext.donations[key]['recipientId']}</TableCell>
                                        <TableCell align="right">{myContext.donations[key]['completed']}</TableCell>
                                        <TableCell align="right">{myContext.donations[key]['contact']}</TableCell>
                                    </>
                                    :
                                    null                        
                                }
                                </TableRow>
                            )) 
                            )
                            :
                            null               
                        }
                        {/* {id.map((key,i) => (
                            <TableRow key={key}>
                                <TableCell component="th" scope="row">
                                    <Button variant="contained" 
                                        onClick={() => handleClick(
                                            itemsDonated[i],
                                            recipientID[i],
                                            completed[i]=='y' ? 'completed' : 'pending',
                                            // deliveryInsructions[i] == '' ? 'Deliver to Trusted World, <br></>613b Easy St, Garland, TX 75042' : deliveryInsructions[i]
                                            deliveryInsructions[i],
                                            deliveryAddress[i],
                                            npEmailarray[i]
                                        )}>
                                        View Summary
                                    </Button>
                                </TableCell>
                                <TableCell>{convertCase(itemsDonated[i])}</TableCell>
                                <TableCell align="right">{recipientID[i]}</TableCell>
                                <TableCell align="right">{completed[i]=='y' ? 'completed' : 'pending'}</TableCell>
                                <TableCell align="right">{npEmailarray[i]}</TableCell>
                            </TableRow>
                        ))} */}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );

    function handleClose() {
        // pageState = 0;
        setpageState(1);
        setrender(3);
    }

    // Function passed to BasicSummary prop to reset the items object
    function resetItems() { 
        item_list = {};
    }

    const donationPDF = (
        <>
         {/* {
             alert(`Calling BasicSummary:\n 
                delivery instructions: ${deliveryInst_global}\n
                delivery address: ${deliveryAddress_global}\n
                item list : ${item_list}\n
            `)
         } */}
            <BasicSummary 
                familyID={recipientId} 
                deliveryInstructions={deliveryInst_global} 
                deliveryAddress={deliveryAddress_global} 
                item_list={item_list} 
                setpageState={setpageState}
                resetItems={resetItems}
                npEmail={npEmail_global}
                donationID={donationId}
                imageURL={imageURL}
            />
        </>
    );
    
    return (
        <>
            { pageState == 0 ? donationHistoryTable : 
                donationPDF
            }
        </>
    )
}
