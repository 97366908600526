// Page to display the donor history of the currently logged in user based on 
import React, { useState, useEffect, useContext } from 'react'
import { 
    Button,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableContainer,
    Paper,
    TableHead,
    Typography,
    TextField,
    FormControl, RadioGroup, FormControlLabel, Radio, FormGroup, Checkbox,
    Select, OutlinedInput, MenuItem, ListItemText,
    Grid
} from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import { BasicSummary } from '../index';
import { useAuth0 } from '@auth0/auth0-react';
import AppContext from '../AppContext';

var api_call_limiter = 0;

var item_list={};
var recipientId='';
var donationId='';
var status = ''
var deliveryInst_global = '';
var deliveryAddress_global = '';
var npEmail_global = '';
var imageURL = '';
var adoptedRequestIDList = [];
var allAdoptions;

export default function AdoptionHistory({req}) {
    
    const myContext = useContext(AppContext);

    // const [donations, setdonations] = useState([]);   // state that stores all donations made by currently logged in user
    const [id, setid] = useState([]);
    const [recipientID, setrecipientID] = useState([]);
    const [emailArray, setemailArray] = useState([]);
    const [itemsDonated, setitemsDonated] = useState([]);
    const [nameArray, setnameArray] = useState([]);
    const [completed, setcompleted] = useState([]);
    const [render, setrender] = useState(0);
    const [deliveryInsructions, setdeliveryInsructions] = useState([]);
    const [deliveryAddress, setdeliveryAddress] = useState([]);
    const [npEmailarray, setnpEmailarray] = useState([]);
    
    const [pageState, setpageState] = useState(0);
    const [organizationFilter, setorganizationFilter] = useState();
    const [statusFilter, setstatusFilter] = React.useState({
        "Pending": true,
        "Received": true,
        "Processing": true,
        "Partially Accepted": true,
        "Rejected": true,
        "Accepted": true,
    });
    const [dateFilter, setdateFilter] = useState("asc");

    const { user } = useAuth0();
    const { name, email } = user;

    // const [item_list, setitem_list] = useState({});

    useEffect(() => {
        if(api_call_limiter<=1) {
            console.log("Fetching from api");
            let url = process.env.REACT_APP_SHEETY_GET_DONATIONS;
            fetch(url)
            .then((response) => response.json())
            .then(json => {
            // Do something with the data
                console.log(json.donors);
                
                // if (myContext.donations.length == 0) {
                if (!myContext.donations) {
                    myContext.setdonations(json.donors);
                }
                Object.keys(json.donors).forEach(function(key) {
                    console.log('Key : ' + key + ', Value : ' + json.donors[key].email);
                    // if email matches email in session storage, add row to object
                    if (email == json.donors[key].email) {

                        console.log(`items: ${itemsDonated}`);
                        
                        console.log(`adding ${json.donors[key].itemsDonated} to ${itemsDonated}`);
                        var items_array = itemsDonated;
                        items_array.push(json.donors[key].itemsDonated);
                        setitemsDonated(items_array);
                        // setid({ id: [...id, json.donors[key].id] });
                        
                        // console.log(`adding ${json.donors[key].itemsDonated} to ${itemsDonated}`);
                        var id_array = id;
                        id_array.push(json.donors[key].id);
                        setid(id_array);
                        
                        var rid_array = recipientID;
                        // rid_array = recipientID;
                        rid_array.push(json.donors[key].recipientId);
                        setrecipientID(rid_array);
                        
                        var delivery_instructions_array = deliveryInsructions;
                        delivery_instructions_array.push(json.donors[key].deliveryInstructions);
                        setdeliveryInsructions(delivery_instructions_array);

                        var email_array = emailArray;
                        email_array.push(json.donors[key].email);
                        setemailArray(email_array);

                        var name_array = nameArray;
                        name_array.push(json.donors[key].name);
                        setnameArray(name_array);

                        // setrender(100);
                        var completed_array = completed;
                        completed_array.push(json.donors[key].completed);
                        setcompleted(completed_array);

                        // set delivery address of current donation into the deliveryAddress array
                        var delivery_address_array = deliveryAddress;
                        delivery_address_array.push(json.donors[key].deliveryAddress);
                        setdeliveryAddress(delivery_address_array);
                        // alert(`History.jsx, setting delivery addr: ${json.donors[key].deliveryAddress}`);
                        
                        // set np email array
                        // alert(`History: Adding ${json.donors[key].contact} to np email arrya`);
                        var np_email_array = npEmailarray;
                        np_email_array.push(json.donors[key].contact);
                        setnpEmailarray(np_email_array);
                    }
                });
                console.log(`id: ${id}`);
                // id.map((key,i) => (
                //     console.log(`Key: ${key}`)
                // ))
                // alert('Done in useEffect...');
            })
            .then( () =>  {
                // console.log('Setting render...');
                // console.log(recipientID);
                // setrender(2);
                // window.location.reload();
                // get adoption info 
            });     
            
            let requestsURL = process.env.REACT_APP_SHEETY_API_GET;
            fetch(requestsURL)
            .then((response) => response.json())
            .then(json => {
            // Do something with the data                
                // Update the prop with the requests
                if (myContext.setrequests) {
                    console.log("Going to set requests in AdoptionHistory useEffect");
                    // console.log(myContext);
                    myContext.setrequests(json.requests);
                }
                console.log(req);
            });

            let adoptionURL = process.env.REACT_APP_SHEETY_GET_ADOPTIONS;
            fetch(adoptionURL)
            .then((response) => response.json())
            .then(json => {
                // Do something with the data
                console.log(json.adoptAFamily);
                for(var i=0; i<json.adoptAFamily.length; i++) {
                    console.log(json.adoptAFamily[i]['donorEmail']);
                    console.log(user.email);
                    if (json.adoptAFamily[i]['donorEmail'] == user.email) {
                        // alert('donor email found')
                        adoptedRequestIDList.push(json.adoptAFamily[i]['requestId']);
                        // alert(json.adoptAFamily[i]['requestId']);
                    }
                }                
            });
        }
        api_call_limiter++;

        if (myContext.adoptions[0]['status'] == 'loading') {
            console.log('calling adoptions...');
            // let adoptionsURL = process.env.REACT_APP_SHEETY_GET_ADOPTIONS;
            let adoptionsURL = 'https://api.sheety.co/591c67f7e588d2a49e1cf55d260ce8a5/recipient/adoptAFamily';
            fetch(adoptionsURL)
            .then((response) => response.json())
            .then(json => {
                // iterate through json.adoptAFamily and search for 'Request ID' matching id
                // alert('setting adoptions in RequestSummary ...');
                console.log('76940:\n');
                console.log(json);
                // console.log(json.adoptAFamily);
                myContext.setAdoptions(json.adoptAFamily);
            });
        }
    });

    function handleClick(item, rid, currentStatus, deliveryInst, deliveryAddr, contact, donationID, url) {
        console.log("Handling click in History.jsx");

        recipientId = rid;
        donationId = donationID;
        status = currentStatus;
        imageURL = url;

        // Put item string into item_list object to pass to BasicSummary component
        var item_split = item.split(',');
        for(var i=0; i<item_split.length; i++) {
            if (i==0) {
                item_list = {
                    ...item_list,
                    [item_split[i].split(' ')[1]]: item_split[i].split(' ')[0],
                }
            } else {
                item_list = {
                    ...item_list,
                    [item_split[i].split(' ')[2]]: item_split[i].split(' ')[1],
                }
            }
        }


        // if (deliveryInst == '' || deliveryInst == null) {
        //     deliveryInst_global = 'Deliver to Trusted World, \n613b Easy St, Garland, TX 75042';
        // } else {
            deliveryInst_global = deliveryInst;
        // }

        // alert(`History.js: delivery to ${deliveryAddr}`);
        if (deliveryAddr == '' || deliveryAddr == null) {
            deliveryAddress_global = 'Trusted World, 613b Easy St, Garland, TX 75042';
        } else {
            deliveryAddress_global = deliveryAddr;
        }

        // set np email for the currently clicked donation
        npEmail_global = contact;

        // alert(`Handling click, delivery addr: ${deliveryAddr}`);
        // alert(`Handling click, delivery instructions: ${deliveryInst}`);
        console.log(`handleClick: converting ${item} \n to`);
        console.log(item_list);

        setpageState(1);
        setrender(5);
    }

    // function to convert item name from camelcase to normal word (Eg: pillowCases(standard) -> pillow cases(standard)) 
    function convertCase(item) {
        var gapi_key;

        if(/[A-Z]/.test(item)) {
            // split word at capital letter
            var str_array = item.split(/(?=[A-Z])/);
            gapi_key = str_array.join(' ');
            gapi_key = gapi_key.toLowerCase();
        } else {
            return item;
        }
        
        console.log(`converted ${item} to `);
        return gapi_key;
    }
    
    // Get adoption sheet index of the relevant row
    function getAdoptionStatus(requestID) {
        if (myContext.adoptions[0]['status'] != 'loading') {
            var index;
            for (var i=0; i<myContext.adoptions.length; i++) {
                if (requestID == myContext.adoptions[i]['requestId']) {
                    if (user.email == myContext.adoptions[i]['donorEmail']) {
                        // alert(`index is ${index}`);
                        index = i;
                        break;
                    }
                }
            }
            
            return myContext.adoptions[index]['status'];
        }
    }

    const donationHistoryTable = () => {


        return (        
            <div>        
                <div className="summary-header">
                    <h2>Adoption History for ({user.email})</h2>
                </div>                  

                <TableContainer component={Paper} className="table">
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell><strong>Organization</strong></TableCell>
                                <TableCell><strong>Family ID</strong></TableCell>
                                <TableCell><strong>Family Name</strong></TableCell>
                                <TableCell><strong>Non-Profit Contact</strong></TableCell>
                                <TableCell><strong>Adoption Status</strong></TableCell>
                                <TableCell align='right'><strong>Actions</strong></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>                
                            {                                                         
                                myContext.requests && Object.keys(myContext.requests).map((key, index) => (
                                    <TableRow key={key}>
                                    {
                                        (
                                            (adoptedRequestIDList.includes(myContext.requests[key]['shortId']))
                                        )
                                        ?
                                        <>                                       
                                            <TableCell>{myContext.requests[key]['nonProfitName']}</TableCell>
                                            <TableCell>{myContext.requests[key]['shortId']}</TableCell>
                                            <TableCell>{myContext.requests[key]['recipientName']}</TableCell>
                                            <TableCell>{myContext.requests[key]['nonProfitEmail']}</TableCell>
                                            <TableCell>{getAdoptionStatus(myContext.requests[key]['shortId'])}</TableCell>
                                            <TableCell align='right'>
                                                <Button variant='contained' href={'/adopt/'+myContext.requests[key]['shortId']}
                                                style={{
                                                    textTransform: 'none'
                                                }} >
                                                    View Adoption
                                                </Button>
                                            </TableCell>
                                        </>
                                        :
                                        null                        
                                    }
                                    </TableRow>
                                )) 
                            }                     
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        )
    };

    function handleClose() {
        // pageState = 0;
        setpageState(1);
        setrender(3);
    }

    // Function passed to BasicSummary prop to reset the items object
    function resetItems() { 
        item_list = {};
    }
    
    return (
        <>
            {donationHistoryTable()}
        </>
    )
}
