import React, { useState, useEffect, useContext } from 'react';
import { Grid, Card, CardContent, Typography, CardActions, Button, FormControl, Select, Checkbox, FormControlLabel, FormGroup, Stepper, Step, StepLabel, InputLabel, MenuItem, TextField, Divider, FormHelperText, Paper, Tabs, Tab, Badge, CardHeader, CardMedia } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ItemGallery from './ItemGallery';
import Input from 'react-phone-number-input/input';
import * as items from '../../items';
import CartSummary from './CartSummary';
import itemsObjects from "../../items.json";
import Loading from '../loading';
import AppContext from '../AppContext';
import { useAuth0 } from '@auth0/auth0-react';

/* Return the relevant form based on the step number 
    0 - Non profit details
    1 - Recipient details
    2 - Items selection
*/

// function to convert item name from camelcase to normal word (Eg: pillowCases(standard) -> pillow cases(standard)) 
function convertCase(item) {
    var gapi_key;

    if(/[A-Z]/.test(item)) {
        // split word at capital letter
        var str_array = item.split(/(?=[A-Z])/);
        gapi_key = str_array.join(' ');
        gapi_key = gapi_key.toLowerCase();
    } else {
        return item;
    }
    
    console.log(`converted ${item} to `);
    return gapi_key;
}

const useStyles = makeStyles((theme) => ({
    root : {
        width: '85vw',
        margin: 'auto',
    },
    formRoot: {
        display: 'flex',
        // padding: '10px',
        width: '100%',
        // maxWidth: '1000px',
        flexWrap: 'wrap',
        margin: 'auto',
        // justifyContent: 'space-evenly',
    },
    step1Root: {
        maxWidth: 'min(800px, 90vw)',
    },
    categoryDropdown: {
        width: '11rem',
        margin: '12px',
    },
    FormControl: {
        width: '11rem',
        margin: '12px 12px 12px 0px',
    },
    stepTitle: {
        // margin: '10px 20px 10px 26px',
        margin: '10px 20px 10px 0px',
    },
    TextField: {
        margin: '1%',
    },
    subCategory: {
        width: '100%',
        fontWeight: 'bold',
        // marginLeft: '5rem',
        marginTop: '3.5rem'
    },
    halfWidth: {
        width: '48%',
    },
    thirdWidth: {
        width: '31.3%',
    },
    InputLabel: {
        // marginLeft: '2%',
        // marginTop: '-3px',
    },
    prefCommunication: {
        width: "19%",
        minWidth: "fit-content",
    },
    button: {
    //   marginRight: theme.spacing(1),
        margin: theme.spacing(2),
    },
    greenButton: {
        backgroundColor: 'rgba(52, 211, 153, 1)',
    }
}));

var api_call_limiter = 0;

const RecipientForm = ({
    step, 
    itemsRequested, 
    nonProfit, 
    setnonProfit, 
    setnpEmail, 
    setrecipientFirstName, 
    setrecipientLastName, 
    setrecipientEmail,
    setrecipientPhone,
    setrecipientAddress1,
    setrecipientAddress2,
    setrecipientCity,
    setrecipientState,
    setrecipientZip,
    setrecipientBio,
    recipientPhone,
    setphoneType,
    setpreferredCommunication,
    alternateContactCheckbox, 
    setalternateContactCheckbox,

    setalternateContactFirstName,
    setalternateContactLastName,
    setalternateContactEmail,
    setalternateContactPhone,
    setalternateContactPhoneType,
    setalternateContactAddress1,
    setalternateContactAddress2,
    setalternateContactCity,
    setalternateContactState,
    setalternateContactZip,
    alternateContactFirstName,
    alternateContactLastName,
    alternateContactEmail,
    alternateContactPhone,
    alternateContactPhoneType,
    alternateContactAddress1,
    alternateContactAddress2,
    alternateContactCity,
    alternateContactState,
    alternateContactZip,
    setalternateContactpreferredCommunication,
    handleNext,
    recipientFirstName,
    recipientLastName,
    recipientEmail,
    recipientBio,
    npEmail,
    
    handleBack
}) => {
    
    const [npEmailLocal, setnpEmailLocal] = useState('');       // np email to set value of text field
    const [npName, setnpName] = useState('');
    const [currentCategory, setcurrentCategory] = useState('Kitchen');
    const [tabValue, setTabValue] = useState(0);
    const [formValidation, setformValidation] = useState({
        error: false,
        errorMessage: {}
    });
    var nonProfitOrgsData;
    // var approvedNonProfitList = [];
    const [approvedNonProfitList, setapprovedNonProfitList] = useState();
    const myContext = useContext(AppContext);
    
    const classes = useStyles();
    const { user } = useAuth0();

    useEffect(() => {
        console.warn('Re rendering child component RecipientForm');
        console.log(itemsRequested);
        console.log(formValidation);

        // Call Sheety end point to read a list of approved non profit organizations
        // This list will be used to populate the "Select Non Profit" dropdown in step 0
        if (step == 0 && api_call_limiter < 1) {  
            // alert('Making API call');
            var npList = [];          
            let url = 'https://api.sheety.co/591c67f7e588d2a49e1cf55d260ce8a5/recipient/nonProfitOrgs';
            fetch(url)
            .then((response) => response.json())
            .then(json => {
                // Do something with the data
                console.log(json.nonProfitOrgs);
                nonProfitOrgsData = json.nonProfitOrgs;
                myContext.setnonProfitOrgs(nonProfitOrgsData);

                for (var i=0; i<nonProfitOrgsData.length; i++) {
                    console.log(nonProfitOrgsData[i]['status'])
                    
                    if (nonProfitOrgsData[i]['status'] == "Approved") {
                        npList.push(nonProfitOrgsData[i]['name'])
                    }
                }
                console.log(npList);
            });
            api_call_limiter++;
            console.log("Setting approved np list");
            setapprovedNonProfitList(npList);
            console.log(approvedNonProfitList);
        }
    })

    const [filter, setFilter] = React.useState({
        Kitchen: true,
        Bedroom: true,
        Bathroom: true,
    });
    // const [itemRequest, setitemRequest] = useState();    

    const handleFilterChange = (event) => {
        // setFilter({ ...filter, [event.target.name]: event.target.checked });
        // setcurrentCategory(event.target.value);
    };    
    
    const handleChangeAlternateContactCheckbox = (event) => {
        setalternateContactCheckbox(event.target.checked);
    }
    
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        if (newValue == 0) setcurrentCategory("Kitchen")
        else if (newValue == 1) setcurrentCategory("Bedroom")
        else if (newValue == 2) setcurrentCategory("Bathroom")
    }

    // This function takes in an item(string) and checks if the item cetegory filter is checked to true
    // If there is a match, we return true. Else, we return false
    function checkFilter(item) {
        console.log(item);

        if(items.items.indexOf(item) <= 30 && currentCategory=="Kitchen") {
            return true;
        } else if((items.items.indexOf(item) <= 38 && items.items.indexOf(item) > 30 )  && currentCategory=="Bedroom") {
            return true;
        } else if(items.items.indexOf(item) > 38  && currentCategory=="Bathroom") {
            return true;
        }

        return false;
    }

    const handleChangeStep0 = (event) => {
      console.warn(event.target.value);
      setnonProfit(event.target.value);
    };

    // Handler function to handle donation submissions
    const handleChangeStep2 = (event) => {
        // console.log("Handling item request...");
        const name = event.target.id;
        console.log(event);
        console.log(event.target.value);
        // setrequestedItems({
        //     ...requestedItems,
        //     [name]: event.target.value,
        // });
        console.log(itemsRequested);
        // console.log(`Changing ${event}`);
        itemsRequested[event.target.name] = event.target.value;
        console.log(itemsRequested[event]);
    };

    // handles change for phone number input
    function handleOnChange(value) {
        setrecipientPhone(value);
        // this.setState({
        //    phone: value
        // });
     }

     const validate = (step) => {
        let isError = false;        
        
            if (step == 0) {
                console.log("In switch statement...");
                console.log(npEmail);
                if (npEmail == "") {                        
                    console.log("NP Email is empty");
                    isError = true;
                    setformValidation(prev => ({
                        ...prev,
                        error: true,
                        errorMessage: {
                            ...prev.errorMessage,
                            npEmail: "Non-Profit Email is required"
                        }
                    }))
                }
            }
            else if (step == 1) {
                console.log("Switch statement case 1");
                // Check first name
                if (recipientFirstName == "") {
                    console.log("First name not filled");
                    isError = true;
                    setformValidation(prev => ({
                        ...prev,
                        error: true,
                        errorMessage: {
                            ...prev.errorMessage,
                            firstName: "First Name is required"
                        }
                    }))
                }
                if (recipientLastName == '') {
                    isError = true;
                    setformValidation(prev => ({            
                        ...prev,
                        error: true,
                        errorMessage: {
                            ...prev.errorMessage,
                            lastName: "Last Name is required"
                        }
                    }))
                }
                // if (recipientPhone == '' || recipientPhone && recipientPhone.length < 10) {
                if (!recipientPhone) {
                    isError = true;
                    setformValidation(prev => ({
                        ...prev,
                        error: true,
                        errorMessage: {
                            ...prev.errorMessage,
                            phoneNumber: "Valid phone number is required"
                        }
                    }))
                }
                if (recipientEmail == '') {
                    isError = true;
                    setformValidation(prev => ({
                        ...prev,
                        error: true,
                        errorMessage: {
                            ...prev.errorMessage,
                            email: "Email is required"
                        }
                    }))
                }
                if (recipientBio == '') {
                    isError = true;
                    setformValidation(prev => ({
                        ...prev,
                        error: true,
                        errorMessage: {
                            ...prev.errorMessage,
                            bio: "Recipient bio is required"
                        }
                    }))
                }
            }
                       
        // if there is an error, form is NOT valid
        return !isError;
    }
    
    // return a MenuItem list of approved organizations to dispay in the organization select drop down in Step 0
    function getOrganizationList() {
        if (!myContext.nonProfitOrgs) {
        // if (!approvedNonProfitList) {
            // alert('no approved list');
            console.log('no approved list');
            return (
                <Loading />
            )
        } else {
            var approvedNpList = [];
            // alert('printing approved list')
            console.log("printing list...");
            // approvedNonProfitList.forEach((key, index) =>  {
                // approvedNonProfitList.forEach((key, index) =>  {
                myContext.nonProfitOrgs.forEach((key, index) =>  {
                    if (key['status'] == 'Approved') {
                        approvedNpList.push(key['name'])
                    }
                })
            return (
                <div>
                    {                        
                        //approvedNonProfitList.map((key, index) =>  (                        
                        approvedNpList.map((key, index) =>  (                                
                            <MenuItem key={index} value={key}>{key}</MenuItem>
                        ))
                    }
                </div>
            )
        }
    }
    //  const Step0 = () => {
    function Step0() {
        return (
            <div className={classes.root}>
                <Typography variant="h4" className={classes.stepTitle}>Non-Profit Details</Typography>
                <form className={classes.formRoot}>
                    <FormControl className={classes.FormControl}>
                        {/* <InputLabel id="demo-simple-select-label">Non-Profit</InputLabel> */}

                        {/* <Select
                        name="npName"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={nonProfit}
                        onChange={handleChangeStep0}
                        >
                        <MenuItem value="">Select your Non Profit</MenuItem>
                        {getOrganizationList()}                       
                        </Select> */}
                        <TextField 
                            id="npEmail"
                            name="npName"
                            label="Non-Profit Name"
                            defaultValue={user['https://nectarcares.org/organization']}                                                                
                            InputProps={{
                                readOnly: true,
                            }}
                            variant='filled'
                        />
                    </FormControl>
                    <FormControl className={classes.FormControl}>
                        <TextField 
                            onChange={(e) => setnpEmail(e.target.value)}
                            id="npEmail"
                            label="Email"
                            type="email"
                            name="npEmail"
                            required
                            data-test="npEmail"
                            error={!!formValidation.errorMessage.npEmail}                       
                            helperText={
                                formValidation.errorMessage.npEmail &&
                                formValidation.errorMessage.npEmail
                            }                                                                    
                        />
                    </FormControl>
                </form>
                 <Button
                        variant="contained"
                        // color="primary"                        
                        onClick={() => handleFormValidation(0)}
                        // type="submit"
                        className={`${classes.button} ${classes.greenButton}`}
                    >
                        Next
                    </Button>
            </div>
        )
    }

    // Telephone number component
    const PhoneNumber = () => {
        return (
            <TextField 
                id="recipient-number" 
                label="Phone Number"
                variant="outlined" 
                className={classes.TextField}
            />
        )
    }

    const handleChangePhoneNumber = (e) => {
        let number = '' + e.target.value;
        
        if (number.length > 14) {
            return;
        }
        
        console.log(`target id is: ${e.target.id}`);
        // if user is back-spacing, just return as usual
        if (e.target.id == "alt-number") {
            console.log('THIS IS ALT NUMBER...');
            if (alternateContactPhone && (number.length < alternateContactPhone.length)) {
                console.log('THIS IS ALT NUMBER(2)...');
                setalternateContactPhone(number);
                return;
            }
        }
        else if (recipientPhone && (number.length < recipientPhone.length)) {
            setrecipientPhone(number);
            return;
        }

        // remove brackets when number goes below 3 digits ?
        if (number.length < 3) {
            
        }
        // adding brackets when 3 numbers have been inputted
        if (number.length == 3) {
            number = '(' + number + ') ';
            console.log(number);
        }
        // add hiphen after 9th character
        if (number.length == 9) {
            number = number + '-';
            console.log(number);
        }
        
        if (e.target.id == "alt-number") {
            setalternateContactPhone(number);
        }
        else {
            setrecipientPhone(number);
        } 
    }

    const handleFormValidation = (step) => {

        // Force shopping cart view before item request submission
        if (tabValue != 3 && step == 2) {
            setTabValue(3);
            return;
        } 
        console.log(`Step is ${step}`)
        var isValid = validate(step);
        if(isValid == true) {
            handleNext();
        }
    }
    
    const Step1 = () => {
        return (
            <div className={classes.root}>
                <Typography variant="h4" className={classes.stepTitle}>Recipient Details</Typography>
                <form>
                <div className={`${classes.formRoot} ${classes.step1Root}`}>                
                    <TextField 
                        onChange={(e) => setrecipientFirstName(e.target.value)}
                        id="recipient-name"
                        label="First Name"
                        variant="outlined"
                        required
                        data-test="recipient-firstName"
                        error={!!formValidation.errorMessage.firstName}
                        // errorText="error text"
                        // helperText=""
                        // hintText="Password"
                        // floatingLabelText="Password"
                        // type="password"
                        helperText={
                            formValidation.errorMessage.firstName &&
                            formValidation.errorMessage.firstName
                        }
                        className={`${classes.TextField} ${classes.halfWidth}`}
                    />
                    <TextField 
                        onChange={(e) => setrecipientLastName(e.target.value)}
                        id="recipient-name"
                        label="Last Name"
                        variant="outlined"
                        required
                        data-test="recipient-lastName"
                        error={!!formValidation.errorMessage.lastName}                       
                        helperText={
                            formValidation.errorMessage.lastName &&
                            formValidation.errorMessage.lastName
                        }
                        className={`${classes.TextField} ${classes.halfWidth}`}
                    />
                    {/* </FormControl> */}        
                   
                    <TextField 
                        onChange={handleChangePhoneNumber}
                        id="recipient-number" 
                        label="Phone Number"
                        variant="outlined" 
                        className={`${classes.TextField} ${classes.thirdWidth}`}
                        type="tel"
                        required
                        data-test="recipient-phone"
                        error={!!formValidation.errorMessage.phoneNumber}                       
                        helperText={
                            formValidation.errorMessage.phoneNumber &&
                            formValidation.errorMessage.phoneNumber
                        }
                        value={recipientPhone}
                    />
                    <FormControl variant="outlined" className={`${classes.TextField} ${classes.thirdWidth}`}>
                        <InputLabel htmlFor="outlined-age-native-simple">Type</InputLabel>
                        <Select
                        native
                        // value={state.age}
                        onChange={(e) => setphoneType(e.target.value)}
                        label="Phone Number Type"
                        inputProps={{
                            name: 'age',
                            id: 'outlined-age-native-simple',
                        }}
                        >
                        <option aria-label="None" value="" />
                        <option value="landline">Landline</option>
                        <option value="mobile">Mobile</option>
                        </Select>
                    </FormControl>
                    <TextField
                            onChange={(e) => setrecipientEmail(e.target.value)}
                            id="recipient-email" 
                            label="Email"
                            variant="outlined"
                            type="email"
                            required
                            data-test="recipient-email"
                            className={`${classes.TextField} ${classes.thirdWidth}`}
                            error={!!formValidation.errorMessage.email}                       
                            helperText={
                                formValidation.errorMessage.email &&
                                formValidation.errorMessage.email
                            }
                        />
                    {/* </FormControl> */}
                    {/* <FormControl> */}
                        <TextField 
                            onChange={(e) => setrecipientAddress1(e.target.value)}
                            id="recipient-addrl1" 
                            label="Address Line 1"
                            variant="outlined"
                            data-test="recipient-addr1"
                            className={classes.TextField}
                            fullWidth
                        />
                    {/* </FormControl> */}
                    {/* <FormControl> */}
                        <TextField 
                            onChange={(e) => setrecipientAddress2(e.target.value)}
                            id="recipient-addrl2" 
                            label="Address Line 2"
                            variant="outlined"                          
                            className={classes.TextField}
                            fullWidth
                        />
                    {/* </FormControl> */}
                    {/* <FormControl> */}
                        <TextField 
                            onChange={(e) => setrecipientCity(e.target.value)}
                            id="recipient-city" 
                            label="City"
                            variant="outlined"         
                            data-test="recipient-city"                  
                            className={classes.TextField}
                        />
                    {/* </FormControl> */}
                    {/* <FormControl> */}
                        <TextField
                            onChange={(e) => setrecipientState(e.target.value)}
                            id="recipient-state"
                            label="State(Abbreviated)"
                            variant="outlined"
                            data-test="recipient-state"
                            className={classes.TextField}
                        />
                    {/* </FormControl> */}
                    {/* <FormControl> */}
                        <TextField 
                            onChange={(e) => setrecipientZip(e.target.value)}
                            id="recipient-zip" 
                            label="Zip Code"
                            variant="outlined" 
                            data-test="recipient-zip"
                            className={classes.TextField}
                        />
                        
                    <FormControl variant="outlined" className={`${classes.TextField} ${classes.prefCommunication}`}>
                        <InputLabel htmlFor="outlined-age-native-simple">Communication</InputLabel>
                        <Select
                        native
                        // value={state.age}
                        onChange={(e) => setpreferredCommunication(e.target.value)}
                        label="Phone Number Type"
                        // inputProps={{
                        //     name: 'age',
                        //     id: 'outlined-age-native-simple',
                        // }}
                        >
                        <option aria-label="None" value="" />
                        <option value="Phone">Phone</option>
                        <option value="Email">Email</option>
                        </Select>
                    </FormControl>
                    {/* </FormControl> */}
                    <FormControl>
                        <TextField 
                            className={classes.TextField}
                            onChange={(e) => setrecipientBio(e.target.value)}
                            id="recipient-bio"
                            label="Recipient Bio"
                            helperText="Write a short, 2-3 sentence (20-30 word) biography of the family telling who they are and a short description of what their current situation is."
                            variant="outlined"
                            multiline
                            fullWidth
                            required
                            rows={4}
                            data-test="recipient-bio"
                            error={!!formValidation.errorMessage.bio}                       
                            // helperText={
                            //     formValidation.errorMessage.bio &&
                            //     formValidation.errorMessage.bio
                            // }
                        />
                    </FormControl>
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={alternateContactCheckbox}
                            onChange={handleChangeAlternateContactCheckbox}
                            name="alternateContactCheckbox"
                            color="primary"
                        />
                        }
                        label="Check this box if you would like to enter an alternate contact for the family"
                    />         
                    </div>    

                    <Button variant="contained" onClick={handleBack} className={classes.button} color="primary">
                            Back
                    </Button>
                    <Button
                        variant="contained"
                        // color="primary"                        
                        onClick={() => handleFormValidation(1)}
                        // type="submit"
                        className={`${classes.button} ${classes.greenButton}`}
                    >
                        Next
                    </Button>
                </form>
            </div>
        )
    }

    const AlternateContact = () => {
        return (
            <div className={classes.root}>
                <Typography variant="h4" className={classes.stepTitle}>Alternate Contact Details</Typography>
                <form className={`${classes.formRoot} ${classes.step1Root}`} onSubmit={handleNext}>
                    {/* <FormControl> */}
                        <TextField 
                            onChange={(e) => setalternateContactFirstName(e.target.value)}
                            id="alt-name"
                            label="First Name"
                            variant="outlined"
                            required
                            className={`${classes.TextField} ${classes.halfWidth}`}
                            value={alternateContactFirstName}
                        />
                        <TextField 
                            onChange={(e) => setalternateContactLastName(e.target.value)}
                            id="alt-last-name"
                            label="Last Name"
                            variant="outlined"
                            required
                            className={`${classes.TextField} ${classes.halfWidth}`}
                            value={alternateContactLastName}
                        />
                    {/* </FormControl> */}        
                   
                    <TextField 
                        onChange={handleChangePhoneNumber}
                        id="alt-number" 
                        label="Phone Number"
                        variant="outlined" 
                        required
                        className={`${classes.TextField} ${classes.thirdWidth}`}
                        type="tel"
                        value={alternateContactPhone}
                    />
                    <FormControl variant="outlined" className={`${classes.TextField} ${classes.thirdWidth}`}>
                        <InputLabel htmlFor="alt-phone-type">Type</InputLabel>
                        <Select
                        native
                        // value={state.age}
                        onChange={(e) => setalternateContactPhoneType(e.target.value)}
                        label="Phone Number Type"
                        inputProps={{
                            name: 'alt phone type',
                            id: 'alt-phone-type',
                        }}
                        >
                        <option aria-label="None" value="" />
                        <option value="landline">Landline</option>
                        <option value="mobile">Mobile</option>
                        </Select>
                    </FormControl>
                    <TextField
                            onChange={(e) => setalternateContactEmail(e.target.value)}
                            id="alt-email" 
                            label="Email"
                            variant="outlined"
                            required
                            className={`${classes.TextField} ${classes.thirdWidth}`}
                            value={alternateContactEmail}
                        />
                    {/* </FormControl> */}
                    {/* <FormControl> */}
                        <TextField 
                            onChange={(e) => setalternateContactAddress1(e.target.value)}
                            id="alt-addrl1" 
                            label="Address Line 1"
                            variant="outlined"
                            className={classes.TextField}
                            fullWidth
                            value={alternateContactAddress1}
                        />
                    {/* </FormControl> */}
                    {/* <FormControl> */}
                        <TextField 
                            onChange={(e) => setalternateContactAddress2(e.target.value)}
                            id="alt-addrl2" 
                            label="Address Line 2"
                            variant="outlined"                          
                            className={classes.TextField}
                            fullWidth
                            value={alternateContactAddress2}
                        />
                    {/* </FormControl> */}
                    {/* <FormControl> */}
                        <TextField 
                            onChange={(e) => setalternateContactCity(e.target.value)}
                            id="recipient-city" 
                            label="City"
                            variant="outlined"                           
                            className={classes.TextField}
                            value={alternateContactCity}
                        />
                    {/* </FormControl> */}
                    {/* <FormControl> */}
                        <TextField
                            onChange={(e) => setalternateContactState(e.target.value)}
                            id="alt-state"
                            label="State(Abbreviated)"
                            variant="outlined"
                            className={classes.TextField}
                            value={alternateContactState}
                        />
                    {/* </FormControl> */}
                    {/* <FormControl> */}
                        <TextField 
                            onChange={(e) => setalternateContactZip(e.target.value)}
                            id="alt-zip" 
                            label="Zip Code"
                            variant="outlined" 
                            className={classes.TextField}
                            value={alternateContactZip}
                        />
                    {/* </FormControl> */}
                    {/* <FormControl variant="outlined" className={`${classes.TextField}`}> */}
                    <FormControl variant="outlined" className={`${classes.TextField} ${classes.prefCommunication}`}>
                        <InputLabel htmlFor="outlined-age-native-simple">Pref. Communication</InputLabel>
                        <Select
                        native
                        // value={state.age}
                        onChange={(e) => setalternateContactpreferredCommunication(e.target.value)}
                        label="Phone Number Type"                       
                        >
                        <option aria-label="None" value="" />
                        <option value="Phone">Phone</option>
                        <option value="Email">Email</option>
                        </Select>
                    </FormControl>
                    <Button variant="contained" onClick={handleBack} className={classes.button} color="primary">
                            Back
                    </Button>
                    <Button
                        variant="contained"
                        // color="primary"                        
                        onClick={() => handleFormValidation(1)}
                        // type="submit"
                        className={`${classes.button} ${classes.greenButton}`}
                    >
                        Next
                    </Button>
                </form>
            </div>
        )
    }

    // return the subcategory based on the index
    const displaySubcategory = (index) => {
        if (index == 0) {
            return (<Typography className={classes.subCategory}>Tableware</Typography>);
        }
        else if (index == 4) {
            return (
                <>
                <Divider variant="middle" />
                <Typography className={classes.subCategory}>Glassware & Drinkware</Typography>
                </>
            );
        }
        else if (index == 10) {
            return (<Typography className={classes.subCategory}>Cookware</Typography>);
        }
        else if (index == 19) {
            return (<Typography className={classes.subCategory}>Cooking Utensils</Typography>);
        }
        else if (index == 4) {
            return (<Typography className={classes.subCategory}>Glassware & Drinkware</Typography>);
        }
    }
    const Step2 = () => {
        return (
            <div className={classes.root}>
                <Typography variant="h4" className={classes.stepTitle}>Select Items Needed</Typography>
                {/* <Typography >Select items from the categories below: </Typography> */}
                {
                    tabValue == 3 ? 
                        <p>Please review your selected items now, and make any necessary changes before hitting Submit below.</p>
                        :
                        <>
                            <p><strong>Disclaimer: </strong>Images are examples of the items available to request, and are not necessarily the exact item that will be provided.</p>
                        </>
                }
                <Paper>
                    <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                        // variant="scrollable"
                        scrollButtons="on"
                    >
                        <Tab label="Kitchen" />
                        <Tab label="Bedroom" />
                        <Tab label="Bathroom" />
                        <Tab icon={
                             <Badge color="secondary" badgeContent={itemsRequested ? Object.keys(itemsRequested).length : 0}>
                                <ShoppingCartIcon />
                            </Badge>
                        } aria-label="shopping cart" />
                    </Tabs>
                </Paper>                
                {
                    tabValue == 3 ? 
                        <CartSummary itemsRequested={itemsRequested} summaryHeading="Item Request Summary" />
                    :
                        <form className={classes.formRoot}>
                        <Grid container spacing={4} className="itemGrid">

                        {
                            items.items.map((item, index) => (
                                (checkFilter(item) == false) ? null :
                                <>
                                    {displaySubcategory(index)}
                                    <Grid item xs={12} sm={6} lg={4}>
                                        <Card sx={{ maxWidth: 345 }} className="itemCard">
                                            <CardHeader              
                                                title={itemsObjects.items[0][item].name}
                                                subheader={convertCase(item)}
                                            />
                                            <CardMedia
                                                component="img"
                                                alt={item.slice(0,-1)}
                                                height="200"
                                                image={itemsObjects.items[0][item].img}
                                                className="itemCardProductImage"
                                            />
                                            <CardContent className="itemCardDescription" style={{overflow: "visible",}}>                                               
                                                <Typography variant="body2" color="text.secondary">
                                                    {itemsObjects.items[0][item].description}
                                                </Typography>
                                            </CardContent>
                                            <CardActions className="itemCardAction">
                                                <FormControl key={index} className={classes.FormControl}>
                                                    <InputLabel id={item}>Qty</InputLabel>
                                                    <Select
                                                        name={item}
                                                        value={itemsRequested[item]}
                                                        onChange={handleChangeStep2}
                                                        data-test={"qty-"+index}
                                                        // autoWidth
                                                    >
                                                
                                                    <MenuItem value={0}>0</MenuItem>
                                                    <MenuItem value={1}>1</MenuItem>
                                                    <MenuItem value={2}>2</MenuItem>
                                                    <MenuItem value={3} data-test="qty-option-3">3</MenuItem>
                                                    <MenuItem value={4}>4</MenuItem>
                                                    <MenuItem value={5}>5</MenuItem>
                                                    <MenuItem value={6}>6</MenuItem>
                                                    </Select>
                                                </FormControl>                                           
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                </>           
                            ))
                        }
                        </Grid>                       
                        </form>
                }
                <Button variant="contained" onClick={handleBack} className={classes.button} color="primary">
                    Back
                </Button>
                <Button
                    variant="contained"
                    // color="primary"                        
                    onClick={() => handleFormValidation(2)}
                    // type="submit"
                    data-test="item-req-next"
                    className={`${classes.button} ${classes.greenButton}`}
                >
                    {tabValue == 3 ? "Submit" : "Next"}                    
                </Button>
            </div>
        )
    }

    function getStepContent(step) {
        // alert(`step is ${step}`);
       if (step == 0) {
           console.log(approvedNonProfitList);           
           return Step0();
        // return <Step0 />
       } else if (step == 1) {
           return Step1();
       } else if (step == 2) {
           return AlternateContact();
        } else if (step == 3) {
            return Step2();
        }
    }

    if (step == 0 && !myContext.nonProfitOrgs) {
        return <Loading />
    } else {
        return (
            <>
               {getStepContent(step)}
            </>
        )    
    }
}

export default RecipientForm
