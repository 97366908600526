// Home page containing gallery view of all the families
import React, { useState, useEffect, useContext } from 'react'
import { Grid, Card, CardContent, Typography, CardActions, Button, FormControl, Select, Checkbox, FormControlLabel, FormGroup, Paper, Tabs, Tab, Badge, InputLabel, MenuItem, TextField, Chip } from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import './DonationHome.css';
import { handleDonation } from '../../handleDonation.js';
import {
    BrowserRouter as Router,
    Link,
    useHistory
  } from "react-router-dom";
import { CheckBox } from '@material-ui/icons';
import DallasSkyline3 from '../../img/bannerImg.png';
import StockImg from '../../img/banner-image.png';
import * as items_global from '../../items';
import { MyStepper, BasicSummary } from '..';
import * as itemsGlobal from '../../items';
import CartSummary from '../Recipient Request/CartSummary';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AppContext from '../AppContext';
import { useAuth0 } from '@auth0/auth0-react';
import ItemsMatch from '../ItemsMatch/ItemsMatch';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import { toast } from 'react-toastify';


var categories = [ "Kitchen", "Bedroom", "Bathroom"];

var api_call_limiter = 0;



const Home = ({
    // setnpEmail, familyID, deliveryInstructions, deliveryAddress, item_list, 
    npEmailGlobal,
    organizationName
}) => {
    
    const myContext = useContext(AppContext);

    const [items, setItems] = useState([]);                                     // items of current request. Set on modal open
    const [open, setOpen] = useState(false);                                    // State to toggle modal open or close                               
    const [currReq, setcurrReq] = useState({                                    // current request to populate the modal with. Set on modal open
        id: "",
        bio: "Loading...",
        timestamp: ""
    });
    // const [donatedItems, setdonatedItems] = React.useState({});                 // items donate by donor. Set on dropdown change
    const [reqID, setreqID] = useState();                                       // Recipient request ID. Set on modal open
    const [req, setreq] = useState(require('../../requests.json').requests);    // object containing all requests. Set on component mount
    var itemList = [];
    var categoryList = [];
    const [modalState, setmodalState] = useState(0);                            // Stage of modal (0 is initial stage, 1 is summary stage)
    const [deliveryInsructions, setdeliveryInsructions] = useState('');         // Delivery instructions for current family
    const [deliveryAddr, setdeliveryAddr] = useState('');
    // const [npEmail, setnpEmail] = useState('tim@trustedworld.org');
    var kitchenFlag=false;                                                          // Flag to check if an item category has been printed on the donation page yet
    var bedroomFlag=false;                                                          // Flag to check if an item category has been printed on the donation page yet
    var bathroomFlag =false;                                                        // Flag to check if an item category has been printed on the donation page yet
    const [tabValue, setTabValue] = useState(0);
    const [currentCategory, setcurrentCategory] = useState('Kitchen');
    const [organizationFilter, setorganizationFilter] = useState(window.sessionStorage.getItem('visitedOrganization'));
    // const [organizationFilter, setorganizationFilter] = useState('Helping Hands');
    var nonProfitOrgsData;
    const [approvedNonProfitList, setapprovedNonProfitList] = useState();

    const [filter, setFilter] = React.useState({
        Kitchen: true,
        Bedroom: true,
        Bathroom: true,
    });

    const { user } = useAuth0();

    let history = useHistory();
    const [itemFilterValues, setitemFilterValues] = useState([]);

    const handleFilterChange = (event) => {
        setFilter({ ...filter, [event.target.name]: event.target.checked });
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        if (newValue == 0) setcurrentCategory("Kitchen")
        else if (newValue == 1) setcurrentCategory("Bedroom")
        else if (newValue == 2) setcurrentCategory("Bathroom")
    }

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        // alert('In home useEffect');
        console.log("Setting donorDetails state");

        console.log(`api_call_limiter is: ${api_call_limiter}`);

        if(api_call_limiter<=1) {
            var org = window.sessionStorage.getItem('visitedOrganization');
            if (org != null) {
                // alert(`org is ${org}`);
                setorganizationFilter(org);
            }

            console.log("Fetching from api");
            let url = process.env.REACT_APP_SHEETY_API_GET;
            fetch(url)
            .then((response) => response.json())
            .then(json => {
            // Do something with the data
                
                setreq(json.requests);

                // Update the prop with the requests
                if (myContext.setrequests) {
                    console.log("Going to set requests in DonationHome useEffect");
                    console.log(myContext);
                    myContext.setrequests(json.requests);
                }
                console.log(req);
            });

             // alert('Making API call');
             var npList = [];          
             let url2 = 'https://api.sheety.co/591c67f7e588d2a49e1cf55d260ce8a5/recipient/nonProfitOrgs';
             fetch(url2)
             .then((response) => response.json())
             .then(json => {
                 // Do something with the data
                 console.log(json.nonProfitOrgs);
                 nonProfitOrgsData = json.nonProfitOrgs;
                 myContext.setnonProfitOrgs(nonProfitOrgsData);
 
                 for (var i=0; i<nonProfitOrgsData.length; i++) {
                     console.log(nonProfitOrgsData[i]['status'])
                     
                     if (nonProfitOrgsData[i]['status'] == "Approved") {
                         npList.push(nonProfitOrgsData[i]['name'])
                     }
                 }
                 console.log(npList);
                //  console.log("Setting approved np list");
                //  setapprovedNonProfitList(npList);
                //  console.log(approvedNonProfitList);      
             });
        }
        api_call_limiter++;
        console.log("Setting approved np list");
        setapprovedNonProfitList(npList);
        console.log(approvedNonProfitList);      
    });

    // Get & set list of items given a request object
    function getItems(obj, flag) {
        console.log("Getting list");
        var list = '';

        Object.keys(obj).forEach(function(key) {
            // console.log('Key : ' + key + ', Value : ' + obj[key]);
            if (items_global.items.includes(key) && (obj[key] != '') && (obj[key] != 0)) {
                list = list + ' ' + obj[key] + ' ' + key + ',';
            }
        });
        
        // Set the categories to show on each family card
        setCategories(obj);

        // Remove the last comma            
        list = list.slice(0,-1);
        console.log(list);
        // Set the list state
        if(flag == 0) {
            setItems(list);
            myContext.setItemsNeeded(list);
        }
        return list;
    }

    // Set the global variable categoryList to a list of categories based on the items that are present in object obj
    function setCategories(obj) {
        
        // Reset the global categoryList array
        categoryList = [];

        Object.keys(obj).forEach(function(key) {
            // console.log('Key : ' + key + ', Value : ' + obj[key]);
            if (items_global.items.includes(key) && (obj[key] != '') && (obj[key] != 0)) {
                console.log(`key is ${key}`);
                // if the current key is an item, check it's index in items_global[]
                var index = items_global.items.indexOf(key);
                console.log(`Index of ${key} is ${index}`);

                // Set the category based on the index range of the current key in array items_global[]
                if (index <= 30) {
                    // Kitchen
                   if (categoryList.indexOf('Kitchen ') == -1) {
                       categoryList.push('Kitchen ');
                   } 
                } else if (index > 30 && index <= 38) {
                    // Bedroom
                    if (categoryList.indexOf('Bedroom ') == -1) {
                        categoryList.push('Bedroom ');
                    } 
                } else if (index > 38) {
                    // Bathroom
                    if (categoryList.indexOf('Bathroom ') == -1) {
                        categoryList.push('Bathroom ');
                    } 
                }
            }
        });

        // Remove the last comma in the category list
        // categoryList = categoryList[categoryList.length - 1].slice(0,-1);
        // categoryList.push('items needed');
    }
   
    const CustomToastWithLink = () => (
        <div>
            Please update your name in the profile section before making a donation or adoption!
            <br />
            <br />
            <Button href={"/profile/"+user.sub} variant='contained'
                style={{
                    color: 'inherit',
                    textTransform: 'none',
                }}>
                Go to Profile
            </Button>
            {/* (<Link to={"/profile/"+user.sub}
                style={{
                    color: 'inherit',
                }}
                >Go to Profile</Link>) */}
        </div>
    );
    
    // Handle the opening of the modal
    const handleOpen = (obj, pageType) => {
        console.log(obj);
        if (!obj) return;

        // alert(window.sessionStorage.getItem('profileUpdateFlag'));
        // alert(!window.sessionStorage.getItem('profileUpdateFlag'));
        // alert((user['https://example.com/first_name'] == '' || user['https://example.com/last_name'] == ''));

        if ((user['https://example.com/first_name'] == '' || user['https://example.com/last_name'] == '')
        && !window.sessionStorage.getItem('profileUpdateFlag')) {
            // window.localStorage.setItem('profileCompleteRedirect', pageType);
            window.localStorage.setItem('profileCompleteRedirectURL', pageType + '/' + obj.shortId);
            // alert('Please update your name in the profile section before making a donation or adoption!');
            toast.error(
                'Please update your name in the profile section before making a donation or adoption! (redirecting you now)', 
                // `<a href=\'http://localhost:300/profile/\'`,
                // CustomToastWithLink,
                {
                    position: "top-right",
                    autoClose: 5000,
                    // hideProgressBar: true,
                    closeOnClick: true,
                    // pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
            });
            window.setTimeout(function() {
                window.location.replace('/profile/'+user.sub);
            }, 5500)
            return;
        }

        if (pageType == 'adopt') {
            history.push('/adopt/' + obj.shortId);
            return;
        }

        // Set the current request object
        setcurrReq(obj);

        // set the item list for the current object
        getItems(obj, 0);

        // set the id for the current object
        // var id = obj.id + '-' + obj.timestamp.split(' ')[0].split('/').join("") + '-' + obj.timestamp.split(' ')[1].split(':').join("")
        setreqID(obj.reqId);

        // set the delivery address and instructions for the currently opened family modal
        // if (obj.nonProfitName == 'Trusted World') {
        //     setdeliveryInsructions('Deliver between 9am-5pm Monday-Friday');
        //     setdeliveryAddress('Trusted World, 613b Easy St, Garland, TX 75042');
        //     setdeliveryAddr('Trusted World, 613b Easy St, Garland, TX 75042');
        // } else {
        //     setdeliveryInsructions('Deliver monday-friday');
        //     setdeliveryAddress('1891 Forest Ln, Garland, TX 75042');
        //     setdeliveryAddr('1891 Forest Ln, Garland, TX 75042');
        // }
        setdeliveryInsructions(obj.deliveryInstructions);
        if(myContext.setdeliveryAddress) {
            myContext.setdeliveryAddress(obj.preferredDeliveryLocation);
        }
        setdeliveryAddr(obj.preferredDeliveryLocation);

        // set the non profit email for the currently selected family
        if (myContext.setnpEmail){
            myContext.setnpEmail(obj.nonProfitEmail);
        }

        setOpen(true);

        sessionStorage.setItem('makeAnotherDonationURL', "/donations");

        if (pageType == 'donation') {
            history.push('/donations/' + obj.shortId);
        }
        else if (pageType == 'adopt') {
            alert(`object shortID is: ${obj.shortId}`)
            history.push('/adopt/' + obj.shortId);
        }
    };
    // Handle the closing of the modal
    const handleClose = () => {
        console.log('Going back');
        setOpen(false);
        window.location.reload();
        setmodalState(0);
    };

    // Handler function to handle donation submissions
    const handleChange = (event) => {
        console.log("Handling donation...");
        const name = event.target.name;
        console.log(name);
        console.log(event.target.value);
        if (myContext.setdonatedItems) {
            console.log('Setting donated items...');
            myContext.setdonatedItems({
                ...myContext.donatedItems,
                [name]: event.target.value,
            });
        }
    };

    // function to convert item name from camelcase to normal word (Eg: pillowCases(standard) -> pillow cases(standard)) 
    function convertCase(item) {
        var gapi_key;

        if(/[A-Z]/.test(item)) {
            // split word at capital letter
            var str_array = item.split(/(?=[A-Z])/);
            gapi_key = str_array.join(' ');
            gapi_key = gapi_key.toLowerCase();
        } else {
            return item;
        }
        
        console.log(`converted ${item} to `);
        return gapi_key;
    }
    
     // return the subcategory based on the index
     const displaySubcategory = (itemName) => {
        var index = itemsGlobal.items.indexOf(itemName);

        console.log(`${itemName} index is ${index}`);

        if (index <= 30 && !kitchenFlag) {
            kitchenFlag = true;
            return (
                <div>
                    <Typography className="itemCategory">Kitchen Items</Typography>
                </div>
            );
        }
        else if ((index > 30 && index <= 38) && !bedroomFlag) {
            bedroomFlag = true;
            return (
                <>
                {/* <Divider variant="middle" /> */}
                <Typography className="itemCategory">Bedroom Items</Typography>
                </>
            );
        }
        else if (index > 38 && !bathroomFlag) {
            bathroomFlag = true;
            return (<Typography className="itemCategory">Bathroom Items</Typography>);
        }
    }    

     // This function takes in an item(string) and checks if the item cetegory filter is checked to true
    // If there is a match, we return true. Else, we return false
    function checkTabFilter(item) {
        
        if(items_global.items.indexOf(item) <= 30 && currentCategory=="Kitchen") {
            console.log(`${item} is a Kitchen item`);
            return true;
        } else if((items_global.items.indexOf(item) <= 38 && items_global.items.indexOf(item) > 30 )  && currentCategory=="Bedroom") {
            console.log(`${item} is a Bedroom item`);
            return true;
        } else if(items_global.items.indexOf(item) > 38  && currentCategory=="Bathroom") {
            console.log(`${item} is a Bathroom item`);
            return true;
        }

        return false;
    }

    const itemCategoryTabs = (items) => {
        if (!items) {
            alert("Items not initialised");
            return(
                <div>Loading...</div>
            );
        }
        else if (items) {
            var str = JSON.stringify(items);
            str = str.split(" ")
            console.log("str is...");
            str.shift();
            console.log(str);
        
            var itemQty = [];

            for(let i=0; i<str.length-1; i=i+2) {
                itemQty.push(parseInt(str[i]));
                itemList.push(str[i+1]);
            }
        
            return (
                <div>
                    <Paper>
                        <Tabs
                            value={tabValue}
                            onChange={handleTabChange}
                            indicatorColor="primary"
                            textColor="primary"
                            centered
                            // variant="scrollable"
                            // scrollButtons="on"
                        >
                            <Tab label="Kitchen" />
                            <Tab label="Bedroom" />
                            <Tab label="Bathroom" />
                            <Tab icon={
                                    <Badge color="secondary" badgeContent={myContext.donatedItems ? Object.keys(myContext.donatedItems).length : 0}>
                                    <ShoppingCartIcon />
                                </Badge>
                            } aria-label="shopping cart" />
                        </Tabs>
                    </Paper>
                    {
                        tabValue == 3 ? 
                            <CartSummary itemsRequested={myContext.donatedItems} />
                        :
                            <form 
                            // className={classes.formRoot}
                            >
                            {
                                itemList.map((item, index) => (
                                    (checkTabFilter(item.slice(0,-1)) == false) ? null :
                                    <>
                                        {displaySubcategory(item.slice(0, -1))}
                                        <FormControl key={index}
                                            // className={classes.FormControl}
                                            className="formControl"
                                        >
                                            <InputLabel id={item}>{convertCase(item.slice(0,-1))}</InputLabel>
                                            <Select
                                                name={item.slice(0,-1)}
                                                // value={(!donatedItems || !donatedItems[item.slice(0,-1)]) ? '' : donatedItems[item.slice(0,-1)]}
                                                value={(!myContext.donatedItems) ? '' : myContext.donatedItems[item.slice(0,-1)]}
                                                // value={donatedItems[item.slice(0,-1)]}
                                                onChange={handleChange}
                                                // autoWidth
                                                className="dropdown-item"
                                            >
                                                {/* <option aria-label="None" value="" />
                                                {Array(itemQty[index]+1).fill(0).map((value, i) => (
                                                    <option key={i} value={i}>{i}</option>
                                                ))} */}
                                                <MenuItem aria-label="None" value={0}></MenuItem>
                                                {Array(itemQty[index]+1).fill(0).map((value, i) => (
                                                    <MenuItem key={i} value={i}>{i}</MenuItem>
                                                ))}
                                        
                                                {/* <MenuItem value={0}>0</MenuItem>
                                                <MenuItem value={1}>1</MenuItem>
                                                <MenuItem value={2}>2</MenuItem>
                                                <MenuItem value={3}>3</MenuItem>
                                                <MenuItem value={4}>4</MenuItem>
                                                <MenuItem value={5}>5</MenuItem>
                                                <MenuItem value={6}>6</MenuItem> */}
                                            </Select>
                                        </FormControl>   
                                    </>           
                                ))
                            }
                            </form>
                    }
                </div>
            )
        }
    }

    async function makeDonation() {
        console.log(`DonationHome, calling handleDonation with user email: ${user.email}`);

        // alert(`Calling handleDonation. Deliver to ${deliveryAddr}`);
        toast.promise(
            await handleDonation(myContext.donatedItems, reqID, itemList, deliveryInsructions, deliveryAddr, npEmailGlobal, user.email),            
            {
                pending: 'Processing Donation',
                success: `Donation Successful 👌`,
                error: 'There was an error processing the donation 🤯'
            }
        );
        // Update global state variables with current donation
        if (myContext.setfamilyID) {
            myContext.setfamilyID(reqID);
        }
        
        // alert(`Setting delivery instructions to: ${deliveryInsructions}`);
        if (myContext.setdeliveryInstructions) {
            myContext.setdeliveryInstructions(deliveryInsructions);
        }
        // setdeliveryAddress(deliveryAddress);

        setmodalState(1);
        // alert("Items have been donated...");
    }

    function makeDonationTest() {
        console.log("In makeDonationTest");
        console.log(user.email);
    }
    
    // Body structure of the lightbox/modal
    const modalInitial = (
        <>
        <img className="banner-image" src={DallasSkyline3} />
        <div className="modal-body">
            <MyStepper activeStep={1} />
            <Typography variant="h5">Family background</Typography>
            <p className="modal-bio">{currReq.bio}</p>
            {/* <p>
                <strong>Item request placed by: </strong>
                {npEmail}
            </p> */}

            <Typography className="donationInstructionsText">Choose the items that you’d like to donate from the requested items. If you can’t donate all of them, that’s okay! Just select the items that you have available to donate.</Typography>

            {/* Display dropdown based on items */}
            {/* {dropdownMenu(items)} */}
            {itemCategoryTabs(items)}
            <div className="modal-buttons-container">
                <Button
                    // fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => handleClose()}
                >
                    Back
                </Button>
                <Button className="donate-modal" onClick={() => makeDonationTest()}>
                    Donate
                </Button>
            </div>
        </div>
        </>
    );

    const modalOnDonation = (
        <div className="modal-body">
        <MyStepper activeStep={2} />
            <div className="noPrint">
                <h1>Thank you for your donation!</h1>
                <h3>See below for further instructions</h3>               
            </div>

            {/* <Link to={"/summary"}> */}
                {/* <Button variant="outlined" color="primary" onClick={() => {
                    history.push('/summary');
                }}>
                    Donation Next Steps
                </Button> */}
                <BasicSummary familyID={myContext.familyID} deliveryInstructions={myContext.deliveryInstructions} deliveryAddress={myContext.deliveryAddress} item_list={myContext.donatedItems} npEmail={npEmailGlobal}/>
            {/* </Link> */}
            {/* <BasicSummary familyID={reqID} deliveryInstructions={deliveryInsructions} item_list={donatedItems}/> */}
        </div>
    );

    // This function takes in a space seperated category list and checks if any of the checked categories matched
    // If there is a match, we return true. Else, we return false
    function checkFilter(categoryList) {
        console.log(categoryList);
        for (let i=0; i<categoryList.length; i++) {
            if(categoryList[i].includes("Kitchen") && filter.Kitchen==true) {
                return true;
            } else if(categoryList[i].includes("Bedroom") && filter.Bedroom==true) {
                return true;
            } else if(categoryList[i].includes("Bathroom") && filter.Bathroom==true) {
                return true;
            }
        }

        return false;
    }

    // Check if the passed request contains any of the items present in the "value" state
    function checkItemSearchFilter(request) {
        console.log("Values: ");
        console.log(itemFilterValues);
        
        if (itemFilterValues.length == 0) return true;

        for (var i=0; i < itemFilterValues.length; i++) {
            if (request[itemFilterValues[i]] != 0 && request[itemFilterValues[i]] != "") {
                return true;
            }
        }

        return false;
    }

    // if modal is open, return modal 
    if (open == true) {
        return (
            modalState==0 ? modalInitial : modalOnDonation
        )
    } else {
        console.log(organizationName)
        // if component is being called from the organization homepage, do not display the banner, instructions card, and steps
        if (organizationName) {
            return (
                <div>
                <MyStepper activeStep={0} />                    
                <Grid container className="filter">
                    {/* <Grid item className="filter-item">
                        <Typography className="bold-text">Filter by Organization: </Typography>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={["Trusted World", "Other"]}
                            sx={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} label="Organization Name" />}
                            onClose={(e) => setorganizationFilter(e.target.innerText)}
                        />
                    </Grid> */}
                    <Grid item className="filter-item">
                        <Typography className="bold-text">Filter by Category: </Typography>
                        <FormGroup row>
                            <FormControlLabel
                                control={
                                <Checkbox
                                    checked={filter.Kitchen}
                                    onChange={handleFilterChange}
                                    name="Kitchen"
                                    color="primary"
                                />
                                }
                                label="Kitchen"
                            />
                            <FormControlLabel
                                control={
                                <Checkbox
                                    checked={filter.Bedroom}
                                    onChange={handleFilterChange}
                                    name="Bedroom"
                                    color="primary"
                                />
                                }
                                label="Bedroom"
                            />
                            <FormControlLabel
                                control={
                                <Checkbox
                                    checked={filter.Bathroom}
                                    onChange={handleFilterChange}
                                    name="Bathroom"
                                    color="primary"
                                />
                                }
                                label="Bathroom"
                            />
                        </FormGroup>                    
                    </Grid>                  
                    {/* <div className="filter-item">
                        <Typography className="bold-text">Filter by Items: </Typography>
                        <ItemsMatch setValues={setitemFilterValues} />
                    </div> */}
                </Grid>
                                
                <Grid container spacing={4} className="grid">
                    { myContext.requests==null?null: myContext.requests.map((value) => (
                        (getItems(value, 1) == '' || checkFilter(categoryList) == false || (organizationFilter && value.nonProfitName != organizationFilter))
                        || organizationName != value['nonProfitName']
                         || checkItemSearchFilter(value)==false 
                         ? null 
                         :
                        <Grid item key={value.id} xs={12} sm={6} md={4} lg={4}>
                            <a className="donation-card-link" onClick={() => handleOpen(value, 'donation')}>
                                <Card className="gridItem">
                                    <CardContent className="card-content">
                                        <Typography className="card-bio" color="textPrimary" style={{fontWeight:"bold"}}>
                                            {value.bio}
                                        </Typography>
                                        {/* <Typography color="textSecondary" className="card-items">
                                            <strong>Categories needed: </strong>
                                            {categoryList}
                                        </Typography> <br /> */}
                                        <Chip 
                                            label={"Request ID: " + value['shortId']} 
                                            style={{marginBottom: "0px"}}                                                 
                                        /> 
                                        <Chip 
                                            label={"Request ID: " + value['shortId']} 
                                            style={{marginBottom: "0px"}}                                                 
                                        /> 
                                    </CardContent>
                                    <CardActions className="card-donate">
                                            <Button size="small" color="primary">
                                                Donate
                                            </Button>
                                    </CardActions>
                                </Card>
                            </a>                           
                        </Grid>
                    ))}
                </Grid>
            </div>
            )
        } else {
            return (
                <div>
                    {/* <img className="banner-image" src={DallasSkyline3} /> */}
                    <img className="banner-image" src={StockImg} />
                    <MyStepper activeStep={0} />
                    <div className="donationHeroContent">
                        <Card className="instructions-card">
                            <CardContent>
                                <Typography color="textPrimary" style={{fontWeight:"bold", textAlign:"center"}}>
                                    Instructions
                                </Typography>
                                <Typography color="textSecondary" className="card-items">
                                    <ul>
                                        <li>Choose a family that you’d like to donate to from the request cards below.</li>
                                        <li>Click “donate” on that family’s card.</li>
                                        <li>Choose the items that you’d like to donate from the requested items. If you can’t donate all of them, that’s okay! Just select the items that you have available to donate.</li>
                                        <li>Submit your donation and print the donation summary. If you aren’t able to print when you submit your donation, you can always save it or access it later from the “Donation History” page.</li>
                                    </ul>
                                </Typography>                           
                            </CardContent>
                        </Card>
                    </div>
                    
                    <Grid container className="filter">
                        <Grid item className="filter-item" xs={12} sm={5}>
                            <Typography className="bold-text">Filter by Organization: </Typography>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={["Trusted World", "Helping Hands", "Family Place", "Purpose Shelter"]}
                                // defaultValue={api_call_limiter <=1 ? window.sessionStorage.getItem('visitedOrganization') : null}
                                defaultValue={window.sessionStorage.getItem('visitedOrganization')}
                                // options={approvedNonProfitList}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label="Organization Name" />}
                                onClose={(e) => setorganizationFilter(e.target.innerText)}
                                onChange={(e) => setorganizationFilter(e.target.innerText)}
                                // onClose={(e) => setorganizationFilter(e.target.innerText || window.sessionStorage.getItem('visitedOrganization'))}
                                // onClose={(e) => console.log(e.target.innerText)}
                            />
                        </Grid>
                        <Grid item className="filter-item" xs={12} sm={5}>
                            <Typography className="bold-text">Filter by Category: </Typography>
                            <FormGroup row>
                                <FormControlLabel
                                    control={
                                    <Checkbox
                                        checked={filter.Kitchen}
                                        onChange={handleFilterChange}
                                        name="Kitchen"
                                        color="primary"
                                    />
                                    }
                                    label="Kitchen"
                                />
                                <FormControlLabel
                                    control={
                                    <Checkbox
                                        checked={filter.Bedroom}
                                        onChange={handleFilterChange}
                                        name="Bedroom"
                                        color="primary"
                                    />
                                    }
                                    label="Bedroom"
                                />
                                <FormControlLabel
                                    control={
                                    <Checkbox
                                        checked={filter.Bathroom}
                                        onChange={handleFilterChange}
                                        name="Bathroom"
                                        color="primary"
                                    />
                                    }
                                    label="Bathroom"
                                />
                            </FormGroup>
                        </Grid>
                        {/* <div className="filter-item">
                            <Typography className="bold-text">Filter by Items: </Typography>
                            <ItemsMatch setValues={setitemFilterValues} />
                        </div> */}
                    </Grid>
                                    
                    <Grid container spacing={4} className="grid">
                        { myContext.requests==null?null: myContext.requests.map((value) => (
                            (getItems(value, 1) == '' || checkFilter(categoryList) == false || (organizationFilter && value.nonProfitName != organizationFilter)) || checkItemSearchFilter(value)==false ? null :
                            <Grid item key={value.id} xs={12} sm={6} md={4} lg={4}>
                                <Card className="gridItem">
                                    <CardContent className="card-content">
                                        <Typography className="card-bio" color="textPrimary" style={{fontWeight:"bold"}}>
                                            {value.bio}
                                        </Typography>
                                        {/* <Typography color="textSecondary" className="card-items">
                                            <strong>Categories needed: </strong>
                                            {categoryList}
                                        </Typography> */}
                                        <br />
                                        <Chip 
                                            label={"Request ID: " + value['shortId']} 
                                            style={{marginBottom: "0px"}}
                                            size='medium'                                        
                                        />                                               
                                        <Chip 
                                            label={value['nonProfitName']}
                                            style={{marginBottom: "0px", marginLeft: '0.4rem'}} 
                                            size='medium'                                        
                                            // variant='outlined'                                                
                                        />
                                        <br />   
                                        {
                                            value['adoptionStatus'] == 'Supported' || value['adoptionStatus'] == user.email ?
                                                <Chip 
                                                    size='medium'
                                                    icon={<VolunteerActivismIcon />} 
                                                    label="Open For Adoption" 
                                                    color='primary'
                                                    variant='default'
                                                    style={{
                                                        marginBottom: "0px", 
                                                        // marginLeft: '0.4rem', 
                                                        marginTop:'0.5rem', 
                                                        // display: 'block',
                                                        width: 'fit-content',
                                                    }} 
                                                />
                                            :
                                                null
                                        }                
                                    </CardContent>
                                    <CardActions className="card-donate">
                                        <a className="donation-card-link" onClick={() => handleOpen(value, 'donation')}>
                                            <Button 
                                                size="small" 
                                                color="primary" 
                                            >
                                                Donate
                                            </Button>
                                        </a>       
                                        {
                                            value['adoptionStatus'] == 'Supported' || value['adoptionStatus'] == user.email ?
                                                <a className="donation-card-link" onClick={() => handleOpen(value, 'adopt')}>
                                                    <Button 
                                                        size="small" 
                                                        // color="primary"                                                 
                                                    >
                                                        Adopt Family
                                                    </Button>
                                                </a>                           
                                            :
                                            null
                                        }                    
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </div>
            );
        }
    }
}

export default Home;