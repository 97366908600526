import React, { useState, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {
    useHistory
} from "react-router-dom";
import { GoogleLogin } from 'react-google-login';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0.7, 2),
  },
  loginButtons: {
    textAlign: 'center',
    // display: 'inline-block'
  }
}));

export default function Login () {
  const classes = useStyles();
  const [donorEmail, setdonorEmail] = useState('');
  const [donorName, setdonorName] = useState('');
  let history = useHistory();

    useEffect(() => {
        // alert('In Login useEffect');
    })
  
    // Store user email in local storage on "Login"
    function setLocalStorage() {
        // setdonorLoggedIn(true);
        // alert("Setting session storage..." + donorEmail);
        window.sessionStorage.setItem('donorEmail', donorEmail);
        window.sessionStorage.setItem('donorName', donorName);
        // alert(window.sessionStorage.getItem('donorEmail'));
        history.push("/");
        // {<Redirect to="/home" /> }

    }

    // Handle change of text field
    const handleChange = (event) => {
        console.log('handling change...');
        console.log(event.target.name);
        if (event.target.name == "email") {
            setdonorEmail(event.target.value);
        }
        if (event.target.name == "name") {
            setdonorName(event.target.value);
        }
    }

    const responseGoogleSuccess = (response) => {
        // alert(`In responseGoogleSuccess\n Name: ${response.profileObj.name} \n Email: ${response.profileObj.email}`);
        console.log(response.profileObj.name);
        console.log(response.profileObj.email);
        window.sessionStorage.setItem('donorEmail', response.profileObj.email);
        window.sessionStorage.setItem('donorName', response.profileObj.name);
        // setdonorEmail(response.profileObj.email);
        // history.push("/");
        window.location.reload();
    }      
    const responseGoogleError = (response) => {
        console.log(response);
        alert('Login Failed...');
    } 

  return (
      <>
        <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
            <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
            Enter Donor Details
            </Typography>
            <form className={classes.form} onSubmit={setLocalStorage}>
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={handleChange}
            />
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="name"
                label="Full Name"
                name="name"
                autoComplete="name"
                autoFocus
                onChange={handleChange}
            />
            {/* <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
            />
            <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
            /> */}
            <div className={classes.loginButtons}>              
              <Button
                  type="submit"
                  // fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  // onClick={() => setLocalStorage()}
              >
                  Browse Requests
              </Button>
              <GoogleLogin
                  clientId={process.env.REACT_APP_GOOGLE_LOGIN_CLIENTID}
                  render={renderProps => (
                    // <button onClick={renderProps.onClick} disabled={renderProps.disabled}>This is my custom Google button</button>
                    <Button className={classes.submit} onClick={renderProps.onClick} variant="contained" color="secondary">Login with Google</Button>
                  )}
                  buttonText="Login with Google"
                  onSuccess={responseGoogleSuccess}
                  onFailure={responseGoogleError}
                  cookiePolicy={'single_host_origin'}
                  isSignedIn
                  // className="google-login-button"
              />
            </div>
            {/* <Grid container>
                <Grid item xs>
                <Link href="#" variant="body2">
                    Forgot password?
                </Link>
                </Grid>
                <Grid item>
                <Link href="#" variant="body2">
                    {"Don't have an account? Sign Up"}
                </Link>
                </Grid>
            </Grid> */}
            </form>
        </div>
        {/* <Box mt={8}>
            <Copyright />
        </Box> */}
        </Container>
    </>
  );
}