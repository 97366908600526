import { Button, Typography, Grid } from '@material-ui/core';
import { Email } from '@material-ui/icons';
import React from 'react';
import EmailChips from '../Email Chips/EmailChips';
import { useAuth0 } from '@auth0/auth0-react';

const Referrals = () => {

    const { user } = useAuth0();

    return (
        <div>
            <Grid container justify='center' spacing={4}>
                <Grid item xs={12} style={{textAlign: 'center' }}>
                    <h2>Refer A Friend!</h2>
                    <p>
                        Sending a referral to a friend will allow them to sign up and make donations with NectarCares. 
                        <br /><br />Use the box below to refer 1 or more friends!
                    </p>
                </Grid>
                <Grid item xs={12} >
                    <EmailChips user={user} />
                </Grid>
                {/* <Grid item xs={6} style={{textAlign: 'center' }}>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={() => handleReferral}
                    >
                        Send Referral
                    </Button>
                </Grid> */}
            </Grid>
        </div>
    )
}

export default Referrals
