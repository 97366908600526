import React, { useState, useEffect } from 'react'
import { Typography } from '@material-ui/core';
import {
    BrowserRouter as Router,
    Link,
    useHistory
} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import RecipientHeroImg from '../../img/DallasSkyline3.png';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    button: {
    //   marginRight: theme.spacing(1),
        margin: theme.spacing(2),
    },
    requestSummary: {
    //   marginTop: theme.spacing(1),
    //   marginBottom: theme.spacing(1),
        margin: theme.spacing(2),
    },
    instructions: {
        marginBottom: 10,
    },     
    stepTitle: {
        margin: '10px 20px 10px 26px',
    },
    heroImage: {
        // maxWidth: '100vw',
        // height: '200px',
        width: '100vw'
    },
    errorMessage: {
        padding: 20,
        margin: 'auto',
        display: 'flex',
        maxWidth: '70vw',
        marginTop: '10vh',
    }
}));

const Failure = () => {

    const classes = useStyles();
            
    return (
        <div className={classes.root}>
            <img src={RecipientHeroImg} className={classes.heroImage} />         
            <Typography variant="h5" className={classes.errorMessage}>
                You need admin privileges to access this page. 
                If you are an admin, please log in using your relevant email associated with your organization and try again.
                <br /><br />
                Please contact support if this is a mistake.
            </Typography>            
        </div>
    );
}

export default Failure
