import React from "react";
const loadingImg =
  "https://cdn.auth0.com/blog/auth0-react-sample/assets/loading.svg";

  var styleEl = document.createElement('style');
  styleEl.innerHTML = '.spinner{display:flex; justify-content:center; margin-top:30vh;}';
  document.head.appendChild(styleEl);

  const Loading = () => (
  <div className="spinner">
    <img src={loadingImg} alt="Loading..." />
  </div>
);

export default Loading;