var items = [
// Kitchen items
    // Tableware
        "dessertPlates",
        "dinnerPlates",
        "saladPlates",
        "bowls",                        // 3
    // Glassware and Drinkware
        "mugs",
        "drinkingGlasses",
        "glassCups",
        "plasticCups",
        "travelCups",
        "waterBottles",                // 9
    // Cookware
        // Pots
        "pots(1Qt)",
        "pots(3Qt)",
        "pots(5Qt)",
        "roastingPan",                 // 13
        // Skillet
        "skillet(8In)",
        "skillet(10In)",              // 15
        // Cast Iron Skillet
        "castIronSkillet(8In)",
        "castIronSkillet(10In)",   
        "wok(12In)",                  // 18
    // Cooking Utensils
        "slottedSpoon",
        "spoon",
        "spatula",
        "canOpener",
        "measuringCups",
        "tongs",
        "measuringSpoons",
        "whisk",
        "wineBottleOpener",
        "pizzaCutter",                 // 28
    // Misc.
        "ovenMitts",
        "silverware",                   // 30
// Bedroom items
    // Sheets
    "sheets(twin)",                    
    "sheets(full)",
    "sheets(queen)",
    "sheets(king)",                    // 34
    // Pillow Cases
    "pillowCases(standard)",
    "pillowCases(queen)",
    "pillowCases(king)",
    "pillowCases(body)",              // 38
// Bathroom items
    "washCloths",
    "bathTowels",
    "handTowels",
    "ironingBoard",                    // 42
];

export { items };