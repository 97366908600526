import {  
    Button, Table, TableBody, TableRow, TableCell, TableContainer, Paper, TableHead, Divider,
    Select, InputLabel, MenuItem, Grid, Typography, Box, Tab, Tabs, Card
} from '@material-ui/core';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import EditIcon from '@material-ui/icons/Edit';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AppContext from '../AppContext';
import { useAuth0 } from '@auth0/auth0-react';
import './requestSummary.css';
import * as itemsGlobal from '../../items';
import DonationList from './DonationList';
import axios from 'axios';
import Loading from '../loading';
import TabPanel from '../Tabs/TabPanel';
import CustomizedTimeline from '../Timeline/CustomizedTimeline';
import CustomizedSteppers from '../RequestStatusStepper/RequestStatusStepper';
import { makeStyles } from "@material-ui/core/styles";
import UnstyledTabsCustomized from '../MyTabs/UnstyledTabsCustomized';

var api_call_limiter = 0;

const useStyles = makeStyles((theme) => ({
    tabRoot: {
        display: 'flex',        
        // border: '1px solid black',
        borderBottom: '1px solid black',
        // borderRadius: '4px'
        maxWidth: '80%',
        margin: 'auto'
    }
}))

const RequestSummary = () => {

    const myContext = useContext(AppContext);
    const history = useHistory();
    let { id } = useParams();
    const classes = useStyles();
    
    const [currentRequest, setcurrentRequest] = useState();
    const [loadingState, setLoadingState] = useState();

    // Go through request list and find the one with the relevant reqId
    useEffect(() => {
         // Check if requests object has been loaded. If not, make API call and populate it
         if (api_call_limiter <= 1) {
            if (myContext.requests[0].bio == "Loading...") {
                console.log("Requests not loaded yet... Loading requests...");
                let url = process.env.REACT_APP_SHEETY_API_GET;
                fetch(url)
                .then((response) => response.json())
                .then(json => {
                // Do something with the data
                    // Update the prop with the requests
                    if (myContext.setrequests) {
                        console.log("Going to set requests in RequestHistory useEffect");
                        console.log(myContext);
                        myContext.setrequests(json.requests);
                    }
                });
                api_call_limiter++;
            }
        }
        if (myContext.adoptions[0]['status'] == 'loading') {
            console.log('calling adoptions...');
            let adoptionsURL = 'https://api.sheety.co/591c67f7e588d2a49e1cf55d260ce8a5/recipient/adoptAFamily';
            fetch(adoptionsURL)
            .then((response) => response.json())
            .then(json => {
                // iterate through json.adoptAFamily and search for 'Request ID' matching id
                // alert('setting adoptions in RequestSummary ...');
                console.log('76940:\n');
                console.log(json);
                // console.log(json.adoptAFamily);
                myContext.setAdoptions(json.adoptAFamily);
            });
        }
        if (!myContext.donations) {
            console.log("Setting donations in RequestSummary.jsx");
            let url = process.env.REACT_APP_SHEETY_GET_DONATIONS;
            fetch(url)
            .then((response) => response.json())
            .then(json => {
                // Do something with the data
                console.log("Setting donations in RequestSummary.jsx(2)");
                myContext.setdonations(json.donors);
            });          
        }

        if (!myContext.timeline) {
            // alert("Setting donations in DonationList.jsx");
          let url = process.env.REACT_APP_SHEETY_GET_TIMELINE;
          fetch(url)
          .then((response) => response.json())
          .then(json => {
          // Do something with the data
              console.log("Setting timeline in RequestSummary.jsx(2)");
              myContext.setTimeline(json.timeline);
          });
        }
       
    })


    // function to convert item name from camelcase to normal word (Eg: pillowCases(standard) -> pillow cases(standard)) 
    function convertCase(item) {
        var gapi_key;

        if(/[A-Z]/.test(item)) {
            // split word at capital letter
            var str_array = item.split(/(?=[A-Z])/);
            gapi_key = str_array.join(' ');
            gapi_key = gapi_key.toLowerCase();
        } else {
            return item;
        }
        
        console.log(`converted ${item} to `);
        return gapi_key;
    }
    
    // Get & set list of items given a request object
    function getItems(obj) {
        console.log("Getting list");
        var list = '';

        Object.keys(obj).forEach(function(key, index) {
            console.log(index + '. Key : ' + key + ', Value : ' + obj[key]);
            if (itemsGlobal.items.includes(key)) {
                console.log("Key is an item!")
                if (obj[key] > 0) {
                    console.log("Item quantity is greater than 0...");
                    list = list + ' ' + obj[key] + ' ' + convertCase(key) + ',';
                }
                console.log(list);
            }
            // if (items_global.items.includes(key) && (obj[key] != '') && (obj[key] != 0)) {
            //     list = list + ' ' + obj[key] + ' ' + key + ',';
            // }
        });
        
        console.log(list);

        // Remove the last comma            
        list = list.slice(0,-1);
        console.log(list);

        // Set the list state
        // if(flag == 0) {
        //     setItems(list);
        //     myContext.setItemsNeeded(list);
        // }
        return list;
    }

    function LoadingPage() {
        return (
            <div>
                <p>Loading...</p>                
            </div>
        )
    }

    function handleNotifyFamily (npName, npNumber, npEmail, currentStatus, recipientName, recipientPhoneNumber, altPhoneNumber, recipientEmail, id) {

        // if status has not been set yet, do not proceed with the notification request
        if (currentStatus == 'n/a') {
            alert('Pickup status has not been set yet. Please set the status first to notify the recipient!');
        } else {
            // set loading state 
            setLoadingState(true);

            // send a PATCH request to the /items-pickup endpoint
            axios.post('https://donation-proxy-api.herokuapp.com/sms', {
            // axios.patch('http://localhost:3080/items-pickup', {
                id: id,
                recipientName: recipientName,
                // phone_number: '+12193336690',
                phone_number: recipientPhoneNumber,
                new_status: '/notify',
                // preferredCommunicationMethod: preferredCommunicationMethod,                
                npName: npName,
                npNumber: npNumber,
                npEmail: npEmail,
                currentStatus: currentStatus,
                recipientEmail: recipientEmail,
                currentStatus: currentStatus,          
            })
            .then(function (response) {
                alert("Recipient has been notified of their current status!");
                window.location.reload();
                return response;
            })
            .catch(function (error) {
                console.log(error);
            });    
        }
    }

    const FamilyPickup = (keyGlobal) => {
        const [newStatus, setNewStatus] = useState();
        const [loadingState, setLoadingState] = useState();

        function handlePickupStatusUpdate (preferredCommunicationMethod, id, npName, npNumber, npEmail, recipientEmail, recipientName, recipientPhoneNumber) {
            // if status has not been set yet, do not proceed with the notification request        
           // set loading state 
           setLoadingState(true);
   
           // send a PATCH request to the /items-pickup endpoint
           axios.patch('https://donation-proxy-api.herokuapp.com/items-pickup', {
        //    axios.patch('http://localhost:3080/items-pickup', {
               id: id,
               recipientName: recipientName,
               // phone_number: '+12193336690',
               phone_number: recipientPhoneNumber,
               new_status: newStatus,
               // preferredCommunicationMethod: preferredCommunicationMethod,
               npName: npName,
               npNumber: npNumber,
               npEmail: npEmail,
               recipientEmail: recipientEmail,
           })
           .then(function (response) {
               alert("SMS Sent, status updated!");
               window.location.reload();
               return response;
           })
           .catch(function (error) {
               console.log(error);
           });    
       }

        if (loadingState) {
            return <Loading />
        } else {
            return (
                <div>
                     <h2>Family Pickup</h2>
                     <Typography>
                         When items are ready for pickup, notify the family here by selecting the appropriate status
                         and clicking 'Update Status'. You can send additional notifications by clicking on 'Notify Family'.
                     </Typography>
                    <Grid container spacing={4} style={{
                        marginBottom: '2rem',
                        marginTop: '1rem',
                        // marginLeft: '4rem'
                    }}>
                        <Grid item xs={4} md={4}>
                            <p style={{textAlign: 'center'}}>
                                <strong>Details </strong>
                            </p>
                            <p>
                                <strong>Status: </strong> {myContext.requests[keyGlobal]['pickupStatus']}
                            </p>
                            <p>
                                <strong>Family Last Notified: </strong> {myContext.requests[keyGlobal]['pickupNotification']}
                            </p>
                            <p style={{textAlign: 'center', marginTop: '3rem'}}>
                                <strong>Actions </strong>
                            </p>
                            <div style={{
                                // maxWidth: '15%'
                            }}>
                                <InputLabel>Update Status</InputLabel>
                                <Select
                                    name='pickupStatus'
                                    value={newStatus}
                                    onChange={(e) => setNewStatus(e.target.value)}
                                    autoWidth
                                    // className="dropdown-item"
                                    // fullWidth
                                    style={{width: '17rem'}}
                                >                                                    
                                    {/* <MenuItem value={0}></MenuItem> */}
                                    <MenuItem value='Awaiting Pickup'>Awaiting Pickup</MenuItem>
                                    <MenuItem value='Pickup Missed'>Pickup Missed</MenuItem>
                                    <MenuItem value='Pickup Complete'>Pickup Complete</MenuItem>
                                </Select>
                            </div>
                            <Button
                                variant='outlined'
                                onClick={() => handlePickupStatusUpdate(myContext.requests[keyGlobal]['recipientPreferredCommunicationMethod'], myContext.requests[keyGlobal]['shortId'],
                                    myContext.requests[keyGlobal]['nonProfitName'], myContext.requests[keyGlobal]['nonProfitPhoneNumber'],
                                    myContext.requests[keyGlobal]['nonProfitEmail'], myContext.requests[keyGlobal]['recipientEmail'],
                                    myContext.requests[keyGlobal]['recipientName'], myContext.requests[keyGlobal]['recipientPhoneNumber'], 
                                    )}
                                color='primary'
                                style={{
                                    marginTop: '1.5rem',
                                    textTransform: 'none',
                                }}
                            >
                                Update Status & Notify Family
                            </Button>
                        </Grid>
                        <Grid item xs={2} md={3}>
                            <p style={{textAlign: 'center'}}>
                                <strong>Primary Contact </strong>
                            </p>
                            <p>
                                <strong>Name: </strong> {myContext.requests[keyGlobal]['recipientName']}
                            </p>
                            <p>
                                <strong>Contact Number: </strong> {myContext.requests[keyGlobal]['recipientPhoneNumber']}
                            </p>
                            <p>
                                <strong>Email: </strong> {myContext.requests[keyGlobal]['recipientEmail']}
                            </p>
                        </Grid>
                        <Grid item md={5}>
                            <p style={{textAlign: 'center'}}>
                                <strong>Timeline </strong>
                            </p>
                            <CustomizedTimeline />
                        </Grid>
                        
                        {/* <Grid item xs={7}>                           
                            <Button
                                variant='outlined'
                                onClick={() => handleNotifyFamily(myContext.requests[keyGlobal]['nonProfitName'], myContext.requests[keyGlobal]['nonProfitPhoneNumber'],
                                    myContext.requests[keyGlobal]['nonProfitEmail'], myContext.requests[keyGlobal]['pickupStatus'],
                                    myContext.requests[keyGlobal]['recipientName'], myContext.requests[keyGlobal]['recipientPhoneNumber'],
                                    myContext.requests[keyGlobal]['alternateContactPhoneNumber'], myContext.requests[keyGlobal]['recipientEmail'],
                                    myContext.requests[keyGlobal]['shortId']
                                )}
                                style={{
                                    marginLeft: '2rem'
                                }}
                            >
                                Send Reminder Notification
                            </Button>
                        </Grid>                        */}
                    </Grid>       
                </div>
            )
        }
    }
    
    function PageContent(props) {
        const { index } = props;

        let matchFound = false;
        var keyGlobal = -1;
        var adoptionKey;

        console.log('url id: ' + id);
        Object.keys(myContext.requests).forEach (function(key) {
            console.log('reqId: ' + myContext.requests[key]['reqId']);
            if (myContext.requests[key]['shortId'] == id) {
                console.log("MATCH FOUND-----------------");
                console.log(myContext.requests[key]['bio']);
                matchFound = true;
                keyGlobal = key;
                // return(
                //     <div></div>
                // )
            }
        })

        if (myContext.adoptions[0]['status'] != 'loading') {
            Object.keys(myContext.adoptions).forEach (function(key) {
                if (myContext.adoptions[key]['requestId'] == id) {
                    console.log("MATCH FOUND-----------------");
                    if (myContext.adoptions[key]['status'] == 'Active') {
                        adoptionKey = key;
                    }
                }
            })
        } else {
            console.log('adoptions is empty!');
        }

        // if (myContext.adoptions) {
        //     for (var i=0; i<myContext.adoptions.length; i++) {
        //         if (myContext.adoptions[i]['requestId'] == id) {
        //             alert('found adoption row!');
        //             // check if the donor matches
        //             if (myContext.adoptions[i]['status'] == 'Active') {
        //                 adoptionKey = i;                    
        //                 break;
        //             }
        //         }
        //     }
        // }

        console.log(myContext.requests[keyGlobal]);

        // Set the non profit name in the context
        myContext.setnonprofitName(myContext.requests[keyGlobal]['nonProfitName']);
        
        if (matchFound == false) {
            return (
                <p>ID not found...</p>
            )
        } else {
            if (index == 0) {
                return (
                    <>
                    <div className="request-summary-root">
                    {/* <h2>Request Summary</h2> */}
                    <div style={{
                        marginBottom: '1.5rem'
                    }} >
                        <CustomizedSteppers status={myContext.requests[keyGlobal]['status']} />
                    </div>
                    <div className="request-summary-flexbox">
                        <div className="recipient-info">
                            <h3>Recipient Information</h3>
                            <p><strong>Name: </strong>{myContext.requests[keyGlobal]['recipientName']}</p>
                            <p><strong>Email: </strong>{myContext.requests[keyGlobal]['recipientEmail']}</p>
                            <p><strong>Phone: </strong>{myContext.requests[keyGlobal]['recipientPhoneNumber']}</p>
                            <p><strong>Request ID: </strong>{myContext.requests[keyGlobal]['shortId']}</p>
                            <p><strong>Preferred Communication Method: </strong>{myContext.requests[keyGlobal]['recipientPreferredCommunicationMethod']}</p>
                            <p><strong>Family Bio: </strong>{myContext.requests[keyGlobal]['bio']}</p>
                            <p><strong>Items Remaining: </strong>{getItems(myContext.requests[keyGlobal])}</p>                            
                        </div>
                        <div className="recipient-info">
                            <h3>Alternate Contact Information</h3>
                            {
                                (myContext.requests[keyGlobal]['alternateContactName'] == "" || myContext.requests[keyGlobal]['alternateContactName'] == " ") ?
                                    <div>
                                        <span>Recipient has not listed an alternate contact</span>
                                    </div>
                                :
                                <div>
                                    <p><strong>Name: </strong>{myContext.requests[keyGlobal]['alternateContactName']}</p>
                                    <p><strong>Email: </strong>{myContext.requests[keyGlobal]['alternateContactEmail']}</p>
                                    <p><strong>Phone: </strong>{myContext.requests[keyGlobal]['alternateContactPhoneNumber']}</p>
                                    <p><strong>Preferred Communication Method: </strong>{myContext.requests[keyGlobal]['alternateContactPreferredCommunicationMethod']}</p>
                                </div>

                            }
                            <h3>Adoption Information</h3>
                            {
                                (myContext.adoptions && myContext.adoptions[0]['status'] != 'loading' && myContext.adoptions[adoptionKey])
                                ?
                                <div>
                                    <p><strong>Donor Name: </strong>{myContext.adoptions[adoptionKey]['donorName']}</p>
                                    <p><strong>Donor Email: </strong>{myContext.adoptions[adoptionKey]['donorEmail']}</p>
                                </div>
                                :
                                <p>This family has not been adopted yet!</p>
                            }
                            </div>
                        <div className="recipient-info">
                            <h3>Delivery Information</h3>
                            <p><strong>Non-Profit Name: </strong>{myContext.requests[keyGlobal]['nonProfitName']}</p>
                            <p><strong>Non-Profit Email: </strong>{myContext.requests[keyGlobal]['nonProfitEmail']}</p>
                            <p><strong>Delivery Address: </strong>{myContext.requests[keyGlobal]['preferredDeliveryLocation']}</p>
                            <p><strong>Delivery Instructions: </strong>{myContext.requests[keyGlobal]['deliveryInstructions']}</p>
                        </div>
                        <div>                                                                                    
                        </div>
                    </div>
                    </div>
                        <Button
                            variant="contained"
                            onClick={() => history.goBack()}
                        >
                            Back
                        </Button>
                    </>
                    
                )
            } else if (index == 1) {
                return (
                    <div className="request-summary-root">                        
                        <h2>Donation List</h2> 
                        <Typography>
                            List of all donations made towards the current recipient family. Update the status of a specific donation by clicking on
                            the downward facing arrow of the respective donation.               
                         </Typography>  
                         <br />
                        <DonationList 
                            id={id}
                            adopterEmail={myContext.adoptions[adoptionKey] ? myContext.adoptions[adoptionKey]['donorEmail'] : ""}
                            // setFile={setFile} File={File}
                        />                        
                        <Button
                            variant="contained"
                            onClick={() => history.goBack()}
                        >
                            Back
                        </Button>
                    </div>
                )               
            } else if (index == 2) {
                return (
                    <div className="request-summary-root">                                        
                        {FamilyPickup(keyGlobal)}
                        <Button
                            variant="contained"
                            onClick={() => history.goBack()}
                        >
                            Back
                        </Button>
                    </div>
                )
            }
        }
    }

    // Return the appropriate content based on data loading status
    function Content() {
        if (myContext.requests[0].bio == "Loading...") {
            return <LoadingPage />;
        } else {
            return <PageContent />
        }
    }   

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
      
    function BasicTabs() {
        const [value, setValue] = React.useState(0);
        
        const handleChange = (event, newValue) => {
            setValue(newValue);
        };
        
        if (myContext.requests[0].bio == "Loading...") {
            return (
                <Loading />
            )
        } else {
            return (
                <div className="request-summary-root">
                    {/* <UnstyledTabsCustomized component1={<PageContent index={0} />} component2={<PageContent index={1} />} component3={<PageContent index={2} />} />              */}
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs
                                value={value} 
                                onChange={handleChange} 
                                aria-label="basic tabs example"
                                variant='fullWidth'
                                className={classes.tabRoot}    
                                // indicatorColor= 'primary'
                                TabIndicatorProps={{style: {background:'grey', height: '2.5px'}}}
                            >
                                <Tab label="Recipient Summary" {...a11yProps(0)} />
                                <Tab label="Donation List" {...a11yProps(1)} />
                                <Tab label="Pickup Status" {...a11yProps(2)} />
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>                    
                            <PageContent index={0} />
                        </TabPanel>
                        <TabPanel value={value} index={1}>                  
                            <PageContent index={1} />
                        </TabPanel>
                        <TabPanel value={value} index={2}>                   
                            <PageContent index={2} />
                        </TabPanel>
                    </Box>
                </div>
            );
        }
    }

     return (
        // <Content />
        <BasicTabs />
    )

}

export default RequestSummary

// <div>
//     <h2>{currentRequest.bio}</h2>
//     <p><strong>Email: </strong>{currentRequest.npEmail}</p>
//     <p><strong>Request Date: </strong>{currentRequest.requestPlacedOn}</p>
//     <Button
//         variant="contained"
//         onClick={() => history.push("/item-history")}
//     >
//         Back
//     </Button>
// </div>