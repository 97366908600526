// Home page containing gallery view of all the families
import React, { useState, useEffect, useContext } from 'react'
import { Grid, Card, CardContent, Typography, CardActions, Button, FormControl, Select, Paper, Tabs, Tab, Badge, InputLabel, MenuItem, CardMedia, CardHeader } from '@material-ui/core';
import './DonationHome.css';
import { handleDonation } from '../../handleDonation.js';
import {
    BrowserRouter as Router,
    Link,
    useHistory,
    useParams
  } from "react-router-dom";
import { CheckBox } from '@material-ui/icons';
import DallasSkyline3 from '../../img/bannerImg.png';
import StockImg from '../../img/banner-image.png';
import * as items_global from '../../items';
import { MyStepper, BasicSummary } from '..';
import * as itemsGlobal from '../../items';
import CartSummary from '../Recipient Request/CartSummary';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AppContext from '../AppContext';
import { useAuth0 } from '@auth0/auth0-react';
import itemsObjects from "../../items.json";
import Loading from '../loading';
import CartIcon from '../../img/charity-donation-hand-love.svg';

var api_call_limiter = 0;
var donationID_local = '';

const DonationModal = () => {

    const myContext = useContext(AppContext);
    let { id } = useParams();
    const [currentCategory, setcurrentCategory] = useState('Kitchen');
    const [tabValue, setTabValue] = useState(0);
    const [modalState, setmodalState] = useState(0);                            // Stage of modal (0 is initial stage, 1 is summary stage)
    var itemList = [];
    const history = useHistory();
    const { user } = useAuth0();
    const [donationID, setdonationID] = useState();
    const [DonateDisabled, setDonateDisabled] = useState(false);

    // var donatedItems = myContext.donatedItems;

    // Get & set list of items given a request object
    function getItems(obj) {
        console.log("Getting list");
        var list = '';

        Object.keys(obj).forEach(function(key) {
            // console.log('Key : ' + key + ', Value : ' + obj[key]);
            if (items_global.items.includes(key) && (obj[key] != '') && (obj[key] != 0)) {
                list = list + ' ' + obj[key] + ' ' + key + ',';
            }
        });
        
        // Set the categories to show on each family card
        // setCategories(obj);

        // Remove the last comma            
        list = list.slice(0,-1);
        console.log(list);
       
        return list;
    }

    useEffect(() => {
        // set the donation ID for the current donor and family
        // donationID_local = user.sub + '--' + id;
        donationID_local = "D" + '-' + id;

        if (myContext.requests[0].bio == "Loading...") {
            console.log(`api_call_limiter is: ${api_call_limiter}`);
            if(api_call_limiter<=1) {
                console.log("Making api call for requests in DonationModal.jsx useEffect");
                let url = process.env.REACT_APP_SHEETY_API_GET;
                fetch(url)
                .then((response) => response.json())
                .then(json => {
                    // Do something with the data                    
                    // Update the prop with the requests
                    console.log(json.requests);
                    console.log(myContext.requests);
                    console.log("Setting requests...");
                    myContext.setrequests(json.requests);
                });
            }
            api_call_limiter++;
        } else {
            // set the details of the current donation
            console.log(myContext.requests);
            myContext.requests.forEach(request => {
                console.log(request.shortId);
                console.log(id);
                if (request.shortId == id) {
                    // setdonatedItems,
                    myContext.setdeliveryInstructions(request.deliveryInstructions);
                    myContext.setfamilyID(request.shortId);
                    myContext.setdeliveryAddress(request.preferredDeliveryLocation);
                    myContext.setnpEmail(request.nonProfitEmail);
                    // setItemsNeeded
                    myContext.setItemsNeeded(getItems(request));
                }
            })
        }

    })
    
    // function to convert item name from camelcase to normal word (Eg: pillowCases(standard) -> pillow cases(standard)) 
    function convertCase(item) {
        var gapi_key;

        if(/[A-Z]/.test(item)) {
            // split word at capital letter
            var str_array = item.split(/(?=[A-Z])/);
            gapi_key = str_array.join(' ');
            gapi_key = gapi_key.toLowerCase();
        } else {
            return item;
        }
        
        console.log(`converted ${item} to `);
        return gapi_key;
    }
    
    // Handler function to handle donation submissions
    const handleChange = (event) => {
        console.log("Handling donation...");
        const name = event.target.name;
        console.log(name);
        console.log(event.target.value);       
        
        console.log(myContext.donatedItems[event.target.name]);     
        
        myContext.donatedItems[event.target.name] = event.target.value;
        console.log(myContext.donatedItems[event]);
    };
    
    // This function takes in an item(string) and checks if the item cetegory filter is checked to true
    // If there is a match, we return true. Else, we return false
    function checkTabFilter(item) {
        
        if(items_global.items.indexOf(item) <= 30 && currentCategory=="Kitchen") {
            console.log(`${item} is a Kitchen item`);
            return true;
        } else if((items_global.items.indexOf(item) <= 38 && items_global.items.indexOf(item) > 30 )  && currentCategory=="Bedroom") {
            console.log(`${item} is a Bedroom item`);
            return true;
        } else if(items_global.items.indexOf(item) > 38  && currentCategory=="Bathroom") {
            console.log(`${item} is a Bathroom item`);
            return true;
        }

        return false;
    }
    
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        if (newValue == 0) setcurrentCategory("Kitchen")
        else if (newValue == 1) setcurrentCategory("Bedroom")
        else if (newValue == 2) setcurrentCategory("Bathroom")
    }
    
    const itemGallery = (items) => {
        var str = JSON.stringify(items);
        str = str.split(" ")
        console.log("str is...");
        str.shift();
        console.log(str);
    
        var itemQty = [];
        
        if (tabValue == 3) {
            cartReview();
            return(
                <CartSummary itemsRequested={myContext.donatedItems} summaryHeading="Donation Summary" />
            )
        } 

        for(let i=0; i<str.length-1; i=i+2) {
            itemQty.push(parseInt(str[i]));
            itemList.push(str[i+1]);
        }
        return (
            <>
                <Grid container spacing={4} className="itemGrid">
                    {
                        itemList.map((item, index) => (
                                    (checkTabFilter(item.slice(0,-1)) == false) ? null :
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <Card sx={{ maxWidth: 345 }} className="itemCard">
                                    <CardHeader              
                                        // title={itemsObjects.items[0][item.slice(0,-1)].name}
                                        title={convertCase(item.slice(0,-1))}
                                        // subheader={convertCase(item.slice(0,-1)).toUpperCase()}
                                    />
                                    <CardMedia
                                        component="img"
                                        alt={item.slice(0,-1)}
                                        height="200"
                                        image={itemsObjects.items[0][item.slice(0,-1)].img}
                                        className="itemCardProductImage"
                                    />
                                    <CardContent className="itemCardDescription">                                       
                                        {/* <img src={itemsObjects.items[0][item.slice(0,-1)].img} className="itemCardProductImage"/> */}
                                        <Typography variant="body2" color="text.secondary">
                                            {itemsObjects.items[0][item.slice(0,-1)].description}
                                        </Typography>
                                    </CardContent>
                                    <CardActions className="itemCardAction">
                                        <FormControl key={index}
                                            className="formControl"
                                        >
                                            {/* <InputLabel id={item}>{convertCase(item.slice(0,-1))}</InputLabel> */}
                                            <InputLabel id={item}>Qty</InputLabel>
                                            <Select
                                                name={item.slice(0,-1)}
                                                value={(!myContext.donatedItems) ? '' : myContext.donatedItems[item.slice(0,-1)]}
                                                onChange={handleChange}
                                                // autoWidth
                                                className="dropdown-item"
                                            >                                            
                                                {Array(itemQty[index]+1).fill(0).map((value, i) => (
                                                    <MenuItem key={i} value={i}>{i}</MenuItem>
                                                ))}                                                                                 
                                            </Select>
                                        </FormControl> 
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))
                    }
                </Grid>
            </>
        )
    }    

    // Handle the closing of the modal
    const handleClose = () => {
        console.log('Going back');
        // setOpen(false);
        // window.location.reload();
        // setmodalState(0);
    history.goBack();
    };
    
    function cartReview() {
        // if there are items with 0 quantity, delete them
        Object.keys(myContext.donatedItems).forEach((key, index) => {
            console.log(key);
            console.log(myContext.donatedItems[key]);
            if (myContext.donatedItems[key] == 0) {
                delete myContext.donatedItems[key]
            }
        })

        console.log(myContext.donatedItems);
        console.log(Object.keys(myContext.donatedItems).length);

        if (tabValue != 3) {
            setTabValue(3);
        }
    }
    

    async function makeDonation() {

        // Disable "Donate" button
        setDonateDisabled(true);

        // Get the relevant request row
        // alert(`Calling handleDonation. Deliver to ${deliveryAddr}`);
        const index = myContext.requests.map(e => e.shortId).indexOf(id);

        console.log(`Making donation, \nindex: ${index}\n id: ${id}`);
       
        const response = await handleDonation(
            myContext.donatedItems, id, itemList, myContext.deliveryInstructions, 
            myContext.deliveryAddress, myContext.npEmail, user.email, donationID_local, user['https://example.com/first_name'],
            myContext.requests[index]['nonProfitName']
        );
        setdonationID(response);
        console.log(`Donation ID is: ${donationID}`);

        setmodalState(1);
        setDonateDisabled(false);
    }
    
    // Body structure of the lightbox/modal
    const modalInitial = (id) => (
        <>
            {
                myContext.requests.map((request, index) => (
                    <div key={index}>
                        {request.shortId == id ? 
                            <>
                                {/* <img className="banner-image" src={DallasSkyline3} /> */}
                                <img className="banner-image" src={StockImg} />
                                <div className="modal-body">
                                    <MyStepper activeStep={1} />
                                    <Typography variant="h5">Family background</Typography>
                                    <p className="modal-bio">{request.bio}</p>            

                                    {
                                        tabValue == 3 ? 
                                            <Typography className="donationInstructionsText">Please review your selected items now, and make any necessary changes before hitting Donate below.</Typography>
                                            :
                                            <>
                                                <Typography className="donationInstructionsText">Choose the items that you’d like to donate from the requested items. If you can’t donate all of them, that’s okay! Just select the items that you have available to donate.</Typography>
                                                <p><strong>Disclaimer: </strong>Item images are examples of the items being requested, your items do not have to match the image exactly.</p>
                                            </>
                                    }

                                    <Paper>
                                        <Tabs
                                            value={tabValue}
                                            onChange={handleTabChange}
                                            indicatorColor="primary"
                                            textColor="primary"
                                            centered
                                            // variant="scrollable"
                                            // scrollButtons="on"
                                        >
                                            <Tab label="Kitchen" />
                                            <Tab label="Bedroom" />
                                            <Tab label="Bathroom" />
                                            <Tab icon={
                                                    <Badge color="secondary" badgeContent={myContext.donatedItems ? Object.keys(myContext.donatedItems).length : 0}>
                                                    {/* <ShoppingCartIcon /> */}
                                                    <img src={CartIcon} height={28} />
                                                </Badge>
                                            } aria-label="shopping cart" />
                                        </Tabs>
                                    </Paper>

                                    {/* Display dropdown based on items */}
                                    {/* {itemCategoryTabs(myContext.itemsNeeded)} */}
                                    {itemGallery(myContext.itemsNeeded)}
                                    <div className="modal-buttons-container">
                                        <Button
                                            // fullWidth
                                            variant="contained"
                                            color="default"
                                            onClick={() => handleClose()}
                                        >
                                            Back
                                        </Button>
                                        {
                                            tabValue == 3 ?
                                                <Button className="donate-modal"                                            
                                                    onClick={() => makeDonation()}
                                                    disabled={Object.keys(myContext.donatedItems).length == 0 || DonateDisabled}
                                                >
                                                    Donate
                                                </Button>
                                                :
                                                <Button                             
                                                    onClick={() => cartReview()}
                                                    color="primary"
                                                    variant="contained"
                                                >
                                                    Continue
                                                </Button>
                                        }
                                    </div>
                                </div>
                            </>
                            :
                            null
                        }
                    </div>
                ))
            }
        </>
    );

    function modalOnDonation() {
        console.log(`donation ID: ${donationID}`);
            if (donationID != "") {
                return (
                    <div className="modal-body">
                        <MyStepper activeStep={2} />
                        <div className="noPrint">
                            <h1>Thank you for your donation!</h1>
                            <h3>See below for further instructions</h3>
                            {/* <div style={{display: "flex", justifyContent: "flex-end"}}> */}
                            {/* <div style={{marginLeft: "2rem"}}> */}                           
                        </div>           
                        <BasicSummary 
                            familyID={id} deliveryInstructions={myContext.deliveryInstructions}
                            deliveryAddress={myContext.deliveryAddress} item_list={myContext.donatedItems}
                            npEmail={myContext.npEmail} donationID={donationID}
                        />
                    </div>
                )
            } else {
                return (
                    <div>
                        <p>Loading...</p>
                    </div>
                )
            }                
    }

    
    if (DonateDisabled) {
        return (
            <Loading />
        )
    }
    return (
        <div>
            {modalState == 0? modalInitial(id) : modalOnDonation()}
        </div>
    )
}

export default DonationModal
