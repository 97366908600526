import axios from 'axios';
import { toast } from 'react-toastify';

export async function handleDonation(donatedItems, id, items, deliveryInstructions, deliveryAddress, npEmail, donorEmail, donationID, donorName, nonProfitName) {
    console.log('In handleDonation...');
    console.log(donatedItems);
    console.log(id);
    console.log(deliveryAddress);
    console.log(npEmail);
    console.log(donorEmail);
   
    // send patch request to api
    // alert('Sending PATCH req to server from Donor page client...');

    // console.log(window.sessionStorage.getItem('donorName'));
    // console.log(window.sessionStorage.getItem('donorEmail'));
    // console.log(id);
    // console.log(donatedItems);
    // console.log(deliveryInstructions);
    // console.log(deliveryAddress);
    // console.log(npEmail);

    var toast_id = toast.loading('Processing Donation...');

    const response = await
        axios.patch('https://donation-proxy-api.herokuapp.com', {
        // axios.patch('http://localhost:3080', {
            donorName: donorName,
            donorEmail: donorEmail,
            id: id,
            donationID: donationID,
            donatedItems: donatedItems,
            items: items,
            deliveryInstructions: deliveryInstructions,
            deliveryAddress: deliveryAddress,
            contact: npEmail,
            nonProfitName: nonProfitName,
        })
        .then(function (response) {
            // alert("Donation Successful!");
            var donationID = response.data;
            // window.location.reload();
            console.log(`Returning from handleDonation, donationID: ${donationID}`);
            // return donationID

            toast.update(toast_id, { 
                render: "Donation Successful!", 
                type: "success", 
                isLoading: false, 
                autoClose: 5000, 
                closeOnClick: true,
                hideProgressBar: false,
            });
            
            return new Promise((resolve, reject) => {
                resolve(donationID);
            })
            // return response;
        })
        .catch(function (error) {
            toast.update(toast_id, { render: "Donation Failed!", type: "error", isLoading: false });
            alert(error);
        });


        return response;
}
