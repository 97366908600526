import React, { useEffect, useCallback } from 'react';
import {useDropzone} from 'react-dropzone';
import { makeStyles, Button, IconButton } from '@material-ui/core';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    container: {
        borderStyle: "dashed",
        borderColor: "lightgray",
        borderWidth: "2px",
    },
    dropzone: {
        width: "58%",
        textAlign: "center",
        margin: "auto",
        marginTop: "1.5rem",
        marginBottom: "1.5rem",
    },
    uploadButton: {
        textTransform: "none",
    }
}))

export default function FileUpload ({setFile}) {    

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
            // Do whatever you want with the file contents
                const binaryStr = reader.result
                setFile(acceptedFiles[0]);
            }                               
            reader.readAsArrayBuffer(file)
        })
        
    }, [])

    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({onDrop});
    const classes = useStyles();

    useEffect(() => {
        console.log(acceptedFiles);
    })


    const files = acceptedFiles.map(file => (
        <li key={file.path}>
        {file.path} - {file.size} bytes
        {/* <IconButton 
            onClick={() => (
                acceptedFiles.splice(acceptedFiles.indexOf(file), 1)
            )}
        >
            <DeleteIcon />
        </IconButton> */}
        </li>
    ));

    return (
        <>
            <section className={classes.container}>
            <div {...getRootProps({className: classes.dropzone})}>
                <input {...getInputProps()} />
                <CloudUploadIcon />
                <p>Drag and drop files here</p>
                <Button variant='contained' className={classes.uploadButton}>Or select items to upload</Button>
            </div>
            {
                acceptedFiles.length != 0 ?
                <aside style={{padding: "15px"}}>
                    <h4>File</h4>
                    <ul>{files}</ul>
                </aside>
                :
                null
            }
            </section>
        </>
    );
}