import * as React from 'react';
import { styled } from '@mui/system';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import {  Grid } from '@material-ui/core';

const blue = {
    
    // Unused
    100: '#C2E0FF',
    200: '#80BFFF',
    300: '#66B2FF',
    
    // Used
    //   50: '#F0F7FF',
    50: 'white',
    400: '#3399FF',
    // 500: '#007FFF',
    500: 'rgb(141, 215, 207)',
    // 600: '#0072E5',
  
  // Unused
  700: '#0059B2',
  800: '#004C99',
  900: '#003A75',
};

const Tab = styled(TabUnstyled)`
  /* font-family: IBM Plex Sans, sans-serif; */
  ${'' /* color: white; */}
  color: black;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  background-color: transparent;
  width: 100%;
  padding: 12px 16px;
  ${'' /* margin: 6px 6px; */}
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;

  &:hover {
    ${'' /* background-color: ${blue[400]}; */}
    ${'' /* background-color: white ; */}
    ${'' /* opacity: 0.2; */}
  }

  &:focus {
    ${'' /* color: #fff; */}
    border-radius: 3px;
    /*outline: 2px solid ${blue[200]};*/
    /* outline-offset: 2px; */
  }

  &.${tabUnstyledClasses.selected} {
    ${'' /* background-color: ${blue[50]}; */}
    background-color: lightGrey;
    ${'' /* outline: 2px solid ${blue[200]}; */}
    ${'' /* border-top: 1px solid black; */}
    ${'' /* border-left: 1px solid black; */}
    ${'' /* border-right: 1px solid black; */}
    color: ${blue[600]};
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
`;

const TabsList = styled(TabsListUnstyled)`
  ${'' /* min-width: 320px; */}
  ${'' /* width: 50%; */}
  ${'' /* background-color: ${blue[500]}; */}
  ${'' /* border: 1px solid black; */}
  ${'' /* border-bottom: 1px solid black; */}
  border: 2px solid black;
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
`;

export default function UnstyledTabsCustomized(props) {

    const { component1, component2, component3 } = props;

  return (   
    <TabsUnstyled defaultValue={0}>
        <TabsList>
            <Tab>Recipient Summary</Tab>
            <Tab>Donation List</Tab>
            <Tab>Pickup Status</Tab>
        </TabsList>
        <TabPanel value={0}>
            {component1}
        </TabPanel>
        <TabPanel value={1}>
            {component2}
        </TabPanel>
        <TabPanel value={2}>
            {component3}
        </TabPanel>
    </TabsUnstyled>    
  );
}
