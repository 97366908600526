import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import LogoutButton from '../Login/logout-button';
import HistoryIcon from '@mui/icons-material/History';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { SvgIcon } from '@mui/material';

export default function ProfileButton () {
  
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const { user, isAuthenticated, logout } = useAuth0();


    const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
    setAnchorEl(null);
    };
    return (
    <React.Fragment> 
    <Tooltip title="Account settings">
        <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
            <Avatar sx={{ width: 32, height: 32 }} src={user.picture}>
                {/* <img src={user.picture} /> */}
            </Avatar>
            {/* <SvgIcon htmlColor="white"> */}
            <SvgIcon>
                <ArrowDropDownIcon />
            </SvgIcon>
        </IconButton>
    </Tooltip>
    <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
        elevation: 0,
        sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
            },
            '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
            },
        },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
        <MenuItem component={Link} to={"/profile/"+user.sub}>
            <Avatar /> Profile
        </MenuItem>
        <Divider />
        {/* <MenuItem component={Link} to={isAuthenticated && user["https://nectarcares.org/roles"][0] == "admin" ? "/item-history" : "/history"}>
            <ListItemIcon>
                <HistoryIcon /> 
            </ListItemIcon>
            {
                isAuthenticated && user["https://nectarcares.org/roles"][0] == "admin" ?
                    "Manage Requests"
                    :
                    "Donation History"
            }
        </MenuItem> */}
        <MenuItem
             onClick={() =>
                logout({
                    returnTo: window.location.origin,
                })
            }
        >
            <ListItemIcon>
                <Logout fontSize="small" />
            </ListItemIcon>
            Logout
        </MenuItem>
    </Menu>
    </React.Fragment>
    );
}
