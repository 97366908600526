import { Button, Divider, makeStyles, Typography, useMediaQuery, Grid, Card, CardContent, Chip, Link } from '@material-ui/core';
import { height } from 'dom-helpers';
import { classes } from 'istanbul-lib-coverage';
import React, { useEffect, useContext, useState } from 'react';
import typeIs from 'type-is';
import { Url } from 'url';
// import heroImage from '../../img/Family _Outline.svg'
import heroImage from '../../img/transparent-logo.png'
import './home.css'
import AppContext from '../AppContext';
import * as items_global from '../../items';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Keyboard, Scrollbar, Pagination, Navigation } from 'swiper/core'

// Import Swiper styles
// import 'swiper/css';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/scrollbar/scrollbar.min.css'
import 'swiper/components/pagination/pagination.min.css'
import useWindowDimensions from '../hooks/WindowDimensions';
import Loading from '../loading';
// import StockImg from '../../img/banner-image.png';
import StockImg from '../../img/banner-image-2.svg';
import NewsletterForm from '../MailChimp/MailchimpFormContainer';
import MailchimpFormContainer from '../MailChimp/MailchimpFormContainer';

// Home page - users are directed here on successful login
// We display hero image along with 2 options - 
//      (1) For Donors - Make a donation 
//      (2) For Non-Profit Orgs - Submit an Item Request

SwiperCore.use([Navigation])

const useStyles = makeStyles((theme) => ({
    heroImage: {
        width: '18vw',
        marginBottom: '-50px',     
    },
    overlay: {
        position: 'fixed', /* Sit on top of the page content */
        width: '100%', /* Full width (cover the whole page) */
        height: '71%', /* Full height (cover the whole page) */
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0,0,0,0.5)' /* Black background with opacity */
    },
    heroTitle: {
        // fontFamily: 'roboto',
        fontSize: 'min(9vw, 4.5rem)',
        // fontSize: '6vw',
        fontWeight: 'bold',
        margin: '15px 15px 7px 15px',
    },
    heroSubtitle: {
        fontSize: 'min(5vw, 2rem)',
        // fontSize: '2vw',
        margin: '7px 15px 15px 15px',
    },
    heroContent: {
        // height: '65vh',
        display: 'flex',
        flexWrap: 'wrap',
        // maxWidth: 'fit-content',
        // margin: '4rem',
        // marginBottom: '8rem',
        alignContent: 'center',
    },
    leftContent: {
        margin: "auto",
        textAlign: "center",
        width: "100%"
    },
    heroText: {
        width: 'fit-content',
        margin: "auto",
        // position: 'fixed',
        // top: '30vh',
        // left: '8vw',
    },
    heroButtons: {
        justifyContent: 'center',
        margin: '35px 15px 15px 15px',
        display: 'flex',
    },
    donationButton: {
        // margin: '8px 20px 0px 0px',
    },
    itemRequestButton: {
        margin: '8px 0px 0px 0px',
    },
    InstructionsTitle: {
        fontSize: '1.5rem',
        margin: '2rem 0rem 2rem 0rem',
        fontWeight: 'bold'
    },
    InstructionsRoot: {
        // marginLeft: '5rem'
        display: 'flex',
        flexFlow: 'column',
        maxWidth: '80vw',
        margin: 'auto'
    },
    requestOverview: {
        // width: "35%",
        display: "flex",
        margin: "1vh auto 5vh",        
    },
    swiper: {
        width: "85vw",
        height: "188px",
        marginTop: "15px",
    },
    swiperSlide: {
        // maxWidth: "80vw",
        // marginRight: "unset",
        // margin: "auto",
        // justifyContent: "center",
        // backgroundColor: "blue",
    },
    swiperContainer: {
        overflow: "visible",
    },
    cardRoot: {
        height: "99%",
        // height: '100%',
        // border: 'solid black 1px',
        marginLeft: "20px !important",
        marginRight: "20px !important"
    },
    '@media print': {
        noPrint: {
          visibility: 'hidden'
        }
    },
    // chipRoot: {
    //     position: 'absolute',
    //     bottom: 25
    // }
    bodyText: {
        fontSize: '1.25rem',
        lineHeight: '180%',
    }
}));

var api_call_limiter = 0;
var requestOverviewSize = 0;

const Home = () => {
    const classes = useStyles();
    const mobileView = useMediaQuery("(max-width: 500px)");
    const myContext = useContext(AppContext);
    var categoryList = [];
    const { height, width } = useWindowDimensions();
    // const [open, setOpen] = useState(false); 

    // const setnewsletterPopup = (newValue) => {
    //     console.log(`Setting newsletterPopup to ${newValue}`);
    //     newsletterPopup = newValue;
    // }

    useEffect(() => {
        // alert('In home useEffect');
        console.log("Setting homepage state");
        
        if(api_call_limiter<=1) {
            console.log("Fetching from api");
            let url = process.env.REACT_APP_SHEETY_API_GET;
            fetch(url)
            .then((response) => response.json())
            .then(json => {                
                // Update the prop with the requests
                if (myContext.setrequests) {
                    console.log("Going to set requests in homepage useEffect");
                    console.log(myContext);
                    myContext.setrequests(json.requests);
                }
            });
        }
        api_call_limiter++;
    });

     // Set the global variable categoryList to a list of categories based on the items that are present in object obj
     function setCategories(obj) {
        
        // Reset the global categoryList array
        categoryList = [];

        Object.keys(obj).forEach(function(key) {
            // console.log('Key : ' + key + ', Value : ' + obj[key]);
            if (items_global.items.includes(key) && (obj[key] != '') && (obj[key] != 0)) {
                console.log(`key is ${key}`);
                // if the current key is an item, check it's index in items_global[]
                var index = items_global.items.indexOf(key);
                console.log(`Index of ${key} is ${index}`);

                // Set the category based on the index range of the current key in array items_global[]
                if (index <= 30) {
                    // Kitchen
                   if (categoryList.indexOf('Kitchen ') == -1) {
                       categoryList.push('Kitchen ');
                   } 
                } else if (index > 30 && index <= 38) {
                    // Bedroom
                    if (categoryList.indexOf('Bedroom ') == -1) {
                        categoryList.push('Bedroom ');
                    } 
                } else if (index > 38) {
                    // Bathroom
                    if (categoryList.indexOf('Bathroom ') == -1) {
                        categoryList.push('Bathroom ');
                    } 
                }
            }
        });        
    }
    
    // Get & set list of items given a request object
    function getItems(obj, flag) {
        console.log("Getting list");
        var list = '';

        // if (requestOverviewSize == 6) {
        //     console.log(requestOverviewSize);
        //     // alert('reached 6...');
        //     return 'str.';
        // }

        Object.keys(obj).forEach(function(key) {
            // console.log('Key : ' + key + ', Value : ' + obj[key]);
            if (items_global.items.includes(key) && (obj[key] != '') && (obj[key] != 0)) {
                list = list + ' ' + obj[key] + ' ' + key + ',';
            }
        });

        // Set the categories to show on each family card
        setCategories(obj);

        // Remove the last comma
        list = list.slice(0,-1);

        if (list != '') {
            // alert(`overview size: ${requestOverviewSize}\n category list: ${categoryList}`);
            requestOverviewSize++;
        }
        
        console.log(`overview size: ${requestOverviewSize}\n category list: ${categoryList}`);
        console.log(list);

        return list;
    }
    
    // Return the first 6 requests
    function getRequestsOverview () {
        var slidesPerView;
        if (width > 1080) {
            if (myContext.requests.length > 3) {
                slidesPerView = 3;
            } else {
                slidesPerView = 1;
            }
        } else {
            slidesPerView = 1;
        }

        if (myContext.requests[0].bio == "Loading...") {
            return(
                <Loading />
            )
        }

        // if there are <= 3 active requests, just display them without the carousel
        if (myContext.requests.length <= 3) {
            return (
                <>
                    <Grid container spacing={2} className={classes.swiperSlide}>
                    { myContext.requests==null?null: myContext.requests.map((value, index) => (
                        (requestOverviewSize > 6 || getItems(value, 1) == '') ? null :
                            <Grid item xs={12} md={4} key={value.id} className="gridItem">
                                <Card className={classes.cardRoot}>
                                    <CardContent className="card-content">
                                        <Typography className="card-bio" color="textPrimary" style={{fontWeight:"bold"}}>
                                            {value.bio}
                                        </Typography>
                                        <br />
                                        <div className={classes.chipRoot}>
                                            {/* <Chip 
                                                label={"Request ID: " + value['shortId']} 
                                                style={{marginBottom: "0px"}}
                                                size='small'                                      
                                            />                                                     */}
                                            <Chip 
                                                label={'Requestor: ' + value['nonProfitName']}
                                                style={{marginBottom: "0px"}} 
                                                size='small'                                        
                                                // variant='outlined'                                                
                                            />    
                                        </div>
                                    </CardContent>                           
                                </Card>
                            </Grid>
                    ))}
                    </Grid>
                </>
            )
        } else {
            return(
                <>
                <Swiper
                    // spaceBetween={50}
                    slidesPerView={slidesPerView}
                    navigation={true}
                    className={classes.swiper}
                    // onSlideChange={() => console.log('slide change')}
                    // onSwiper={(swiper) => console.log(swiper)}
                    
                    >
                    { myContext.requests==null?null: myContext.requests.map((value, index) => (
                        (getItems(value, 1) == '') ? null :
                        <SwiperSlide className={classes.swiperSlide} key={value.id}>
                            <Card className={classes.cardRoot}
                                style={{
                                    // margin: "auto",
                                    // backgroundColor: "green",
                                    // maxWidth: "80%",
                                    // marginLeft: "40px",
                                    // marginLeft: "40px",
                                    // marginRight: "10px",
                                }}
                            >
                                <CardContent className="card-content">
                                    <Typography className="card-bio" color="textPrimary" style={{fontWeight:"bold"}}>
                                        {value.bio}
                                    </Typography>
                                    {/* <Typography color="textSecondary" className="card-items">
                                        <strong>Needed: </strong>
                                        {categoryList}
                                    </Typography> */}
                                    <br />
                                    <div className={classes.chipRoot}>
                                        {/* <Chip 
                                            label={"Request ID: " + value['shortId']} 
                                            style={{marginBottom: "0px"}}
                                            size='small'                                      
                                        />                                                     */}
                                        <Chip 
                                            label={'Requestor: ' + value['nonProfitName']}
                                            style={{marginBottom: "0px"}} 
                                            size='small'                                        
                                            // variant='outlined'                                                
                                        />    
                                    </div>
                                </CardContent>                           
                            </Card>
                        </SwiperSlide>
                    ))}
                </Swiper>
                </>
            )
        }
    }
    
    return (
        <div>
            {/* <div className={classes.overlay}> */}
            {/* </div> */}
            
            <Grid container className={classes.heroContent}>
                <img className="banner-image" src={StockImg} />
                {/* <img className={classes.heroImage} src={heroImage} /> */}
                <Grid className={classes.leftContent}>
                    {/* <img className="heroImage" src={heroImage} /> */}
                    <div className={classes.heroText}>
                        <Typography className={classes.heroTitle}>
                            Serve a family in need
                        </Typography>
                        <Typography className={classes.heroSubtitle}>
                            Donate items and services to families in need
                        </Typography>
                    </div>
                    <div className={classes.heroButtons}>
                        <Button
                            href="/donations"
                            variant="contained"
                            color="primary"
                            className={classes.donationButton}
                        >Fulfill a Request</Button>                    
                    </div>
                </Grid>
                <h3 style={{width: "100%", textAlign: "center", marginTop: "7vh"}}>Open Requests</h3>
                <div className={classes.requestOverview}>                                                                                                      
                    {getRequestsOverview()}                  
                </div>                        
            </Grid>
                {/* <a href="https://www.vecteezy.com/free-vector/charity">Charity Vectors by Vecteezy</a> */}
            <Divider variant="middle" />
            <div className={classes.InstructionsRoot}>
                <Typography className={classes.InstructionsTitle}>How Does It Work?</Typography>
                <Typography className={classes.bodyText}>
                    1. Click on &nbsp;
                    <Link href='/donations' >
                        'Fulfill a Request'                        
                    </Link>
                    .
                </Typography>
                <Typography className={classes.bodyText}>
                    2. Create a NectarCares account (it's free!)
                </Typography>
                <Typography className={classes.bodyText}>
                    3. Login to your NectarCares account
                </Typography>
                <Typography className={classes.bodyText}>
                    4. Choose a family that you’d like to donate to.
                </Typography>
                <Typography className={classes.bodyText}>
                    5. Click “donate” on that family’s card.
                </Typography>
                <Typography className={classes.bodyText}>
                    6. Choose the items that you’d like to donate from the requested items. If you can’t donate all of them, that’s okay! Just select the items that you have available to donate.
                </Typography>
                <Typography className={classes.bodyText}>
                    7. Submit your donation and print the donation summary. If you aren’t able to print when you submit your donation, you can always save it or access it later from the “Donation History” page.
                </Typography>
            </div>
        </div>
    )
}

export default Home
