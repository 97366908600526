import React, { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import './emailchips.css';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

class EmailChips extends React.Component {
        
    state = {
        value: '',
        emails: [],
        error: null
    }    

    handleChange = (event) => {
        this.setState({
            value: event.target.value,
            error: null
        });
    };

    handleKeyDown = (event) => {
        if (['Enter', 'Tab', ','].includes(event.key)) {
            event.preventDefault();
            
            var email = this.state.value.trim();
            
            if (email && this.isValid(email)) {
                this.setState({
                    emails: [...this.state.emails, email],
                    value: '',
                });                
            }
        }
    };

    handleDelete = (emailToRemove) => {
        this.setState({
            emails: this.state.emails.filter(email => email !== emailToRemove)
        });
    }

    isEmail(email) {
        return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
    }

    isInList(email) {
        return this.state.emails.includes(email);
    }

    isValid(email) {
        var error = null;
        
        if (!this.isEmail(email)) {
          error = `${email} is not a valid email address.`;
        }
        
        if (this.isInList(email)) {
          error = `${email} has already been added.`;
        }
        
        if (error) {
          this.setState({ error });
          
          return false;
        }
        
        return true;
    }
    
    handleReferral() {
        alert("Sending referral");

        axios.patch('https://donation-proxy-api.herokuapp.com/refer', {
            // axios.patch('http://localhost:3080/refer', {
                sender: this.props.user.email,
                recipientList: this.state.emails
            })
            .then(function (response) {
                alert(response.data);
                // alert(`Status has been updated to ${status}`);
                window.location.reload();
                // return response;
            })
            .catch(function (error) {
                alert(error);
            })
    }
    
    render () {
        return (
            <div className='email-chips-root'>
                <main className='email-chips-wrapper'>
                    {
                        this.state.emails && 
                        this.state.emails.map(email => (
                            <div key={email} className='email-chips-tag-item'>
                                {email}
                                <button
                                    type='button'
                                    onClick={() => this.handleDelete(email)}
                                    className="button"
                                >
                                    &times;
                                </button>
                            </div>
                        ))
                    }
                    <input
                        placeholder="Type email addresses and press `Enter`"
                        value={this.state.value}
                        onChange={this.handleChange}
                        onKeyDown={this.handleKeyDown}
                        className={"email-chips-input " + (this.state.error && " has-error")}
                    />
                    {this.state.error && <p className="error">{this.state.error}</p>}
                <Button
                        variant='contained'
                        color='primary'
                        onClick={() => this.handleReferral()}
                        style={{marginTop: '2rem'}}
                        fullWidth
                    >
                        Send Referral
                </Button>
                </main>
            </div>
        )
    }
}

export default EmailChips
