import React, { useState, useEffect, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
    BrowserRouter as Router,
    Link,
    useHistory,
    useParams
} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
// import { TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { Button, Divider, Typography, useMediaQuery, Grid, Card, CardContent, Chip } from '@material-ui/core';
import { Height, PowerOffRounded } from '@material-ui/icons';
import axios from 'axios';
import TrustedWorldLogo from '../../img/TrustedWorldLogo.png';
import NectarCaresLogo from '../../img/nectar-cares-logo-blue.png'
import AddIcon from '@mui/icons-material/Add';
import { PartnershipApplication } from './PartnershipApplication';
import useWindowDimensions from '../hooks/WindowDimensions';
import AppContext from '../AppContext';
import Loading from '../loading';
import * as items_global from '../../items';


// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Keyboard, Scrollbar, Pagination, Navigation } from 'swiper/core'

const useStyles = makeStyles((theme) => ({    
    logo: {
        // maxHeight: "150px",
        // width: "auto",
        // maxWidth: "100%",
        // padding: "20px",
        // textAlign: "center",   
        // width: "200px",
        width: "100%",
        // maxWidth: "400px",
        maxWidth: "min(90%, 400px)",
        height: "auto",
        padding: "15px"
    },
    logoRoot: {
        // display: "flex",
        alignItems: "center",
        // justifyContent: "center",
        textAlign: "center",
        marginTop: "3rem",
        marginBottom: "1rem",
        // flexWrap: "wrap",
        maxWidth: "65vw",
        margin: "auto"
    },
    plusSign: {
        width: "115px",
        height: "110px",
        textAlign: "center",
    },
    bodyRoot: {
        margin: "auto",
        maxWidth: "70vw",
        marginBottom: "2rem"
    },
    organizationOverviewRoot: {
        marginBottom: "4rem",
    },
    requestsRoot: {

    },
    nectarCaresLogo: {
        // maxHeight: "200px",
        // height: "150px",
        maxWidth: "100%"
    },
    swiper: {
        width: "85vw",
        height: "188px",
        marginTop: "15px",
    },
    swiperSlide: {
        // maxWidth: "80vw",
        // marginRight: "unset",
        // margin: "auto",
        // justifyContent: "center",
    },
    cardRoot: {
        height: "95%",
        marginLeft: "19px"
    },
    heroTitle: {
        // fontFamily: 'roboto',
        fontSize: 'min(9vw, 4.5rem)',
        // fontSize: '6vw',
        fontWeight: 'bold',
        margin: '15px 15px 7px 15px',
        textAlign: "center",
    },
    heroSubtitle: {
        fontSize: 'min(5vw, 2rem)',
        // fontSize: '2vw',
        margin: '7px 15px 15px 15px',
        textAlign: "center",
    },
    heroButtons: {
        display: "flex",
        justifyContent: "space-around",
        marginBottom: "2rem",
        maxWidth: "30%",
        margin: "auto",
        flexWrap: "wrap"
    },
    bodyText: {
        fontSize: '1.25rem',
        // lineHeight: '180%',
    }
}));

const Partnerships = () => {
    
    let { organization } = useParams();
    const classes = useStyles();
    const { height, width } = useWindowDimensions();
    const myContext = useContext(AppContext);
    const { isAuthenticated, user } = useAuth0();
    var categoryList = [];

    useEffect(() => {
        // alert('In home useEffect');
        console.log("Setting homepage state");
      
        // if(api_call_limiter<=1) {
        if(myContext.requests[0].bio == "Loading...") {
            console.log("Fetching from api");
            let url = process.env.REACT_APP_SHEETY_API_GET;
            fetch(url)
            .then((response) => response.json())
            .then(json => {                
                // Update the prop with the requests
                if (myContext.setrequests) {
                    console.log("Going to set requests in homepage useEffect");
                    console.log(myContext);
                    myContext.setrequests(json.requests);
                }
            });
        }
        // api_call_limiter++;
    });

    if (organization == "application") {
        return (
            <PartnershipApplication />
        )
    }

    // Set the global variable categoryList to a list of categories based on the items that are present in object obj
    function setCategories(obj) {
        
        // Reset the global categoryList array
        categoryList = [];

        Object.keys(obj).forEach(function(key) {
            // console.log('Key : ' + key + ', Value : ' + obj[key]);
            if (items_global.items.includes(key) && (obj[key] != '') && (obj[key] != 0)) {
                console.log(`key is ${key}`);
                // if the current key is an item, check it's index in items_global[]
                var index = items_global.items.indexOf(key);
                console.log(`Index of ${key} is ${index}`);

                // Set the category based on the index range of the current key in array items_global[]
                if (index <= 30) {
                    // Kitchen
                    if (categoryList.indexOf('Kitchen ') == -1) {
                        categoryList.push('Kitchen ');
                    } 
                } else if (index > 30 && index <= 38) {
                    // Bedroom
                    if (categoryList.indexOf('Bedroom ') == -1) {
                        categoryList.push('Bedroom ');
                    } 
                } else if (index > 38) {
                    // Bathroom
                    if (categoryList.indexOf('Bathroom ') == -1) {
                        categoryList.push('Bathroom ');
                    } 
                }
            }
        });        
    }

    // Get & set list of items given a request object
    function getItems(obj) {
        console.log("Getting list");
        var list = '';

        Object.keys(obj).forEach(function(key) {
            // console.log('Key : ' + key + ', Value : ' + obj[key]);
            if (items_global.items.includes(key) && (obj[key] != '') && (obj[key] != 0)) {
                list = list + ' ' + obj[key] + ' ' + key + ',';
            }
        });

        // Set the categories to show on each family card
        setCategories(obj);

        // Remove the last comma
        list = list.slice(0,-1);

        return list;
    }

    // Return the first 6 requests
    function getRequestsOverview () {
        var slidesPerView;
        if (width > 970) {
            if (myContext.requests.length > 3) {
                slidesPerView = 3;
            } else {
                slidesPerView = 1;
            }
        } else {
            slidesPerView = 1;
        }

        if (myContext.requests[0].bio == "Loading...") {
            return(
                <Loading />
            )
        }

        // if there are <= 3 active requests, just display them without the carousel
        if (myContext.requests.length <= 3) {
            return (
                <>
                    <Grid container spacing={2} className={classes.swiperSlide}>
                    { myContext.requests==null?null: myContext.requests.map((value, index) => (
                        (index > 6 || getItems(value, 1) == '') ? null :
                            <Grid item xs={12} md={4} key={value.id} className="gridItem">
                                <Card className={classes.cardRoot}>
                                    <CardContent className="card-content">
                                        <Typography className="card-bio" color="textPrimary" style={{fontWeight:"bold"}}>
                                            {value.bio}
                                        </Typography>
                                        <br />
                                        <div className={classes.chipRoot}>
                                            {/* <Chip 
                                                label={"Request ID: " + value['shortId']} 
                                                style={{marginBottom: "0px"}}
                                                size='small'                                      
                                            />                                                     */}
                                            <Chip 
                                                label={'Requestor: ' + value['nonProfitName']}
                                                style={{marginBottom: "0px"}} 
                                                size='small'                                        
                                                // variant='outlined'                                                
                                            />    
                                        </div>
                                    </CardContent>                           
                                </Card>
                            </Grid>
                    ))}
                    </Grid>
                </>
            )
        } else {
            return(
                <>
                <Swiper
                    // spaceBetween={50}
                    slidesPerView={slidesPerView}
                    navigation={true}
                    className={classes.swiper}
                    // onSlideChange={() => console.log('slide change')}
                    // onSwiper={(swiper) => console.log(swiper)}
                    
                    >
                    { myContext.requests==null?null: myContext.requests.map((value, index) => (
                        (getItems(value, 1) == '') ? null :
                        <SwiperSlide className={classes.swiperSlide} key={value.id}>
                            <Card className={classes.cardRoot}
                                style={{
                                    // margin: "auto",
                                    // backgroundColor: "green",
                                    // maxWidth: "80%",
                                    // marginLeft: "40px",
                                    // marginLeft: "40px",
                                    // marginRight: "10px",
                                }}
                            >
                                <CardContent className="card-content">
                                    <Typography className="card-bio" color="textPrimary" style={{fontWeight:"bold"}}>
                                        {value.bio}
                                    </Typography>
                                    <br />
                                        <div className={classes.chipRoot}>
                                            {/* <Chip 
                                                label={"Request ID: " + value['shortId']} 
                                                style={{marginBottom: "0px"}}
                                                size='small'                                      
                                            />                                                     */}
                                            <Chip 
                                                label={'Requestor: ' + value['nonProfitName']}
                                                style={{marginBottom: "0px"}} 
                                                size='small'                                        
                                                // variant='outlined'                                                
                                            />    
                                        </div>
                                </CardContent>                           
                            </Card>
                        </SwiperSlide>
                    ))}
                </Swiper>
                </>
            )
        }
    }
    
    return (
        <div>
            <Grid container className={classes.logoRoot} justify='center'>
                <Grid item xs={12} md={3}>
                    <img src={TrustedWorldLogo} className={classes.logo}></img>
                </Grid>
                <Grid item xs={12} md={1} className={classes.plusSign}>
                    <img src="../../img/plusSign.png"></img>
                </Grid>
                <Grid item xs={12} md={3}>
                    <img src={NectarCaresLogo} className={classes.nectarCaresLogo}></img>
                </Grid>
            </Grid>
            <Grid container justify='center'>
                <Grid className={classes.leftContent}>
                    <div className={classes.heroText}>
                        <Typography className={classes.heroTitle}>
                            Serve a family in need
                        </Typography>
                        <Typography className={classes.heroSubtitle}>
                            Donate in-kind items and services to families in need
                        </Typography>
                    </div>
                </Grid>
            </Grid>
            <div className={classes.bodyRoot}>
                <div className={classes.bodyText}>
                    <p>
                        The NectarCares app was developed with input from TrustedWorld leadership based on their experience with Dallas
                        area non-profits for more than the last decade.
                    </p>
                    <p>
                        Trusted World is responsible for helping thousands of families, either directly through the services that they 
                        offer, or through their partnerships with other organizations. Their input in identifying processes, 
                        recommending new paths forward, and dedication to helping families wherever they’re able has been invaluable.
                    </p>
                    <p>
                        To learn if NectarCares can help partner with your organization, click the button below to get more information.                
                    </p>
                </div>
            </div>
            <div className={classes.heroButtons}>
                {/* <Button
                    href="/donations"
                    variant="contained"
                    color="primary"
                    className={classes.donationButton}
                >Fulfill a Request</Button> */}
                {
                    isAuthenticated && (user["https://nectarcares.org/roles"][0] == "admin") ?
                    <Button
                            href="/item-request"
                            variant="contained"
                            color="primary"
                            className={classes.donationButton}
                        >Submit a Request</Button>
                    :
                        <Button
                            href="/partnerships/application"
                            variant="contained"
                            color="primary"
                            className={classes.donationButton}
                        >Become A Partner</Button>
                }
            </div>
            <h3 style={{width: "100%", textAlign: "center", marginTop: "7vh"}}>Open Requests</h3>
            {getRequestsOverview()}
        </div>
    )
}

export default Partnerships
