import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Auth0ProviderWithHistory from './auth/auth0-provider-with-history';
import {
  BrowserRouter as Router,
} from "react-router-dom";
import './Fonts/5563279/6b4d4830-9c7d-43d2-b5d3-c73f739561b9.woff2'
import './Fonts/5563279/ccf25ada-6d2a-4133-95dc-deb039e22999.woff'
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme'

ReactDOM.render(
  <React.StrictMode>
  <Router>
    <Auth0ProviderWithHistory>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Auth0ProviderWithHistory>
  </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
