import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Card, CardContent, Typography, CardActions, Button, TextField, InputLabel, MenuItem  } from '@material-ui/core';
import { MdOutlineRecycling, MdMonetizationOn, MdOutlineAdminPanelSettings } from 'react-icons/md';
import { BiDonateHeart } from 'react-icons/bi';
import { SiCrowdsource } from 'react-icons/si';
import { FaPager } from 'react-icons/fa';


const useStyles = makeStyles((theme) => ({
  pageRoot: {
    // maxWidth: '75vw',
    margin: 'auto',
    paddingBottom: '0px'
  },
  pointsContainer: {
    maxWidth: '75vw',
    margin: 'auto'
  },
  pointRoot: {
    textAlign: 'center'
  },
  donorSection: {
    paddingTop: '0.2rem',
    // paddingBottom: '2rem',
  },
  nonProfitSection: {
    backgroundColor: '#E8E8E8',
    marginBottom: '-4rem',
    paddingTop: '2rem',
    paddingBottom: '2rem',
  },
  circle: {
    borderRadius: '50%',
    width: '140px',
    height: '140px',
    backgroundColor: '#E8E8E8',
    margin: 'auto',
  },
  whiteCircle: {
    borderRadius: '50%',
    width: '140px',
    height: '140px',
    backgroundColor: 'white',
    margin: 'auto',
  }

}));

const WhyNectarCares = () => {

  const classes = useStyles();

  return (
    <div className={classes.pageRoot}>
      <h1 style={{textAlign: 'center'}} >Why NectarCares?</h1>
      <div className={classes.donorSection}>
        <h2 style={{textAlign: 'center'}}>For Donors</h2>
        <Grid container spacing={4} className={classes.pointsContainer}>
          <Grid item xs={12} md={4} className={classes.pointRoot}>
            {/* <img alt='environment logo' src={<MdOutlineRecycling />}></img> */}
            <div className={classes.circle}>
              <MdOutlineRecycling size={100} style={{marginTop: '1rem'}} />
            </div>
            <h3>Help the Environment</h3>
            <p>
              Items that you might throw away will instead go to families in need (helps the environment)
            </p>
          </Grid>
          <Grid item xs={12} md={4} className={classes.pointRoot}>
            {/* <img alt='cost benefit logo'></img> */}
            <div className={classes.circle}>
              <MdMonetizationOn size={100} style={{marginTop: '1rem'}} />
            </div>
            <h3>Gain Tax Benefits</h3>
            <p>
              Gain value from items by donating them instead of throwing them out (tax benefits)
            </p>
          </Grid>
          <Grid item xs={12} md={4} className={classes.pointRoot}>
            {/* <img alt='convenient logo'></img> */}
            <div className={classes.circle}>
              <BiDonateHeart size={100} style={{marginTop: '1rem'}} />
            </div>
            <h3>Easily Manage Donations</h3>
            <p>
              NectarCares provides you with an easy way to manage and make donations to multiple organizations/families
            </p>
          </Grid>
        </Grid>
      </div>

      <div className={classes.nonProfitSection}>      
        <h2 style={{textAlign: 'center'}}>For Non-Profit Organizations</h2>
        <Grid container spacing={4} className={classes.pointsContainer}>
          <Grid item xs={12} md={4} className={classes.pointRoot}>
            {/* <img alt='donor network logo'></img> */}
            <div className={classes.whiteCircle}>
              <SiCrowdsource size={100} style={{marginTop: '1rem'}} />
            </div>
            <h3>Tap Into Our Network of Donors</h3>
            <p>
              NectarCares helps you help your community by sourcing pre-loved items (based on your requests) from our network of donors
            </p>        
          </Grid>
          <Grid item xs={12} md={4} className={classes.pointRoot}>
            {/* <img alt='admin portal logo'></img> */}
            <div className={classes.whiteCircle}>
              <MdOutlineAdminPanelSettings size={100} style={{marginTop: '1rem'}} />
            </div>
            <h3>Easily Manage Requests and Donations</h3>
            {/* <h3>Gain Access To Our Admin Portal</h3> */}
            <p>
              Conveniently make and manage requests from our Admin portal
            </p>        
          </Grid>
          <Grid item xs={12} md={4} className={classes.pointRoot}>
            {/* <img alt='custom branding logo'></img> */}
            <div className={classes.whiteCircle}>
              <FaPager size={100} style={{marginTop: '1rem'}} />
            </div>
            <h3>A Custom Experience for Your Community</h3>
            <p>
              NectarCares provides your organization with a landing page; giving your community a custom experience when they visit
            </p>      
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default WhyNectarCares;
