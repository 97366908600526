import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Card, CardContent, Typography, CardActions, Button, TextField, InputLabel, MenuItem  } from '@material-ui/core';
import { MdOutlineRecycling, MdMonetizationOn, MdOutlineAdminPanelSettings } from 'react-icons/md';
import { BiDonateHeart } from 'react-icons/bi';
import { SiCrowdsource } from 'react-icons/si';
import { FaPager } from 'react-icons/fa';
import BannerImg from '../../img/banner-image.png';
import NectarCaresLogo from '../../img/transparent-logo.png'
import Countdown from 'react-countdown';
import { width } from '@mui/system';

const useStyles = makeStyles((theme) => ({
  center: {
    textAlign: 'center',
    margin: 'auto'
  },
  logo: {
    width: '100px',
  },
  countdownTimer: {
    textAlign: 'center',
    fontSize: '3rem',
    marginTop: '2rem',
    // border: 'solid #FC2252 1px',
    // border: 'solid black 1px',
    // width: '30%',
    margin: 'auto',
    // borderRadius: '15px',
    padding: '15px'

  },
  bodyText: {
    fontSize: '1.25rem',
    // lineHeight: '180%',
  }
}));

const NFTLanding = () => {

  const classes = useStyles();

  return (
    <div className={classes.bodyText}>
      <img className='banner-image' src={BannerImg} />
      <div  style={{maxWidth: '75vw', margin: 'auto'}}>
        <div className={classes.center}>
          {/* <img src={NectarCaresLogo} className={classes.logo} /> */}
          <h1 style={{marginBottom: '0px'}}>NectarCares NFT Project</h1>
          {/* <p>Our NFT Collection aimed at raising funds to help grow our team</p> */}
          <p>We are excited to announce the upcoming release of our NFT collection!</p>
        </div>

        <div className={classes.countdownTimer}>
          <Countdown date={'2022-03-15T01:00:00'} />
          <Typography>Countdown (DD:HH:MM:SS)</Typography>
        </div>

        <div style={{marginTop: '2.5rem'}}>
          <h2 className={classes.center}>Introduction</h2>
          <p style={{maxWidth: '80%', margin: '1rem auto 3rem auto'}}>
            Through NectarCares NFTs, you directly help families in need.<br /><br />
            100% of the proceeds from the NFT sales go towards our mission of making it easier and less expensive for nonprofits to serve local families & individuals who are homeless, suffered domestic abuse or are refugees.<br /><br />
            We’re a digital company tapping into the digital world.
          </p>
        </div>

        <div>
          <h2 className={classes.center}>How Does it Work?</h2>
          <p style={{maxWidth: '80%', margin: '1rem auto 3rem auto'}}>
            NectarCares connects donors to families in need of household items and services. We help non profit organizations serve their community by sourcing items and services from our network of donors (at no charge to the non profit organizations).<br /><br /> 
            We aim to launch our NFT collection to help raise funds for our project. The sales of these NFTs will help bring designers and marketers on board as we expand NectarCares.
          </p>
        </div>

        <div>
          <h2 className={classes.center}>What We're Doing</h2>
          <p style={{maxWidth: '80%', margin: '1rem auto 3rem auto'}}>
            NectarCares is working with over 500 non-profits via Trusted World to help organizations do what they do best - serve their community. 
          </p>
        </div>
        
        <div>
          <h2 className={classes.center}>Where Do the Funds Go?</h2>
          <p style={{maxWidth: '80%', margin: '1rem auto 3rem auto'}}>
            Some specific roles we are looking to fill
            <ul>
              <li>
                <strong>Community Engagement</strong> - reach out to more non-profits to make it easier and less expensive for them to source donations
              </li>
              <li>
                <strong>Developers</strong> - keep building the app to serve more families, more quickly              
              </li>
              <li>
                <strong>Graphic Design and User Experience</strong> - to help improve the look and usability of our platform 
              </li>
              <li>
                <strong>Marketing</strong> – spread the word to the community to help families in need
              </li>
            </ul>
          </p>
        </div>
        
        <div>
          <h2 className={classes.center}>Why NFTs to Raise Funds?</h2>
          <p style={{maxWidth: '80%', margin: '1rem auto 3rem auto'}}>
            It all comes down to one word - Community. With people taking great interest in crypto and NFTs, we believe this is a good way to raise funds in a transparent way while building our community with technology of the future. 
          </p>
        </div>

        <div>
          <h2 className={classes.center}>NectarOM & NectarCares</h2>
          <p style={{maxWidth: '80%', margin: '1rem auto 3rem auto'}}>
            With 20+ years of experience in digital, marketing and customer analytics for retailers, consumer, and healthcare enterprises; NectarOM now wants to make a difference by giving back to the community in the form of NectarCares.
          </p>
        </div>
      </div>
    </div>
  );
};

export default NFTLanding;
